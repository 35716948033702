import React, {useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../../config/url";
import {
  getErrorMessageByKey,
  Input, isValidateNullText,
  jsonFormatText,
  Modal, TextSearchMarker,
  useNotification,
  useObject,
} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";
import ModalContractorItemSelect from "./ModalContractorItemSelect";

function ModalIncomeEdit(props) {
  const [selectItem, setSelectItem] = useState(null)
  const [isModalSelectItem, setIsModalSelectItem] = useState(false)

  const {
    object: [object],
  } = useObject({
    apiUrl: "api/income-order/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackResponse: response => {
      if (!!response) {
        setSelectItem((!!response.contractor && response.contractor) || null)
      }
    },
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputDate = useRef(null);


  const [isDelete, setIsDelete] = useState(false);

  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const date = jsonFormatText(inputDate.current.value);


    if (isValidateNullText(date)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      date: date,
      contractor: !!selectItem ? {
        uuid: selectItem.uuid,
      } : null,
    });

    axios
      .put("/api/income-order/" + props.object.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("api/income-order/" + props.object.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Наряд удалён", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <>
      <Modal handleClose={() => props.handleClose()}>
        {!isDelete && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div
              className="Form__title"
              style={{ fontSize: 19, fontWeight: 700 }}
            >
              №{object.id}{!!object.date && ' от ' + moment(object.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}
              <div className="Text__signature">Поступление</div>
            </div>

            <div className="Form__field">
              <Input reference={inputDate} type={'datetime-local'} isFixed={true} label="Дата заказа *"
                     value={
                       !!object.date
                         ? moment(object.date.substring(0, 19)).format(
                           "YYYY-MM-DD HH:mm"
                         )
                         : null
                     } />
            </div>

            {!selectItem && (
              <div className="Form__field Form__field_last"
                   style={{
                     cursor: "pointer"
                   }}
                   onClick={() => setIsModalSelectItem(true)}>
                <div className="Field__label">
                  Контрагент
                </div>
                <div className="Field__text">
                  -
                </div>
              </div>
            )}

            {!!selectItem && (
              <div className="Form__field Form__field_last"
                   style={{
                     cursor: "pointer"
                   }}
                   onClick={() => setIsModalSelectItem(true)}>
                <div className="Field__label">
                  Контрагент
                </div>
                <div className="Field__text">
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: 700,
                      width: "100%",
                    }}
                  >
                    {!!selectItem.short_name && selectItem.short_name}
                    <div style={{
                      fontSize: 12,
                      fontWeight: 400,
                    }}>
                      {!!selectItem.name && selectItem.name}
                    </div>
                    <div style={{
                      fontSize: 12,
                      fontWeight: 400,
                    }}>
                      <span style={{marginRight: 8}}>ОГРН:&nbsp;{(!!selectItem.ogrn && selectItem.ogrn) || "-"}</span>
                      <span style={{marginRight: 8}}>ИНН:&nbsp;{(!!selectItem.inn && selectItem.inn) || "-"}</span>
                      <span style={{marginRight: 8}}>КПП:&nbsp;{(!!selectItem.kpp && selectItem.kpp) || "-"}</span>
                    </div>
                    <div style={{
                      fontSize: 12,
                      fontWeight: 400,
                    }}>
                      <span style={{marginRight: 8}}>Банк:&nbsp;{(!!selectItem.bank && selectItem.bank) || "-"}</span>
                      <span style={{marginRight: 8}}>Номер&nbsp;счёта:&nbsp;{(!!selectItem.invoice_number && selectItem.invoice_number) || "-"}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>
            </div>
          </form>
        )}

        {isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div
              className="Form__title"
              style={{ fontSize: 19, fontWeight: 700 }}
            >
              №{object.id}{!!object.date && ' от ' + moment(object.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Удалить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsDelete(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>

        {isModalSelectItem && (
          <ModalContractorItemSelect
            item={selectItem}
            successfulRequest={item =>
              setSelectItem(item)
            }
            handleClose={() => setIsModalSelectItem(false)}
          />
        )}
        </>
    )
  );
}

export default ModalIncomeEdit;
