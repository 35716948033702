import React, { useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  ROLE_MANAGER,
} from "../../../config/constants";
import {
  getErrorMessageByKey,
  useNotification,
  Input,
  Select,
  Modal,
  jsonFormatText,
  isValidateNullText,
} from "@vokymlak/kabinet-ui";

export const ModalAddEmployee = (props) => {
  const inputLastName = useRef(null);
  const inputFirstName = useRef(null);
  const inputPatronymic = useRef(null);
  const inputLogin = useRef(null);
  const inputPassword = useRef(null);
  const inputEmail = useRef(null);
  const inputRoleTypeTag = useRef(null);

  const { notification } = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const lastName = jsonFormatText(inputLastName.current.value);
    const firstName = jsonFormatText(inputFirstName.current.value);
    const patronymic = jsonFormatText(inputPatronymic.current.value);
    const login = jsonFormatText(inputLogin.current.value);
    const password = inputPassword.current.value;
    const email = jsonFormatText(inputEmail.current.value);
    const roleTypeTag = inputRoleTypeTag.current.value;

    if (
      isValidateNullText(lastName) ||
      isValidateNullText(firstName) ||
      isValidateNullText(login) ||
      !password ||
      password.length === 0
    ) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      last_name: lastName,
      first_name: firstName,
      patronymic: patronymic,
      login: login,
      password: password.length > 0 ? password : null,
      email: email,
      role_type_tag: roleTypeTag,
    });

    axios
      .post("/api/employee/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Сотрудник добавлен", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">
          Новый
          <div className="Text__signature">Сотрудник</div>
        </div>

        <div className="Form__field">
          <Input reference={inputLastName} label="Фамилия *" />
        </div>

        <div className="Form__field">
          <Input reference={inputFirstName} label="Имя *" />
        </div>

        <div className="Form__field">
          <Input reference={inputPatronymic} label="Отчество" />
        </div>

        <div className="Form__field">
          <Input reference={inputLogin} label="Логин *" />
        </div>

        <div className="Form__field">
          <Input reference={inputPassword} label="Пароль *" />
        </div>

        <div className="Form__field">
          <Input reference={inputEmail} label="Электронная почта" />
        </div>

        <div className="Form__field Form__field_last">
          <Select
            isFixed={true}
            reference={inputRoleTypeTag}
            options={[
              {
                value: ROLE_MANAGER,
                title: "Менеджер",
              },
            ]}
            label="Роль"
          />
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Добавить
          </button>
        </div>

        <div className="Form__actions Form__actions_center">
          <div className="Action__link" onClick={() => props.handleClose()}>
            Отмена
          </div>
        </div>
      </form>
    </Modal>
  );
};
