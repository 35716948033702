import React, {Fragment, useState} from "react";
import {ButtonIcon, Icon} from "@vokymlak/kabinet-ui";
import styleContent from "../../../../styles/modules/Block.module.scss";
import ProductionNomenclatureSpecifications
  from "./ProductionNomenclatureSpecifications";

function ProductionOrderProductionList(props) {
  const {
    object,

    setModalOrderItem3Edit,

    setModalItemTransaction,
    setModalItemTransactionRollback,
  } = props;

  const [selectComposition, setSelectComposition] = useState(null);

  const isSelectComposition = (composition) => {
    return !!selectComposition && selectComposition.uuid === composition.uuid;
  };

  const changeSelectComposition = (composition) => {
    if (!!selectComposition && selectComposition.uuid === composition.uuid) {
      setSelectComposition(null);
    } else {
      setSelectComposition(composition);
    }
  };


  return (
    !!object && (
      <Fragment>

        <div className={styleContent._block} style={{marginTop: 12}}>
          <div className={styleContent._block__title}>Производство</div>
          <div className={styleContent._block__content}>
            <table className="Table">
              <tbody>
              {object.items &&
                object.items.map((item, index) => (
                  <Fragment key={"issuance_" + item.uuid}>
                    <tr
                      className={
                        isSelectComposition(item)
                          ? "Table__tr Table__tr_hover Table__tr_focus"
                          : "Table__tr Table__tr_hover"
                      }
                      style={{
                        borderStyle: "2px solid",
                        borderColor: "#bdbdbd",
                        borderBottomColor: isSelectComposition(item)
                          ? "#202020"
                          : "#bdbdbd",
                      }}
                    >
                      <td className="Table__td">
                        <div className="Td__title Td__title_flex">
                          {index + 1} # {item.nomenclature.name}

                          <span
                            style={{
                              marginLeft: 12,
                            }}
                          >
                            |&nbsp;&nbsp;
                            {!!item.nomenclature && !!item.nomenclature.value &&
                              new Intl.NumberFormat("ru-RU").format(Number(!!item.nomenclature.measurement_unit_type ?
                                (item.nomenclature.value / item.nomenclature.measurement_unit_type.ratio) : item.nomenclature.value).toFixed(3))}
                            <span
                              style={{
                                marginLeft: 2,
                                fontSize: 12,
                                fontWeight: 400,
                                opacity: 0.75,
                              }}
                            >
                              {!!item.nomenclature.measurement_unit_type && item.nomenclature.measurement_unit_type.designation + "."}
                            </span>
                            &nbsp;&nbsp;
                          </span>

                          {!!item.specification && (
                            <div
                              className="Button__circle"
                              onClick={() => changeSelectComposition(item)}
                              onContextMenu={() => changeSelectComposition(item)}
                              style={{
                                padding:
                                  !!selectComposition &&
                                  item.uuid === selectComposition.uuid
                                    ? "3px 4px 5px 4px"
                                    : "5px 4px 3px 4px",
                              }}
                            >
                              {!!selectComposition &&
                                item.uuid === selectComposition.uuid && (
                                  <Icon
                                    name={"custom-expand-less"}
                                    color={"#202020"}
                                    size={24}
                                  />
                                )}
                              {(!selectComposition ||
                                (!!selectComposition &&
                                  item.uuid !== selectComposition.uuid)) && (
                                <Icon
                                  name={"custom-expand-more"}
                                  color={"#202020"}
                                  size={24}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="Table__td" style={{
                        textAlign: "right"
                      }}>
                        <div className="Td__title">
                                    <span style={{
                                      fontWeight: 700,
                                      fontSize: 24,
                                    }}>

                                      {item.nomenclature.is_pcs && (
                                        <>
                                          {(!!item.quantity && (item.quantity)) || "0"}
                                        </>
                                      )}

                                      {!item.nomenclature.is_pcs && (
                                        <>
                                          {(!!item.quantity && !!item.nomenclature.measurement_unit_type
                                            ? (item.quantity / item.nomenclature.measurement_unit_type.ratio)
                                            : !!item.quantity && item.quantity) || "0"}
                                          {!!item.nomenclature.measurement_unit_type && <span style={{
                                            marginLeft: 4,
                                            fontWeight: 400,
                                            fontSize: 13
                                          }}>{item.nomenclature.measurement_unit_type.designation + '.'}</span>}
                                        </>
                                      )}

                                    </span>
                        </div>
                      </td>

                      <td className="Table__td"
                          style={{
                            width: 36,
                          }}>
                        <div
                          style={{
                            height: 36,
                            display: "flex",
                          }}
                        >
                          {!item.is_transaction && (
                          <ButtonIcon
                            tooltipText={"Редактировать"}
                            iconName={"custom-edit"}
                            iconColor={"#202020"}
                            size={36}
                            onClick={() =>
                              setModalOrderItem3Edit(item)
                            }
                          />
                            )}
                        </div>

                      </td>

                      <td className="Table__td"
                          style={{
                            width: 36,
                          }}>
                        <div
                          style={{
                            height: 28,
                            display: "flex",
                          }}
                        >
                          {item.is_transaction && (
                            <div
                              className={
                                "Button__collected"
                              }
                              style={{
                                height: 28,
                                padding: "3px 8px 4px",
                                display: "flex"
                              }}
                            >
                              Учтено
                              <ButtonIcon
                                style={{marginLeft: 12}}
                                tooltipText={"Отменить учёт"}
                                iconName={"edit-undo"}
                                iconColor={"#202020"}
                                size={28}
                                onClick={() =>
                                  setModalItemTransactionRollback(item)
                                }
                              />
                            </div>
                          )}
                          {!item.is_transaction && (
                          <div
                            className={
                              "Button__collected Button__collected_blue"
                            }
                            style={{
                              height: 28,
                              padding: "3px 8px 4px",
                              cursor: "pointer"
                            }}
                            onClick={() =>
                              setModalItemTransaction(item)
                            }
                          >
                            Учесть
                          </div>
                          )}
                        </div>

                      </td>
                    </tr>
                    {!!item.specification &&
                      !!selectComposition && item.uuid === selectComposition.uuid && (
                      <>
                        <tr
                          className={
                            isSelectComposition(item)
                              ? "Table__tr Table__tr_focus_light"
                              : "Table__tr"
                          }
                          style={{
                            borderStyle: "2px solid",
                            borderColor: isSelectComposition(item)
                              ? "#202020"
                              : "#bdbdbd",
                          }}
                        >
                          <td
                            className="Table__td"
                            colSpan={5}
                            style={{
                              padding: "0px",
                            }}
                          >
                            <ProductionNomenclatureSpecifications
                              {...props}
                              order={object}
                              object={item}
                            />
                          </td>
                        </tr>
                      </>
                    )}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    )
  );
}

export default ProductionOrderProductionList;
