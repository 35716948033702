import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import { ROLE_ADMINISTRATOR } from "../../../config/constants";
import {
  getAccountFio,
  getErrorMessageByKey,
  Modal,
  Select,
  useList,
  useNotification,
  useObject,
} from "@vokymlak/kabinet-ui";
import { Avatar } from "../../../components/Avatar/Avatar";

export const ModalAddAccountRoleType = (props) => {
  const inputRoleTypeTag = useRef(null);

  const { notification } = useNotification();

  const {
    list: [roleTypes],
  } = useList({
    apiUrl: "/api/role-types",
    rootUrl: ROOT_URL,
  });

  const {
    object: [account],
    setApiUrl,
  } = useObject({
    apiUrl: "/api/account/" + props.account.uuid,
    rootUrl: ROOT_URL,
  });

  useEffect(() => {
    if (props.account) {
      setApiUrl("/api/account/" + props.account.uuid);
    }
  }, [props.account]);

  const changeAdd = (event) => {
    event.preventDefault();

    const roleTypeTag = inputRoleTypeTag.current.value;

    if (!roleTypeTag || roleTypeTag.length === 0) {
      return;
    }

    const jsonData = JSON.stringify({
      role_type: {
        tag: roleTypeTag,
      },
    });

    axios
      .post("/api/account/" + props.account.uuid + "/role-type/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Роль добавлена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const RoleTypesAsSelectOptions = (roles, account) => {
    const options = [];

    roles.map((role) => {
      if (role.tag !== ROLE_ADMINISTRATOR) {
        const option = {
          value: role.tag,
          title: role.name,
        };

        let roleSuccess = false;

        account.account_roles &&
          account.account_roles.map((ar) => {
            if (ar.role_type.tag === role.tag) {
              roleSuccess = true;
            }
          });

        if (!roleSuccess) {
          options.push(option);
        }
      }
    });

    return options;
  };

  return (
    !!roleTypes &&
    !!account && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div
            style={{
              borderRadius: 7,
              backgroundColor: "#f3f3f3",
              height: 96,
              width: 96,
              marginBottom: 8,
            }}
          >
            <Avatar size={96} value={getAccountFio(account)} />
          </div>
          <div
            className="Form__title"
            style={{ fontSize: 19, fontWeight: 700 }}
          >
            {getAccountFio(account)}
            <div
              style={{
                fontSize: 12,
                opacity: 0.5,
              }}
            >
              {"@" + account.login}
            </div>
            <div className="Text__signature">Аккаунт</div>
          </div>

          <div className="Form__field">
            <Select
              isFixed={true}
              reference={inputRoleTypeTag}
              options={
                (!!roleTypes && RoleTypesAsSelectOptions(roleTypes, account)) ||
                []
              }
              label="Роль"
            />
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Добавить
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </form>
      </Modal>
    )
  );
};
