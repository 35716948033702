import React, { useEffect } from "react";
import { ROOT_URL } from "../../../config/url";
import { Loading, useAction, useList } from "@vokymlak/kabinet-ui";

export const ClientCompaniesList = (props) => {
  const {
    list: [list],
    update: [isUpdateList, setIsUpdateList, changeUpdate],
  } = useList({
    apiUrl: "/api/client/" + props.client.uuid + "/companies",
    rootUrl: ROOT_URL,
  });

  useEffect(() => {
    setIsUpdateList(!isUpdateList);
  }, [props.client]);

  const { setAction } = useAction(["clients", "companies"], () =>
    changeUpdate()
  );

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "clients", "companies"]);
    } else {
      setAction("clients", "companies");
    }
  }, [list]);

  return (
    <div>
      {/*{!list && (*/}
      {/*  <div className={"Page__loader"}>*/}
      {/*    <Loading />*/}
      {/*  </div>*/}
      {/*)}*/}

      {!!list && (
        <table className="Table">
          <tbody>
            {list.map((company) => (
              <tr
                className="Table__tr"
                key={props.client.uuid + ":" + company.uuid}
              >
                <td className="Table__td">
                  <div className="Td__title">{company.short_name}</div>
                </td>
                <td className="Table__td Table__td_child"></td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
