import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../config/url";
import {
  getErrorMessageByKey,
  Textarea,
  useNotification,
  Modal,
  jsonFormatText,
  isValidateNullText, Input,
} from "@vokymlak/kabinet-ui";
import moment from "moment";
import {useNavigate} from "react-router-dom";

function ModalBoningOrderAdd(props) {
  const [isRequest, setIsRequest] = useState(false)

  const inputDate = useRef(null);

  const { notification } = useNotification();
  const navigate = useNavigate();


  const changeAdd = (isOpen) => {
    if (isRequest) {
      return;
    }

    const date = jsonFormatText(inputDate.current.value);


    if (isValidateNullText(date)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      date: date,
    });

    axios
      .post("/api/boning-order/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then((response) => {
        notification("Наряд добавлен", "green");
        if (isOpen && !!response.data) {
          navigate("/boning/" + response.data.uuid);
        } else {
          props.successfulRequest();
        }
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      })
      .finally(() => {
        setIsRequest(false);
      });
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <div className="Form">
        <div className="Form__title">
          Новая
          <div className="Text__signature">Обвалка</div>
        </div>

        <div className="Form__field">
          <Input reference={inputDate} type={'datetime-local'} isFixed={true} label="Дата *"
                 value={moment().format("YYYY-MM-DD HH:mm")}/>
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue"
                  onClick={() => changeAdd(false)}>
            Добавить
          </button>
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue"
                  onClick={() => changeAdd(true)}>
            Добавить и открыть
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalBoningOrderAdd;
