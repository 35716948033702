import React, {Fragment, useEffect, useState} from "react";
import {NavLink, useMatch, useNavigate, useParams} from "react-router-dom";
import "./NomenclaturesMenu.scss";
import { ScrollBlock } from "../../../components/ScrollBlock/ScrollBlock";
import { ROOT_URL } from "../../../config/url";
import {ButtonIcon, Search, useAction, useList, useObject, useUnroll} from "@vokymlak/kabinet-ui";

export const NomenclaturesMenu = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const match = useMatch("/nomenclatures/category/:nomenclatureCategoryUuid");

  const { isUnroll, changeUnroll } = useUnroll();

  const {
    object: [object],
    update: [_isUpdateObject, _setIsUpdateObject, updateObject],
  } = useObject({ apiUrl: "/api/nomenclature-categories/view", rootUrl: ROOT_URL});

  useEffect(() => {
    let nomenclatureCategoryUuid
    if (!!match && !!match.params && !!match.params.nomenclatureCategoryUuid) {
      nomenclatureCategoryUuid = match.params.nomenclatureCategoryUuid
    }

    if (!!object && !!object.nomenclature_categories && object.nomenclature_categories.length > 0 && !!nomenclatureCategoryUuid) {
      object.nomenclature_categories.forEach(l => {
        if (l.nomenclature_category_type.tag === "GROUP_CATEGORIES") {
          if (!!l.categories) {
            l.categories.forEach(category => {
              if (category.uuid === nomenclatureCategoryUuid) {
                if (!isUnroll(l.uuid)) {
                  changeUnroll(l.uuid)
                }
              }
            })
          }
        }
      })
    }
  }, [object])

  useAction(["nomenclatures", "nomenclature-categories"], () => updateObject());

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="NomenclaturesMenu">
        <div className="Menu__header">
          <div className="Header__text">
            Номенклатура
          </div>

          <ButtonIcon
            iconName={"custom-settings"}
            iconColor={"#20202033"}
            size={24}
            onClick={() => navigate("/nomenclatures/categories")}
          />
        </div>

        <ul className="Menu__list"  style={{paddingBottom: 0}}>
          <li className="List__link">
            <NavLink
              className="Link__text"
              to={"/nomenclatures/all"}
            >
              Вся
              {!!object && !!object.count_all_nomenclatures && (
                <span style={{
                  marginLeft: 4,
                  opacity: .75,
                  fontSize: 12
                }}>
                    ({object.count_all_nomenclatures})
                  </span>
              )}

              {!!object && !object.count_all_nomenclatures && (
                <span style={{
                  marginLeft: 4,
                  opacity: .75,
                  fontSize: 12
                }}>
                    (0)
                  </span>
              )}
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink
              className="Link__text"
              to={"/nomenclatures/no-category"}
            >
              Без категорий
              {!!object && !!object.count_no_category_nomenclatures && (
                <span style={{
                  marginLeft: 4,
                  opacity: .75,
                  fontSize: 12
                }}>
                    ({object.count_no_category_nomenclatures})
                  </span>
              )}

              {!!object && !object.count_no_category_nomenclatures && (
                <span style={{
                  marginLeft: 4,
                  opacity: .75,
                  fontSize: 12
                }}>
                    (0)
                  </span>
              )}
            </NavLink>
          </li>
        </ul>

        {!!object && (
          <ul className="Menu__list"  style={{paddingBottom: 0}}>
            <div
              className={"List__title"}
              style={{opacity: .75, cursor: 'default', fontSize: 16}}
            >
              По категориям

            </div>
            {!!object.nomenclature_categories && object.nomenclature_categories.map((item) => (
              !item.anchor_id && (
                <Fragment key={item.uuid}>

                {item.nomenclature_category_type.tag === "GROUP_CATEGORIES" && (
                  <Fragment>
                  <div
                    className={"List__group"}
                    onClick={() => changeUnroll(item.uuid)}
                  >
                    {item.title}
                    <ButtonIcon
                      tooltipText={null}
                      iconName={
                        isUnroll(item.uuid)
                          ? "custom-expand-circle-up"
                          : "custom-expand-circle-down"
                      }
                      iconColor={"#20202095"}
                      size={18}
                      style={{ marginLeft: 12, marginRight: 3 }}
                      onClick={() => null}
                    />
                  </div>

                    {isUnroll(item.uuid) && (
                      <ul className="List__menu">
                        {!!item.categories && item.categories.map((category) => (
                          <li key={category.uuid} className="List__link">
                            <NavLink
                              className="Link__text"
                              to={"/nomenclatures/category/" + category.uuid}
                            >
                              {category.title}
                              {!!category.count_nomenclatures && (
                                <span style={{
                                  marginLeft: 4,
                                  opacity: .75,
                                  fontSize: 12
                                }}>
                    ({category.count_nomenclatures})
                  </span>
                              )}

                              {!category.count_nomenclatures && (
                                <span style={{
                                  marginLeft: 4,
                                  opacity: .75,
                                  fontSize: 12
                                }}>
                    (0)
                  </span>
                              )}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    )}
                  </Fragment>
                )}

                {item.nomenclature_category_type.tag === "CATEGORY" && (
                  <li className="List__link">
                  <NavLink
                    className="Link__text"
                  to={"/nomenclatures/category/" + item.uuid}
                  >
                    {item.title}
                    {!!item.count_nomenclatures && (
                      <span style={{
                        marginLeft: 4,
                        opacity: .75,
                        fontSize: 12
                      }}>
                    ({item.count_nomenclatures})
                  </span>
                    )}

                    {!item.count_nomenclatures && (
                      <span style={{
                        marginLeft: 4,
                        opacity: .75,
                        fontSize: 12
                      }}>
                    (0)
                  </span>
                    )}
                  </NavLink>
                  </li>
                )}
                </Fragment>
              )
            ))}
          </ul>
        )}

        <ul className="Menu__list">
          <li className="List__link">
            <NavLink
              className="Link__text"
              to={"/nomenclatures/is-deleted"}
            >
              Удалённая (импорт)
                {!!object && !!object.count_deleted_nomenclatures && (
                  <span style={{
                    marginLeft: 4,
                    opacity: .75,
                    fontSize: 12
                  }}>
                    ({object.count_deleted_nomenclatures})
                  </span>
                )}

              {!!object && !object.count_deleted_nomenclatures && (
                <span style={{
                  marginLeft: 4,
                  opacity: .75,
                  fontSize: 12
                }}>
                    (0)
                  </span>
              )}

            </NavLink>
          </li>
        </ul>
      </div>
    </ScrollBlock>
  );
};
