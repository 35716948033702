import React, {Fragment, useEffect, useInsertionEffect, useLayoutEffect} from "react";
import {ButtonIcon, Loading} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../../components/ScrollBlock/ScrollBlock";
import ModalIncomeEdit from "../IncomeOrderItem/ModalIncomeEdit";
import {ModalAddOrderItem2} from "../IncomeOrderItem/ModalAddOrderItem2";
import ModalOrderItemTransactionRollback from "../IncomeOrderItem/ModalOrderItemTransactionRollback";
import ModalOrderItemTransaction from "../IncomeOrderItem/ModalOrderItemTransaction";
import ModalOrderItemEdit3 from "../IncomeOrderItem/ModalOrderItemEdit3";
import {IncomeOrderItem} from "../IncomeOrderItem";

function IncomeOrderView(props) {
  const {
    object,

    searchQuery,

    setIsUpdateObject,
    isUpdateObject,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    isModalObjectAdd, setIsModalObjectAdd,
    modalItemEdit, setModalItemEdit,
    modalOrderItem2Add, setModalOrderItem2Add,
    modalOrderItem3Edit, setModalOrderItem3Edit,

    modalAddBoning, setModalAddBoning,

    modalItemTransaction, setModalItemTransaction,
    modalItemTransactionRollback, setModalItemTransactionRollback,

    modalOrderEditAction, setModalOrderEditAction,
  } = props;

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Поступление
          </div>

          <div className="Title__settings">
            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 8}}
              onClick={() => (setIsUpdateObject(!isUpdateObject))}
            />
          </div>
        </div>

        {!object && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!object && (
          <div className="Page__content">
            <ul className="List">
              <Fragment key={"item_" + object.uuid}>
                <IncomeOrderItem {...props} isUnrollObject={true} />
              </Fragment>
            </ul>
          </div>
        )}

        {!!modalItemEdit && (
          <ModalIncomeEdit
            object={modalItemEdit}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalItemEdit(null)}
          />
        )}

        {!!modalOrderItem2Add && (
          <ModalAddOrderItem2
            object={modalOrderItem2Add}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalOrderItem2Add(null)}
          />
        )}

        {!!modalOrderItem3Edit && (
          <ModalOrderItemEdit3
            object={modalOrderItem3Edit}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalOrderItem3Edit(null)}
          />
        )}

        {!!modalItemTransaction && (
          <ModalOrderItemTransaction
            object={modalItemTransaction}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalItemTransaction(null)}
          />
        )}

        {!!modalItemTransactionRollback && (
          <ModalOrderItemTransactionRollback
            object={modalItemTransactionRollback}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalItemTransactionRollback(null)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default IncomeOrderView;
