import React, { useEffect, useState } from "react";
import { ROOT_URL } from "../../../../../config/url";
import {useList, useAction, useUnroll, getErrorMessageByKey, useNotification} from "@vokymlak/kabinet-ui";
import axios from "axios";

function useNomenclatureSpecifications(props) {
  const [selectAct, setSelectAct] = useState(null);

  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
  } = useList({
    apiUrl: "/api/nomenclature/" + props.object.uuid + "/specifications",
    rootUrl: ROOT_URL,
  });

  const {notification} = useNotification();

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const { setAction } = useAction(props.object.uuid, () => updateList());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, props.object.uuid]);
    } else {
      setAction(props.object.uuid);
    }
  }, [list]);

  const [modalImport, setModalImport] = useState(null);
  const [modalAdd, setModalAdd] = useState(null);
  const [modalEdit, setModalEdit] = useState(null);

  const isBasicSpecification = (object) => {
    axios
      .get("/api/specification/" + object.uuid + "/edit/basic", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsUpdateList(!isUpdateList);
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return {
    object: props.object,

    list,
    setList,
    count,

    setIsUpdateList,
    isUpdateList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    modalAdd,
    setModalAdd,

    modalEdit,
    setModalEdit,

    setModalImport,
    modalImport,

    selectAct,
    setSelectAct,

    isBasicSpecification,
  };
}

export default useNomenclatureSpecifications;
