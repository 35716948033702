import React, { useEffect, useRef, useState } from "react";
import { ROOT_URL } from "../../../../config/url";
import {
  useList,
  useProfile,
  useAction,
  useUnroll,
} from "@vokymlak/kabinet-ui";
import {useViewer} from "../../../../components/Viewer";
import {useParams} from "react-router-dom";

function useTickets(props) {
  const { isArchive, isTopicNull, authorId, executorId } = props;
  const { ticketTopicUuid } = useParams();
  const { profile } = useProfile();

  const { viewer } = useViewer();

  const changeViewer = (image, mimeType) => {
    viewer(image, mimeType);
  };

  const {
    list: [employees],
  } = useList({ apiUrl: "/api/employees/list", rootUrl: ROOT_URL });

  const {
    list: [types],
  } = useList({ apiUrl: "/api/ticket-types", rootUrl: ROOT_URL });

  const {
    list: [statuses],
  } = useList({ apiUrl: "/api/ticket-statuses", rootUrl: ROOT_URL });

  const {
    list: [topics],
  } = useList({ apiUrl: "/api/ticket-topics", rootUrl: ROOT_URL });

  const getUrlParams = () => {
    let params = null;
    if (isArchive) {
      params = {
        ...params,
        archive: isArchive,
      };
    }
    if (ticketTopicUuid) {
      params = {
        ...params,
        topic: ticketTopicUuid,
      };
    }
    if (isTopicNull) {
      params = {
        ...params,
        "no-topic": isTopicNull,
      };
    }
    if (authorId) {
      params = {
        ...params,
        "author": authorId,
      };
    }
    if (executorId) {
      params = {
        ...params,
        "executor": executorId,
      };
    }
    return params;
  };

  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, changeUpdate],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
    setApiUrlParams,
  } = useList({
    apiUrl: "/api/tickets",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 100,
    isSearch: true,
    urlParams: getUrlParams(),
  });

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  useAction("tickets", () => changeUpdate());

  const inputSearchText = useRef(null);
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  useEffect(() => {
    setApiUrlParams({ ...getUrlParams() });
  }, [isArchive, ticketTopicUuid, isTopicNull, authorId, executorId]);

  const onChangeSearchText = () => {
    setSearchQuery(inputSearchText.current.value);
  };

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  return {
    profile,

    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    // isArchive,
    // setIsArchive,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    changeViewer,

    isArchive,
    isTopicNull,
    ticketTopicUuid,
    authorId,
    executorId,

    types,
    statuses,
    employees,
    topics,
  };
}

export default useTickets;
