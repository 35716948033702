import React, {useEffect, useRef, useState} from "react";
import { ROOT_URL } from "../../../../config/url";
import {useList, useAction, useUnroll, useObject} from "@vokymlak/kabinet-ui";
import {useParams} from "react-router-dom";

function useBoningOrder() {
  let { boningOrderUuid } = useParams();

  const {
    object: [object],
    update: [isUpdateObject, setIsUpdateObject, updateObject],
  } = useObject({
    apiUrl: "/api/boning-order/" + boningOrderUuid,
    rootUrl: ROOT_URL,
  });

  const {unrolls, isUnroll, changeUnroll, changeAllUnroll} = useUnroll();

  useAction([boningOrderUuid], () => updateObject());

  const [isModalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [modalItemEdit, setModalItemEdit] = useState(null);
  const [modalOrderItemAdd, setModalOrderItemAdd] = useState(null);
  const [modalOrderItemEdit, setModalOrderItemEdit] = useState(null);

  const [modalAddBoning, setModalAddBoning] = useState(null);
  const [modalItemTransaction, setModalItemTransaction] = useState(null);
  const [modalItemTransactionRollback, setModalItemTransactionRollback] = useState(null);

  const [modalOrderEditAction, setModalOrderEditAction] = useState(null);

  return {
    object,

    setIsUpdateObject,
    isUpdateObject,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    isModalObjectAdd, setIsModalObjectAdd,
    modalItemEdit, setModalItemEdit,
    modalOrderItemAdd, setModalOrderItemAdd,
    modalOrderItemEdit, setModalOrderItemEdit,

    modalAddBoning, setModalAddBoning,
    modalItemTransaction, setModalItemTransaction,
    modalItemTransactionRollback, setModalItemTransactionRollback,

    modalOrderEditAction, setModalOrderEditAction,
  };
}

export default useBoningOrder;
