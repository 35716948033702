import React, {useRef, useState} from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
  useObject,
  Textarea,
  Input,
  jsonFormatText, Select, isValidateNullText,
} from "@vokymlak/kabinet-ui";

function ModalTransactionAddWriteOff(props) {
  const {
    object: [nomenclature],
  } = useObject({
    apiUrl: "/api/nomenclature/" + props.nomenclature.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputNote = useRef(null);

  const inputValue = useRef(null);

  const { notification } = useNotification();

  const numberConvertV = (numberString) => {
    return !!numberString && numberString.length > 0 && numberString !== "null"
      ? Number(String(Number(numberString).toFixed(3)))
      : null;
  };

  const changeAdd = (event) => {
    event.preventDefault();

    const note = jsonFormatText(inputNote.current.value);

    const value = inputValue.current.value;

    if (isValidateNullText(value)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      note: note,
      value: numberConvertV(value),
    });

    axios
      .post("/api/nomenclature/" + props.nomenclature.uuid + "/transaction/write-off", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Транзакция добавлена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!nomenclature && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div className="Form__title">
            {nomenclature.name}
            <div className="Text__signature">Списание</div>
          </div>

          {!nomenclature.is_pcs && (
            <div className="Form__field">
              <div className="Field__label">Единица измерения</div>
              <div className="Field__text">{(!!nomenclature.measurement_unit_type && nomenclature.measurement_unit_type.designation) || "-"}</div>
            </div>
          )}

          {nomenclature.is_pcs && (
            <div className="Form__field">
              <div className="Field__label">Единица измерения</div>
              <div className="Field__text">Штучно</div>
            </div>
          )}


          <div className="Form__field">
            <Input type={'number'} reference={inputValue} label="Размер *" />
          </div>

          <div className="Form__field Form__field_last">
            <Textarea reference={inputNote} label="Примечание" />
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Добавить
            </button>
          </div>

        </form>
      </Modal>
    )
  );
}

export default ModalTransactionAddWriteOff;
