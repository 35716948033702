import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Loading} from "@vokymlak/kabinet-ui";

const ImageUploadB64 = (props) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(props.url, {
          withCredentials: false,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        });
        if (!!response && !!response.data) {
          const imageBytes = response.data;
          const imageMimeType = response.headers.get('content-type')
          const imageSrcData = `data:${imageMimeType};base64,${imageBytes}`;
          setImageSrc(imageSrcData);
        } else {
          setImageSrc(null);
        }
      } catch (error) {
        console.error('Ошибка получения изображения', error);
        setIsError(true);
      }
    };

    fetchData().then();
  }, [props]);

  return (
    <>
      {!!props.width && !!props.height && (
        <div style={{
          backgroundColor: !isError && "#ff000010",
          background: !!imageSrc && `url(${imageSrc}) center center / cover`,
          width: props.width,
          height: props.height,
          borderRadius: 7,
          position: "relative",
          animation: "showModal 0.1s",
          zIndex: 2,
        }}>
          {!imageSrc && (
            <Loading size={'medium'}/>
          )}
        </div>
      )}

      {!props.width && !props.height && (
        <>
          {!imageSrc && (
            <Loading size={'medium'}/>
          )}
          {!!imageSrc && (
            <img src={imageSrc} alt="" style={{
              borderRadius: 7,
              position: "relative",
              margin: 32,
              animation: "showModal 0.1s",
              zIndex: 2,
            }}/>
          )}
        </>
      )}
    </>
  );
};

export default ImageUploadB64;