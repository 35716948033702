import React, { useContext, useRef, useState, createRef } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext, authLogin, useNotification } from "@vokymlak/kabinet-ui";
import { ROOT_URL } from "../../config/url";
import logotype from "./logotype.png";
import ReCAPTCHA from "react-google-recaptcha";
import "./Login.scss";
import Input from "./Input/Input";

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

export const Login = () => {
  const { authState, authDispatch } = useContext(AuthContext);
  const [recaptchaError, setRecaptchaError] = useState(false);
  const navigate = useNavigate();

  const inputLogin = useRef(null);
  const inputPassword = useRef(null);

  const { notification } = useNotification();

  const recaptchaRef = createRef();

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!authState.authRequest) {
      if (recaptchaError) {
        await onRecaptchaResolved();
      } else {
        recaptchaRef.current.execute();
      }
    }
  };

  async function onRecaptchaResolved() {
    const login = inputLogin.current.value;
    const password = inputPassword.current.value;

    if (!login || !password) {
      notification("Заполните все поля для входа", "red");
      return;
    }

    const jsonData = JSON.stringify({ login: login, password: password });

    return await authLogin(
      authDispatch,
      ROOT_URL,
      jsonData,
      () => {
        navigate("/");
      },
      (authError) => {
        notification(authError, "red");
      }
    );
  }

  return (
    <div className="Auth" onContextMenu={(event) => event.preventDefault()}>
      <div className="Auth__form">
        <form className="Form" onSubmit={(event) => handleLogin(event)}>
          <div className="Form__logotype">
            <img src={logotype} alt={"АГРОПРОДУКТ"} height={40} />
          </div>

          <div className="Form__title">Кабинет</div>

          <div className="Form__field">
            <Input inputRef={inputLogin} type={"text"} label={"Логин"} />
          </div>

          <div className="Form__field Form__field_last">
            <Input
              inputRef={inputPassword}
              type={"password"}
              passwordEye={true}
              label={"Пароль"}
            />
          </div>

          <div className="Form__actions Form__actions_center">
            <button
              className="Action__button Action__button_all Action__button_login"
              disabled={!authState.authRequest ? null : "disabled"}
            >
              Авторизоваться
            </button>
          </div>

          <ReCAPTCHA
            ref={recaptchaRef}
            locale="ru"
            size="invisible"
            sitekey={RECAPTCHA_KEY}
            onChange={onRecaptchaResolved}
            onErrored={() => setRecaptchaError(true)}
          />
        </form>
      </div>
    </div>
  );
};
