import React, { Fragment } from "react";
import { ScrollBlock } from "../../../components/ScrollBlock/ScrollBlock";
import {
  getAccountFio,
  Pagination,
  TextSearchMarker,
  Loading,
  Select,
  Search,
  ButtonIcon,
  Marker,
} from "@vokymlak/kabinet-ui";
import { Avatar } from "../../../components/Avatar/Avatar";
import moment from "moment/moment";
import { ModalAddDirector } from "./ModalAddDirector";
import { ModalEditDirector } from "./ModalEditDirector";
import {ROOT_URL} from "../../../config/url";

export const DirectorsPage = (props) => {
  const {
    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,

    isActivatedAccount,

    modalEditAccount,
    setModalEditAccount,
    isModalAddAccount,
    setIsModalAddAccount,
  } = props;

  function isMD5Hash(str) {
    // Проверяем, соответствует ли строка формату MD5 хэша.
    const md5Regex = /^[a-f0-9]{32}$/i;
    return md5Regex.test(str);
  }


  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Руководители
            {!!list && (
              <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                ({count})
              </span>
            )}
            <span style={{ width: 220, marginLeft: 16 }}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{ marginLeft: 8 }}
                onClick={() => changeAllUnroll(list, "created_at")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 8 }}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 100,
                      title: 100,
                    },
                    {
                      value: 1000,
                      title: 1000,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{ marginLeft: 8 }}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{ marginLeft: 12 }}
              onClick={() => setIsModalAddAccount(true)}
            />
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading />
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {list.map((item) => (
                <Fragment key={"item_" + item.account.uuid}>
                  <li
                    className={
                      isUnroll(item.created_at)
                        ? "List__item active"
                        : "List__item"
                    }
                    onClick={() => changeUnroll(item.created_at)}
                    style={{ paddingLeft: 8 }}
                  >
                    <div className="Item__title">
                      <div className="Element__title">
                        {!!item.account.avatar && isMD5Hash(item.account.avatar) && (
                          <img height={36} width={36} style={{
                            borderRadius: 36,
                          }}  src={ROOT_URL + "/api/account/avatar/" + item.account.avatar}/>
                        )}
                        {!!item.account.avatar && !isMD5Hash(item.account.avatar) && (
                          <img height={36} width={36} style={{
                            borderRadius: 36,
                          }}  src={"data:image/jpeg;base64, "+ item.account.avatar}/>
                        )}
                        {!item.account.avatar && (
                          <Avatar size={36} value={getAccountFio(item.account)} />
                        )}

                        <div style={{ marginLeft: 8 }}>
                          <TextSearchMarker
                            text={getAccountFio(item.account)}
                            search={searchQuery}
                          />
                          <div
                            style={{
                              fontSize: 12,
                              opacity: 0.5,
                            }}
                          >
                            <TextSearchMarker
                              text={"@" + item.account.login}
                              search={searchQuery}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Item__settings">
                      <div
                        style={{
                          display: "inline-block",
                          backgroundColor: "#202020",
                          padding: "4px 8px",
                          fontSize: 12,
                          borderRadius: 24,
                          color: "#e1e1e1",
                          marginRight: 12,
                          fontWeight: 700,
                        }}
                      >
                        {item.role_type.name}
                      </div>
                      <div className="Element__datetime">
                        {!!item.account.last_action_in &&
                          moment(
                            item.account.last_action_in.substring(0, 19)
                          ).format("HH:mm DD.MM.YYYY")}
                        <Marker
                          size={10}
                          style={{
                            marginLeft: 8,
                            marginRight: 4,
                          }}
                          color={
                            !!item.account.last_action_in
                              ? moment()
                                  .subtract(5, "minutes")
                                  .isBefore(
                                    moment(
                                      item.account.last_action_in.substring(
                                        0,
                                        19
                                      )
                                    )
                                  )
                                ? "#4caf50"
                                : "#9e9e9e"
                              : "#9e9e9e"
                          }
                        />
                      </div>
                    </div>
                  </li>

                  {isUnroll(item.created_at) && (
                    <li className="List__content">
                      <div className="Content__header">
                        <div className="Header__title"></div>
                        <div className="Header__settings">
                          <div className="Button__swith">
                            <div className="Swith__text">Доступ к работе</div>
                            <div
                              className={
                                "Swith" +
                                ((!!item.account.is_activated &&
                                  " Swith_active ") ||
                                  "")
                              }
                              onClick={() =>
                                isActivatedAccount(item.account.uuid)
                              }
                            ></div>
                          </div>
                          <ButtonIcon
                            tooltipText={"Редактировать"}
                            iconName={"custom-edit"}
                            iconColor={"#202020"}
                            size={36}
                            style={{ marginLeft: 12 }}
                            onClick={() => setModalEditAccount(item.account)}
                          />
                        </div>
                      </div>
                    </li>
                  )}
                </Fragment>
              ))}
            </ul>
          </div>
        )}

        {!!modalEditAccount && (
          <ModalEditDirector
            account={modalEditAccount}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalEditAccount(null)}
          />
        )}
        {!!isModalAddAccount && (
          <ModalAddDirector
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalAddAccount(false)}
          />
        )}
      </div>
    </ScrollBlock>
  );
};
