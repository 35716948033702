import React, {Fragment} from "react";
import {ButtonIcon, Loading,} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../../components/ScrollBlock/ScrollBlock";
import ModalProductionOrderEdit from "../ProductionOrderItem/ModalProductionOrderEdit";
import {ModalAddOrderItem2} from "../ProductionOrderItem/ModalAddOrderItem2";
import ModalOrderItemEdit3 from "../ProductionOrderItem/ModalOrderItemEdit3";
import ModalOrderItemTransaction from "../ProductionOrderItem/ModalOrderItemTransaction";
import {ModalAddEditOrderProduction} from "../ProductionOrderItem/ModalAddEditOrderProduction";
import ModalOrderItemTransactionRollback from "../ProductionOrderItem/ModalOrderItemTransactionRollback";
import ModalOrderEditAction from "../ProductionOrderItem/ModalOrderEditAction";
import {ProductionOrderItem} from "../ProductionOrderItem";

function ProductionOrderView(props) {
  const {
    object,

    setIsUpdateObject,
    isUpdateObject,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    isModalObjectAdd, setIsModalObjectAdd,
    modalItemEdit, setModalItemEdit,
    modalOrderItem2Add, setModalOrderItem2Add,
    modalOrderItem3Edit, setModalOrderItem3Edit,

    modalAddBoning, setModalAddBoning,

    modalItemTransaction, setModalItemTransaction,
    modalItemTransactionRollback, setModalItemTransactionRollback,

    modalOrderEditAction, setModalOrderEditAction,
  } = props;

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Производство
          </div>

          <div className="Title__settings">
            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 8}}
              onClick={() => (setIsUpdateObject(!isUpdateObject))}
            />
          </div>
        </div>

        {!object && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!object && (
          <div className="Page__content">
            <ul className="List">
              <Fragment key={"item_" + object.uuid}>
                <ProductionOrderItem {...props} isUnrollObject={true} />
              </Fragment>
            </ul>
          </div>
        )}

        {!!modalItemEdit && (
          <ModalProductionOrderEdit
            object={modalItemEdit}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalItemEdit(null)}
          />
        )}

        {!!modalOrderItem2Add && (
          <ModalAddOrderItem2
            object={modalOrderItem2Add}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalOrderItem2Add(null)}
          />
        )}

        {!!modalOrderItem3Edit && (
          <ModalOrderItemEdit3
            object={modalOrderItem3Edit}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalOrderItem3Edit(null)}
          />
        )}

        {!!modalItemTransaction && (
          <ModalOrderItemTransaction
            object={modalItemTransaction}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalItemTransaction(null)}
          />
        )}

        {!!modalAddBoning && (
          <ModalAddEditOrderProduction
            object={modalAddBoning}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalAddBoning(null)}
          />
        )}


        {!!modalItemTransactionRollback && (
          <ModalOrderItemTransactionRollback
            object={modalItemTransactionRollback}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalItemTransactionRollback(null)}
          />
        )}

        {!!modalOrderEditAction && (
          <ModalOrderEditAction
            object={modalOrderEditAction}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalOrderEditAction(null)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default ProductionOrderView;
