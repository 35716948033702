import React, {useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {getErrorMessageByKey, Modal, useList, useNotification,} from "@vokymlak/kabinet-ui";
import ModalSpecificationItemSelect
  from "../NomenclaturePage/components/NomenclatureSpecifications/ModalSpecificationItemSelect";

function ModalNomenclaturesInProduct(props) {
  const [selectItem, setSelectItem] = useState(null)
  const [isModalSelectItem, setIsModalSelectItem] = useState(false)

  const {notification} = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const jsonData = JSON.stringify({
      nomenclatures: props.nomenclatures,
      product: !!selectItem ? {
        uuid: selectItem.uuid,
      } : null,
    });

    axios
      .put("/api/nomenclatures/edit/product", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
      <>
        <Modal handleClose={() => props.handleClose()}>
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              Номенклатура
              <div className="Text__signature">Перенести в комплект</div>
            </div>

            {!selectItem && (
              <div className="Form__field Form__field_last"
                   style={{
                     cursor: "pointer"
                   }}
                   onClick={() => setIsModalSelectItem(true)}>
                <div className="Field__label">
                  К товару
                </div>
                <div className="Field__text">
                  -
                </div>
              </div>
            )}

            {!!selectItem && (
              <div className="Form__field Form__field_last"
                   style={{
                     cursor: "pointer"
                   }}
                   onClick={() => setIsModalSelectItem(true)}>
                <div className="Field__label">
                  К товару
                </div>
                <div className="Field__text">
                  №{selectItem.id} - {!!selectItem.name && selectItem.name}
                  {(!!selectItem.code || !!selectItem.article) && (
                    <div
                      style={{
                        fontSize: 13,
                        fontWeight: 500,
                      }}
                    >
                      {!!selectItem.code && selectItem.code}
                      {!!selectItem.code && !!selectItem.article && " | "}
                      {!!selectItem.code && selectItem.article}
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Перенести
              </button>
            </div>
          </form>
        </Modal>

        {isModalSelectItem && (
          <ModalSpecificationItemSelect
            item={selectItem}
            successfulRequest={item =>
              setSelectItem(item)
            }
            handleClose={() => setIsModalSelectItem(false)}
          />
        )}
      </>
  );
}

export default ModalNomenclaturesInProduct;
