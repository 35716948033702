import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
  useObject,
  isValidateNullText,
  Input,
} from "@vokymlak/kabinet-ui";
import moment from "moment";


function ModalOrderItemEdit3(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "api/income-order-item/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputValue = useRef(null);
  const inputPrice = useRef(null);

  const [isDelete, setIsDelete] = useState(false);

  const { notification } = useNotification();

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0 && numberString !== "null"
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const numberConvertV = (numberString) => {
    return !!numberString && numberString.length > 0 && numberString !== "null"
      ? Number(String(Number(numberString).toFixed(3)))
      : null;
  };

  const changeEdit = (event) => {
    event.preventDefault();

    const value = inputValue.current.value;
    const price = inputPrice.current.value;

    if (isValidateNullText(value)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      value: numberConvertV(value),
      amount: numberConvert(price),
    });

    axios
      .put("/api/income-order-item/" + props.object.uuid + "/edit/quantity", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("api/income-order-item/" + props.object.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Позиция на производство удалена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
        {!isDelete && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div
              className="Form__title"
              style={{ fontSize: 19, fontWeight: 700 }}
            >
              {object.nomenclature.name}
              <div className="Text__signature">№{object.income_order.id}{!!object.income_order.date && ' от ' + moment(object.income_order.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}</div>
            </div>

            <div className="Form__field">
              <div className="Field__label">Единица измерения</div>
              <div className="Field__text">{(!!object.nomenclature.measurement_unit_type && object.nomenclature.measurement_unit_type.designation) || "-"}</div>
            </div>

            <div className="Form__field">
              <Input reference={inputValue} type={'number'} label={"Размер *"}
                     value={(!!object.value && !!object.nomenclature.measurement_unit_type ?
                       (object.value / object.nomenclature.measurement_unit_type.ratio) : !!object.value && object.value) || "0"}
              />
            </div>

            <div className="Form__field Form__field_last">
              <Input type={'number'} reference={inputPrice} label="Сумма"
                     value={(!!object.amount && object.amount / 100) || "0"}/>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>
            <div className="Form__actions Form__actions_center">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>
            </div>
          </form>
        )}
        {isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div
              className="Form__title"
              style={{ fontSize: 19, fontWeight: 700 }}
            >
              {object.nomenclature.name}
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Удалить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsDelete(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalOrderItemEdit3;
