import React, { Fragment, useEffect, useState } from "react";
import ModalEditNomenclaturesCategory from "./ModalEditNomenclaturesCategory";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ModalAddNomenclaturesCategory from "./ModalAddNomenclaturesCategory";
import {
  ButtonIcon,
  Loading,
} from "@vokymlak/kabinet-ui";
import { ScrollBlock } from "../../../components/ScrollBlock/ScrollBlock";
import ModalCategoryInGroup from "./ModalCategoryInGroup";
import ModalCategoryFromGroup from "./ModalCategoryFromGroup";
import ModalNomenclaturesCategorySeo from "./ModalNomenclaturesCategorySeo";

export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

export const NomenclaturesCategoriesPage = (props) => {
  const {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalItemEdit,
    setModalItemEdit,
    isModalObjectAdd,
    setIsModalObjectAdd,

    onDragEnd,
  } = props;

  const [modalArticleInGroup, setModalArticleInGroup] = useState(null);
  const [modalArticleFromGroup, setModalArticleFromGroup] = useState(null);
  const [isModalSeoUrl, setIsModalSeoUrl] = useState(false);

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="NomenclaturesPage" style={{
        backgroundColor: "#f1f1f1"
      }}>
        <div className="Page__title">
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
              }}
            >
              Настройки категорий
              <span
                style={{
                  fontSize: 13,
                  opacity: 0.5,
                  fontWeight: 400
                }}
              >
                          Добавьте категорию и настройте отображение
                        </span>
            </div>
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{ marginLeft: 8 }}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 8 }}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            <ButtonIcon
              tooltipText={"Генерация SEO"}
              iconName={"edit-link"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 8 }}
              onClick={() => setIsModalSeoUrl(!isModalSeoUrl)}
            />

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{ marginLeft: 12 }}
              onClick={() => setIsModalObjectAdd(true)}
            />
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading />
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              <DragDropContext onDragEnd={onDragEnd}>
                <StrictModeDroppable droppableId={"droppable"}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {!!list &&
                        list.map((item, index) => (
                          <Draggable
                            draggableId={String(item.uuid)}
                            index={index}
                            key={"draggable_" + item.uuid}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                <Fragment key={"item_" + item.uuid}>
                                  <li
                                    className={
                                      isUnroll(item.uuid)
                                        ? "List__item active"
                                        : "List__item"
                                    }
                                    style={{
                                      position: "relative",
                                      marginBottom: 12
                                    }}
                                    onClick={() => changeUnroll(item.uuid)}
                                  >
                                    <div
                                      className="Item__title"
                                      style={{
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        className="Element__title"
                                        style={{
                                          flexDirection: "column",
                                        }}
                                      >
                                        <div
                                          style={{
                                            fontSize: 19,
                                            fontWeight: 700,
                                            width: "100%",
                                          }}
                                        >
                                          {item.title}
                                        </div>

                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            position: "absolute",
                                            top: -8,
                                            left: 0,
                                            justifyContent: " flex-end",
                                            width: "100%",
                                            padding: "0 8px",
                                          }}
                                        >

                                          {!!item.seo_url && (
                                            <div
                                              style={{
                                                fontSize: 11,
                                                fontWeight: 700,
                                                backgroundColor: "#dbdbdb",
                                                display: "inline-block",
                                                color: "#202020",
                                                padding: "2px 4px 2px",
                                                borderRadius: 3,
                                                margin: "0 2px",
                                              }}
                                            >
                                              {(!!item.seo_url && item.seo_url) || "-"}
                                            </div>
                                          )}

                                          {!!item.is_published && (
                                            <div
                                              style={{
                                                fontSize: 11,
                                                fontWeight: 700,
                                                backgroundColor: "#4caf50",
                                                display: "inline-block",
                                                color: "white",
                                                padding: "2px 4px 2px",
                                                borderRadius: 3,
                                                margin: "0 2px",
                                              }}
                                            >
                                              На розницу
                                            </div>
                                          )}

                                          {!!item.is_published_opt && (
                                            <div
                                              style={{
                                                fontSize: 11,
                                                fontWeight: 700,
                                                backgroundColor: "#cf9400",
                                                display: "inline-block",
                                                color: "white",
                                                padding: "2px 4px 2px",
                                                borderRadius: 3,
                                                margin: "0 2px",
                                              }}
                                            >
                                              На опт
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="Item__settings">
                                    <div className="Element__datetime"></div>
                                    </div>
                                  </li>

                                  {isUnroll(item.uuid) && (
                                    <li className="List__content">
                                      <div className="Content__header">
                                        <div className="Header__title">
                                          {item.nomenclature_category_type.tag === "CATEGORY" && (
                                            <div
                                              className="Button__text"
                                              style={{
                                                marginRight: 24,
                                              }}
                                              onClick={() =>
                                                setModalArticleInGroup(item)
                                              }
                                            >
                                              Перенести в группу
                                            </div>
                                          )}
                                        </div>
                                        <div className="Header__settings">
                                          <ButtonIcon
                                            tooltipText={"Редактировать"}
                                            iconName={"custom-edit"}
                                            iconColor={"#202020"}
                                            size={36}
                                            style={{marginLeft: 12}}
                                            onClick={() => setModalItemEdit(item)}
                                          />
                                        </div>
                                      </div>

                                      {!!item.categories && (
                                        <div>
                                          <ul className="List">
                                            <DragDropContext onDragEnd={onDragEnd}>
                                              <StrictModeDroppable droppableId={item.uuid}>
                                                {(provided) => (
                                                  <div ref={provided.innerRef} {...provided.droppableProps}>
                                                    {!!item.categories &&
                                                      item.categories.map((category, index) => (
                                                        <Draggable
                                                          draggableId={String(category.uuid)}
                                                          index={index}
                                                          key={"draggable_" + category.uuid}
                                                        >
                                                          {(provided) => (
                                                            <div
                                                              ref={provided.innerRef}
                                                              {...provided.dragHandleProps}
                                                              {...provided.draggableProps}
                                                            >
                                                              <Fragment key={"category_" + category.uuid}>
                                                                <li
                                                                  className={
                                                                    isUnroll(category.uuid)
                                                                      ? "List__item active"
                                                                      : "List__item"
                                                                  }
                                                                onClick={() => changeUnroll(category.uuid)}
                                                              >
                                                                <div className="Item__title">
                                                                  <div
                                                                    className="Element__title"
                                                                    style={{
                                                                      display: "flex",
                                                                      alignItems: "center",
                                                                    }}
                                                                  >
                                                                    {category.title}
                                                                  </div>
                                                                </div>
                                                                <div className="Item__settings">
                                                                  <div className="Element__datetime"></div>
                                                                </div>
                                                              </li>

                                                              {isUnroll(category.uuid) && (
                                                                <li className="List__content">
                                                                  <div className="Content__header">
                                                                    <div className="Header__title">
                                                                      {category.nomenclature_category_type.tag === "CATEGORY" && (
                                                                        <div
                                                                          className="Button__text"
                                                                          style={{
                                                                            marginRight: 24,
                                                                          }}
                                                                          onClick={() =>
                                                                            setModalArticleInGroup(category)
                                                                          }
                                                                        >
                                                                          Перенести в группу
                                                                        </div>
                                                                      )}
                                                                      <div
                                                                        className="Button__text"
                                                                        style={{
                                                                          marginRight: 24,
                                                                        }}
                                                                        onClick={() =>
                                                                          setModalArticleFromGroup(category)
                                                                        }
                                                                      >
                                                                        Убрать из группы
                                                                      </div>
                                                                    </div>
                                                                    <div className="Header__settings">
                                                                      <ButtonIcon
                                                                        tooltipText={"Редактировать"}
                                                                        iconName={"custom-edit"}
                                                                        iconColor={"#202020"}
                                                                        size={36}
                                                                        style={{ marginLeft: 12 }}
                                                                        onClick={() => setModalItemEdit(category)}
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                </li>
                                                              )}
                                                            </Fragment>
                                                          </div>
                                                        )}
                                                      </Draggable>
                                                    ))}
                                                  {provided.placeholder}
                                                </div>
                                              )}
                                            </StrictModeDroppable>
                                          </DragDropContext>
                                        </ul>
                                      </div>
                                        )}
                                    </li>
                                  )}
                                </Fragment>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </StrictModeDroppable>
              </DragDropContext>
            </ul>
          </div>
        )}

        {!!modalItemEdit && (
          <ModalEditNomenclaturesCategory
            object={modalItemEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemEdit(null)}
          />
        )}
        {!!isModalObjectAdd && (
          <ModalAddNomenclaturesCategory
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalObjectAdd(false)}
          />
        )}


        {!!modalArticleInGroup && (
          <ModalCategoryInGroup
            object={modalArticleInGroup}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalArticleInGroup(null)}
          />
        )}
        {!!modalArticleFromGroup && (
          <ModalCategoryFromGroup
            object={modalArticleFromGroup}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalArticleFromGroup(null)}
          />
        )}

        {isModalSeoUrl && (
          <ModalNomenclaturesCategorySeo
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalSeoUrl(!isModalSeoUrl)}
          />
        )}
      </div>
    </ScrollBlock>
  );
};
