import React, {useEffect, useRef, useState} from "react";
import { ROOT_URL } from "../../../../config/url";
import {useList, useAction, useUnroll,} from "@vokymlak/kabinet-ui";

function useProductionOrders() {
  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
  } = useList({
    apiUrl: "/api/production-orders",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 10,
    isSearch: true,
  });

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const { setAction } = useAction(["production"], () => updateList());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "production"]);
    } else {
      setAction(["production"]);
    }
  }, [list]);

  const inputSearchText = useRef(null);
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  const onChangeSearchText = () => {
    setSearchQuery(inputSearchText.current.value.trim());
  };

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  const [isModalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [modalItemEdit, setModalItemEdit] = useState(null);
  const [modalOrderItem2Add, setModalOrderItem2Add] = useState(null);
  const [modalOrderItem3Edit, setModalOrderItem3Edit] = useState(null);

  const [modalAddBoning, setModalAddBoning] = useState(null);
  const [modalItemTransaction, setModalItemTransaction] = useState(null);
  const [modalItemTransactionRollback, setModalItemTransactionRollback] = useState(null);

  const [modalOrderEditAction, setModalOrderEditAction] = useState(null);

  return {
    list,
    count,
    setList,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,

    isModalObjectAdd, setIsModalObjectAdd,
    modalItemEdit, setModalItemEdit,
    modalOrderItem2Add, setModalOrderItem2Add,
    modalOrderItem3Edit, setModalOrderItem3Edit,

    modalAddBoning, setModalAddBoning,

    modalItemTransaction, setModalItemTransaction,
    modalItemTransactionRollback, setModalItemTransactionRollback,

    modalOrderEditAction, setModalOrderEditAction,
  };
}

export default useProductionOrders;
