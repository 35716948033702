import React, { useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  useObject,
  useList,
  Select,
  Modal,
} from "@vokymlak/kabinet-ui";

function ModalNomenclaturesInCategory(props) {
  const {
    list: [list],
  } = useList({ apiUrl: "/api/nomenclature-categories/list", rootUrl: ROOT_URL });

  const selectNomenclatureCategoryId = useRef(null);

  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const nomenclatureCategoryId = selectNomenclatureCategoryId.current.value;

    const jsonData = JSON.stringify({
      nomenclatures: props.nomenclatures,
      nomenclature_category_id: Number(nomenclatureCategoryId) ,
    });


    axios
      .put("/api/nomenclatures/edit/category", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Без категории",
      },
    ];

    list.map((item) => {
      if (item.nomenclature_category_type.tag === "CATEGORY") {
        const option = {
          value: item.id,
          title: item.title,
        };

        options.push(option);
      }
    });

    return options;
  };

  return (
    !!list && (
      <Modal handleClose={() => props.handleClose()}>
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              Номенклатура
              <div className="Text__signature">Перенести в категорию</div>
            </div>

            <div className="Form__field Form__field_last">
              <Select
                reference={selectNomenclatureCategoryId}
                isFixed={true}
                options={!!list ? getSelectOptions(list) : getSelectOptions([])}
                label="Группа категорий"
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Перенести
              </button>
            </div>
          </form>
      </Modal>
    )
  );
}

export default ModalNomenclaturesInCategory;
