import React, {Fragment, useState} from "react";
import {ButtonIcon} from "@vokymlak/kabinet-ui";
import styleContent from "../../../../styles/modules/Block.module.scss";

function IncomeOrderIncomeList(props) {
  const {
    object,

    setModalOrderItem3Edit,

    setModalItemTransaction,
    setModalItemTransactionRollback,
  } = props;

  const [selectComposition, setSelectComposition] = useState(null);

  const isSelectComposition = (composition) => {
    return !!selectComposition && selectComposition.uuid === composition.uuid;
  };


  return (
    !!object && (
      <Fragment>

        <div className={styleContent._block} style={{marginTop: 12}}>
          <div className={styleContent._block__title}>Поступление</div>
          <div className={styleContent._block__content}>
            <table className="Table">
              <tbody>
              {object.items &&
                object.items.map((item, index) => (
                  <Fragment key={"issuance_" + item.uuid}>
                    <tr
                      className={
                        isSelectComposition(item)
                          ? "Table__tr Table__tr_hover Table__tr_focus"
                          : "Table__tr Table__tr_hover"
                      }
                      style={{
                        borderStyle: "2px solid",
                        borderColor: "#bdbdbd",
                        borderBottomColor: isSelectComposition(item)
                          ? "#202020"
                          : "#bdbdbd",
                      }}
                    >
                      <td className="Table__td">
                        <div className="Td__title Td__title_flex">
                          {index + 1} # {item.nomenclature.name}
                        </div>
                      </td>
                      <td className="Table__td" style={{
                        width: "1%",
                        textAlign: "right"
                      }}>
                        <div className="Td__title">
                                    <span style={{
                                      fontWeight: 700,
                                      fontSize: 24,
                                    }}>

                                      {(!!item.value && !!item.nomenclature.measurement_unit_type
                                        ? (item.value / item.nomenclature.measurement_unit_type.ratio)
                                        : !!item.value && item.value) || "0"}
                                      {!!item.nomenclature.measurement_unit_type && <span style={{
                                        marginLeft: 4,
                                        fontWeight: 400,
                                        fontSize: 13
                                      }}>{item.nomenclature.measurement_unit_type.designation + '.'}</span>}

                                    </span>
                        </div>
                      </td>

                      <td className="Table__td" style={{
                        width: "1%",
                        textAlign: "right"
                      }}>
                        <div className="Td__title">
                                    <span style={{
                                      fontWeight: 700,
                                      fontSize: 24,
                                    }}>

                                       {(!!item.amount && new Intl.NumberFormat("ru-RU").format(Number(
                                         (item.amount / 100).toFixed(2)))) || "0"}
                                      <span style={{
                                        marginLeft: 4,
                                        fontWeight: 400,
                                        fontSize: 13
                                      }}>руб.</span>

                                    </span>
                        </div>
                      </td>
                      <td className="Table__td" style={{
                        width: "1%",
                        textAlign: "right"
                      }}>
                        <div className="Td__title" style={{
                          opacity: .75
                        }}>
                          (
                          <span style={{
                            fontWeight: 700,
                            fontSize: 17,
                          }}>
                            <>
                              {((!!item.value && !!item.nomenclature.measurement_unit_type
                                ? (item.value / item.nomenclature.measurement_unit_type.ratio)
                                : !!item.value && item.value) || 0) && !!item.amount && (
                                <>
                                  {new Intl.NumberFormat("ru-RU").format(Number(((item.amount / 100) / (!!item.nomenclature.measurement_unit_type
                                    ? (item.value / item.nomenclature.measurement_unit_type.ratio)
                                    : item.value)).toFixed(2)))}
                                </>
                              )}
                              {!!item.nomenclature.measurement_unit_type && <span style={{
                                marginLeft: 4,
                                fontWeight: 400,
                                fontSize: 13
                              }}>руб.&nbsp;/&nbsp;{item.nomenclature.measurement_unit_type.designation + '.'}</span>}
                            </>
                          </span>
                          )
                        </div>
                      </td>

                      <td className="Table__td"
                          style={{
                            width: "1%",
                            textAlign: "right"
                          }}>
                        <div
                          style={{
                            height: 36,
                            display: "flex",
                            marginLeft: 12,
                          }}
                        >
                          {!item.is_transaction && (
                            <ButtonIcon
                              tooltipText={"Редактировать"}
                              iconName={"custom-edit"}
                              iconColor={"#202020"}
                              size={36}
                              onClick={() =>
                                setModalOrderItem3Edit(item)
                              }
                            />
                          )}
                        </div>

                      </td>

                      <td className="Table__td"
                          style={{
                            width: "1%",
                            textAlign: "right"
                          }}>
                        <div
                          style={{
                            height: 28,
                            display: "flex",
                            marginLeft: 12,
                          }}
                        >
                          {item.is_transaction && (
                            <div
                              className={
                                "Button__collected"
                              }
                              style={{
                                marginLeft: 12,
                                height: 28,
                                padding: "3px 8px 4px",
                                display: "flex"
                              }}
                            >
                              Учтено
                              <ButtonIcon
                                style={{marginLeft: 12}}
                                tooltipText={"Отменить учёт"}
                                iconName={"edit-undo"}
                                iconColor={"#202020"}
                                size={28}
                                onClick={() =>
                                  setModalItemTransactionRollback(item)
                                }
                              />
                            </div>
                          )}
                          {!item.is_transaction && (
                            <div
                              className={
                                "Button__collected Button__collected_blue"
                              }
                              style={{
                                marginLeft: 12,
                                height: 28,
                                padding: "3px 8px 4px",
                                cursor: "pointer"
                              }}
                              onClick={() =>
                                setModalItemTransaction(item)
                              }
                            >
                              Учесть
                            </div>
                          )}
                        </div>

                      </td>
                    </tr>
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    )
  );
}

export default IncomeOrderIncomeList;
