import React, {useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../../config/url";
import {
  getErrorMessageByKey,
  Input,
  isValidateNullText,
  jsonFormatText,
  Modal,
  useNotification
} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";
import ModalContractorItemSelect from "./ModalContractorItemSelect";
import {useNavigate} from "react-router-dom";

function ModalIncomeAdd(props) {
  const [selectItem, setSelectItem] = useState(null)
  const [isModalSelectItem, setIsModalSelectItem] = useState(false)
  const [isRequest, setIsRequest] = useState(false)

  const inputDate = useRef(null);

  const {notification} = useNotification();
  const navigate = useNavigate();

  const changeAdd = (isOpen) => {
    if (isRequest) {
      return;
    }

    const date = jsonFormatText(inputDate.current.value);

    if (isValidateNullText(date)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      date: date,
      contractor: !!selectItem ? {
        uuid: selectItem.uuid,
      } : null,
    });

    setIsRequest(true);

    axios
      .post("/api/income-order/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then((response) => {
        notification("Наряд добавлен", "green");
        if (isOpen && !!response.data) {
          navigate("/income/" + response.data.uuid);
        } else {
          props.successfulRequest();
        }
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      })
      .finally(() => {
        setIsRequest(false);
      });
  };

  return (
    <>
      <Modal handleClose={() => props.handleClose()}>
        <div className="Form">
          <div className="Form__title">
            Новое
            <div className="Text__signature">Поступление</div>
          </div>

          <div className="Form__field">
            <Input reference={inputDate} type={'datetime-local'} isFixed={true} label="Дата *"
                   value={moment().format("YYYY-MM-DD HH:mm")}/>
          </div>

          {!selectItem && (
            <div className="Form__field Form__field_last"
                 style={{
                   cursor: "pointer"
                 }}
                 onClick={() => setIsModalSelectItem(true)}>
              <div className="Field__label">
                Контрагент
              </div>
              <div className="Field__text">
                -
              </div>
            </div>
          )}

          {!!selectItem && (
            <div className="Form__field Form__field_last"
                 style={{
                   cursor: "pointer"
                 }}
                 onClick={() => setIsModalSelectItem(true)}>
              <div className="Field__label">
                Контрагент
              </div>
              <div className="Field__text">
                <div
                  style={{
                    fontSize: 15,
                    fontWeight: 700,
                    width: "100%",
                  }}
                >
                  {!!selectItem.short_name && selectItem.short_name}
                  <div style={{
                    fontSize: 12,
                    fontWeight: 400,
                  }}>
                    {!!selectItem.name && selectItem.name}
                  </div>
                  <div style={{
                    fontSize: 12,
                    fontWeight: 400,
                  }}>
                    <span style={{marginRight: 8}}>ОГРН:&nbsp;{(!!selectItem.ogrn && selectItem.ogrn) || "-"}</span>
                    <span style={{marginRight: 8}}>ИНН:&nbsp;{(!!selectItem.inn && selectItem.inn) || "-"}</span>
                    <span style={{marginRight: 8}}>КПП:&nbsp;{(!!selectItem.kpp && selectItem.kpp) || "-"}</span>
                  </div>
                  <div style={{
                    fontSize: 12,
                    fontWeight: 400,
                  }}>
                    <span style={{marginRight: 8}}>Банк:&nbsp;{(!!selectItem.bank && selectItem.bank) || "-"}</span>
                    <span
                      style={{marginRight: 8}}>Номер&nbsp;счёта:&nbsp;{(!!selectItem.invoice_number && selectItem.invoice_number) || "-"}</span>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue"
                    onClick={() => changeAdd(false)}>
              Добавить
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue"
                    onClick={() => changeAdd(true)}>
              Добавить и открыть
            </button>
          </div>
        </div>
      </Modal>

      {isModalSelectItem && (
        <ModalContractorItemSelect
          item={selectItem}
          successfulRequest={item =>
            setSelectItem(item)
          }
          handleClose={() => setIsModalSelectItem(false)}
        />
      )}
    </>
  );
}

export default ModalIncomeAdd;
