import React, {Fragment} from "react";
import {ButtonIcon, getAccountFio, Loading, Pagination, Search, Select,} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../../components/ScrollBlock/ScrollBlock";
import ModalRealizationAdd from "../RealizationOrderItem/ModalRealizationAdd";
import ModalRealizationEdit from "../RealizationOrderItem/ModalRealizationEdit";
import {ModalAddOrderItem2} from "../RealizationOrderItem/ModalAddOrderItem2";
import ModalOrderItemTransactionRollback from "../RealizationOrderItem/ModalOrderItemTransactionRollback";
import ModalOrderItemTransaction from "../RealizationOrderItem/ModalOrderItemTransaction";
import ModalOrderItemEdit3 from "../RealizationOrderItem/ModalOrderItemEdit3";
import ModalOrderEditPayment from "../RealizationOrderItem/ModalOrderEditPayment";
import {RealizationOrderItem} from "../RealizationOrderItem";
import moment from "moment";
import styleContent from "../../../../styles/modules/Block.module.scss";
import styleCard from "../../../../styles/modules/Card.module.scss";
import ModalOrderFilterEdit from "./ModalOrderFilterEdit";
import ReactECharts from "echarts-for-react";

function RealizationOrdersPage(props) {
  const {
    list,
    count,
    setList,
    setIsUpdateList,
    isUpdateList,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    object,
    setObject,
    isUpdateObject,
    setIsUpdateObject,

    isModalObjectAdd, setIsModalObjectAdd,
    modalItemEdit, setModalItemEdit,
    modalOrderItem2Add, setModalOrderItem2Add,
    modalOrderItem3Edit, setModalOrderItem3Edit,

    modalAddBoning, setModalAddBoning,

    modalItemTransaction, setModalItemTransaction,
    modalItemTransactionRollback, setModalItemTransactionRollback,

    modalOrderEditAction, setModalOrderEditAction,
    modalOrderEditPayment, setModalOrderEditPayment,

    listYears,
    changeSelectYear,

    selectMonth,
    setSelectMonth,

    selectDay,
    setSelectDay,

    selectContractor,
    setSelectContractor,
    filterContractor,
    setFilterContractor,

    selectPaid,
    setSelectPaid,

    selectPayment,
    setSelectPayment,
    filterPayment,
    setFilterPayment,

    selectPaymaster,
    setSelectPaymaster,
    filterPaymaster,
    setFilterPaymaster,

    isModalFilterEdit,
    setIsModalFilterEdit,

    changeSelectDay,
    weekDayText,
    selectYear,
    days,
    toDay,

    isDayMarker,
    getDayCount,
    getDayAmount,

    option,

    isChart,
    setIsChart,
  } = props;

  const monthText = (month) => {
    const months = [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ];

    return months[Number(month) - 1];
  };

  const getYearsSelectOptions = (list) => {
    const options = [];

    list.map((item) => {
      const option = {
        value: item,
        title: item,
      };

      options.push(option);
    });

    return options;
  };

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Реализация
            {!!list && (
              <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({count})
              </span>
            )}
            <span style={{width: 140, marginLeft: 16}}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
            <span style={{width: 80, marginLeft: 16, fontSize: 17, fontWeight: 400}}>
              <Select
                isFixed={true}
                isDefaultOption={false}
                value={moment().format('YYYY')}
                options={
                  !!listYears
                    ? getYearsSelectOptions(listYears)
                    : getYearsSelectOptions([])
                }
                label="Год"
                onChange={(event) => changeSelectYear(event.target.value)}
              />
            </span>
          </div>

          <div className="Title__settings">
            {/*{!!list && (*/}
            {/*  <ButtonIcon*/}
            {/*    tooltipText={*/}
            {/*      list.length === unrolls.length ? "Свернуть" : "Развернуть"*/}
            {/*    }*/}
            {/*    iconName={*/}
            {/*      list.length === unrolls.length*/}
            {/*        ? "custom-unfold-less"*/}
            {/*        : "custom-unfold-more"*/}
            {/*    }*/}
            {/*    iconColor={"#20202075"}*/}
            {/*    size={28}*/}
            {/*    style={{marginLeft: 8}}*/}
            {/*    onClick={() => changeAllUnroll(list, "uuid")}*/}
            {/*  />*/}
            {/*)}*/}

            {/*{!!list && !!count && !!pageLimit && (*/}
            {/*  <div*/}
            {/*    style={{*/}
            {/*      marginLeft: 12,*/}
            {/*      width: 64,*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <Select*/}
            {/*      isFixed={true}*/}
            {/*      isDefaultOption={false}*/}
            {/*      value={pageLimit}*/}
            {/*      options={[*/}
            {/*        {*/}
            {/*          value: 10,*/}
            {/*          title: 10,*/}
            {/*        },*/}
            {/*        {*/}
            {/*          value: 100,*/}
            {/*          title: 100,*/}
            {/*        },*/}
            {/*      ]}*/}
            {/*      label="Лимит"*/}
            {/*      onChange={(event) => setPageLimit(Number(event.target.value))}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*)}*/}

            {/*{!!list && !!count && count > pageLimit && (*/}
            {/*  <div style={{marginLeft: 8}}>*/}
            {/*    <Pagination*/}
            {/*      totalRecords={count}*/}
            {/*      pageLimit={pageLimit}*/}
            {/*      currentPage={pageNumber}*/}
            {/*      onPageChanged={(data) => changePage(data)}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*)}*/}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 8}}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList), setObject(null), setIsUpdateObject(!isUpdateObject))}
            />

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{marginLeft: 12}}
              onClick={() => setIsModalObjectAdd(true)}
            />
          </div>
        </div>

        <div className={styleContent._block + " " + styleContent._block_bottom}>
          <div className={styleContent._block__content}
               style={{
                 alignItems: "center"
               }}>
            <div className={styleCard._block}
                 style={{
                   borderRadius: 7,
                   margin: "0px 8px 6px 0",
                   boxSizing: "border-box",
                   backgroundColor: "#fff",
                   border: "2px solid #fff"
                 }}
            >
              <div
                className={styleCard._block__number}
                style={{
                  fontSize: 19,
                }}
              >
                {(!!selectMonth && monthText(selectMonth)) || "Все"}
              </div>
              <div
                className={styleCard._block__title}
                style={{
                  fontSize: 13,
                }}
              >
                Месяц
              </div>
            </div>

            <div className={styleCard._block}
                 style={{
                   borderRadius: 7,
                   margin: "0px 8px 6px 0",
                   boxSizing: "border-box",
                   backgroundColor: "#fff",
                   border: "2px solid #fff"
                 }}
            >
              <div
                className={styleCard._block__number}
                style={{
                  fontSize: 19,
                }}
              >
                {(!!filterContractor && (filterContractor === "null"
                    ? "Не указан"
                    : ((!!selectContractor && !!selectContractor.short_name && selectContractor.short_name) || "-"))
                ) || "Все"}
              </div>
              <div
                className={styleCard._block__title}
                style={{
                  fontSize: 13,
                }}
              >
                Контрагент
              </div>
            </div>

            <div className={styleContent._block__content}
                 style={{
                   alignItems: "center"
                 }}>
              <div className={styleCard._block}
                   style={{
                     borderRadius: 7,
                     margin: "0px 8px 6px 0",
                     boxSizing: "border-box",
                     backgroundColor: "#fff",
                     border: "2px solid #fff"
                   }}
              >
                <div
                  className={styleCard._block__number}
                  style={{
                    fontSize: 19,
                  }}
                >
                  {(!!filterPayment && (filterPayment === "null"
                      ? "Не указана"
                      : (!!selectPayment && (
                        (selectPayment === "CASH" && "Наличными")
                        || (selectPayment === "CARD" && "Картой")
                        || (selectPayment === "ONLINE" && "Онлайн")
                        || (selectPayment === "CHECK" && "По счёту")
                        || (selectPayment === "PREPAID_EXPENSE" && "В счëт зарплаты")
                      )))
                  ) || "Все"}
                </div>
                <div
                  className={styleCard._block__title}
                  style={{
                    fontSize: 13,
                  }}
                >
                  Оплата
                </div>
              </div>
            </div>

            <div className={styleContent._block__content}
                 style={{
                   alignItems: "center"
                 }}>
              <div className={styleCard._block}
                   style={{
                     borderRadius: 7,
                     margin: "0px 8px 6px 0",
                     boxSizing: "border-box",
                     backgroundColor: "#fff",
                     border: "2px solid #fff"
                   }}
              >
                <div
                  className={styleCard._block__number}
                  style={{
                    fontSize: 19,
                  }}
                >
                  {(!!selectPaid && (
                    (selectPaid === "true" && "Да")
                    || (selectPaid === "false" && "Нет")
                  )) || "Все"}
                </div>
                <div
                  className={styleCard._block__title}
                  style={{
                    fontSize: 13,
                  }}
                >
                  Оплачен
                </div>
              </div>
            </div>

            <div className={styleContent._block__content}
                 style={{
                   alignItems: "center"
                 }}>
              <div className={styleCard._block}
                   style={{
                     borderRadius: 7,
                     margin: "0px 8px 6px 0",
                     boxSizing: "border-box",
                     backgroundColor: "#fff",
                     border: "2px solid #fff"
                   }}
              >
                <div
                  className={styleCard._block__number}
                  style={{
                    fontSize: 19,
                  }}
                >
                  {(!!filterPaymaster && (filterPaymaster === "null"
                      ? "Не указан"
                      : ((!!selectPaymaster && getAccountFio(selectPaymaster)) || "-"))
                  ) || "Все"}
                </div>
                <div
                  className={styleCard._block__title}
                  style={{
                    fontSize: 13,
                  }}
                >
                  Кассир
                </div>
              </div>
            </div>

            <div style={{
              height: 48,
              width: 2,
              backgroundColor: "#20202010",
              borderRadius: 4,
              marginRight: 8,
              marginBottom: 8,
              textAlign: "right"
            }}>
            </div>

            <div className={styleCard._block}
                 style={{
                   margin: "0px 8px 6px 0",
                   borderRadius: 7,
                   boxSizing: "border-box",
                   backgroundColor: "inherit",
                   border: "2px solid #20202010"
                 }}>
              <div
                className={styleCard._block__number}
                style={{
                  fontSize: 19,
                  textTransform: "capitalize"
                }}
              >
                {(!!object && !!object.count_all_amounts && new Intl.NumberFormat("ru-RU").format(Number(
                  (object.count_all_amounts / 100).toFixed(2)))) || "-"}
              </div>
              <div
                className={styleCard._block__title}
                style={{
                  fontSize: 13,
                }}
              >
                Сумма
              </div>
            </div>

            <div className={styleCard._block}
                 style={{
                   margin: "0px 8px 6px 0",
                   borderRadius: 7,
                   boxSizing: "border-box",
                   backgroundColor: "inherit",
                   border: "2px solid #20202010"
                 }}>
              <div
                className={styleCard._block__number}
                style={{
                  fontSize: 19,
                  textTransform: "capitalize"
                }}
              >
                {(!!object && !!object.count_all_orders && object.count_all_orders) || "-"}
              </div>
              <div
                className={styleCard._block__title}
                style={{
                  fontSize: 13,
                }}
              >
                Кол-во
              </div>
            </div>

            <div style={{
              height: 48,
              width: 2,
              backgroundColor: "#20202010",
              borderRadius: 4,
              marginRight: 8,
              marginBottom: 8,
              textAlign: "right"
            }}>
            </div>

            <ButtonIcon
              tooltipText={"Фильтры&nbsp;поиска"}
              iconName={"custom-search-filter"}
              iconColor={"#20202075"}
              size={24}
              style={{marginLeft: 12}}
              onClick={() => setIsModalFilterEdit(true)}
            />

            <ButtonIcon
              tooltipText={"График"}
              iconName={isChart ? "custom-expand-circle-up" : "custom-expand-circle-down"}
              iconColor={"#20202075"}
              size={24}
              style={{marginLeft: 12}}
              onClick={() => setIsChart(!isChart)}
            />
          </div>
        </div>

        {isChart && !!object && object.is_month && (
          <div
            className="Page__title"
            style={{
              marginBottom: 72,
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {!!days &&
              days.map((day, index) => (
                <div
                  key={index}
                  onClick={() => changeSelectDay(day.day)}
                  style={{
                    backgroundColor: "white",
                    borderRadius: 7,
                    padding: "3px 7px",
                    minWidth: 56,
                    margin: 2,
                    cursor: "pointer",
                    border: !!selectDay && !!selectMonth && !!selectYear &&
                    Number(moment(`${selectYear}-${selectMonth}-${selectDay}`, "YYYY-MM-DD").format("DD")) ===
                    Number(day.day) &&
                    Number(moment(`${selectYear}-${selectMonth}-${selectDay}`, "YYYY-MM-DD").format("MM")) ===
                    Number(selectMonth) &&
                    Number(moment(`${selectYear}-${selectMonth}-${selectDay}`, "YYYY-MM-DD").format("YYYY")) ===
                    Number(selectYear)
                      ? Number(toDay.format("DD")) === Number(day.day) &&
                      Number(toDay.format("MM")) === Number(selectMonth) &&
                      Number(toDay.format("YYYY")) === Number(selectYear)
                        ? "3px solid #f44336"
                        : "3px solid #202020"
                      : "3px solid white",
                    position: "relative",
                    opacity:
                      moment(
                        selectYear + "-" + selectMonth + "-" + day.day,
                        "YYYY-MM-DD"
                      ).weekday() === 0 ||
                      moment(
                        selectYear + "-" + selectMonth + "-" + day.day,
                        "YYYY-MM-DD"
                      ).weekday() === 6
                        ? 0.5
                        : 1,
                  }}
                >
                  <div
                    style={{
                      color:
                        Number(toDay.format("DD")) === Number(day.day) &&
                        Number(toDay.format("MM")) === Number(selectMonth) &&
                        Number(toDay.format("YYYY")) === Number(selectYear)
                          ? "#f44336"
                          : "#202020",
                      fontSize: 11,
                      textTransform: "capitalize",
                    }}
                  >
                    {weekDayText(
                      moment(
                        selectYear + "-" + selectMonth + "-" + day.day,
                        "YYYY-MM-DD"
                      ).weekday()
                    )}
                  </div>
                  <div
                    style={{
                      color:
                        Number(toDay.format("DD")) === Number(day.day) &&
                        Number(toDay.format("MM")) === Number(selectMonth) &&
                        Number(toDay.format("YYYY")) === Number(selectYear)
                          ? "#f44336"
                          : "#202020",
                      fontSize: 17,
                      fontWeight: 700,
                    }}
                  >
                    {day.day}
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: 3,
                      right: 3,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 8,
                        marginRight: 3,
                      }}
                    >
                      {getDayCount(object.date_orders, selectYear, selectMonth, day.day)}
                    </div>
                    {isDayMarker(
                      object.date_orders,
                      selectYear,
                      selectMonth,
                      day.day
                    ) && (
                      <div
                        className={"Marker Marker_small"}
                        style={{
                          backgroundColor: "#0064ff",
                        }}
                      ></div>
                    )}
                  </div>
                </div>
              ))}
          </div>
        )}

        {isChart && (
          <ReactECharts
            option={option}
            style={{height: "192px", width: "100%"}}
          />
        )}

        <hr className={"Page__hr"}/>

        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            {!!selectDay && !!selectMonth && !!selectYear && moment(`${selectYear}-${selectMonth}-${selectDay}`, "YYYY-MM-DD").format("DD.MM.YYYY")}
            {!selectDay && !!selectMonth && !!selectYear && monthText(selectMonth)}
            {!selectDay && !selectMonth && !!selectYear && selectYear}

            {!!object && !!selectDay && !!selectMonth && !!selectYear && <>
              {searchQuery.length === 0 && getDayCount(object.date_orders, selectYear, selectMonth, selectDay) && (
                <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({getDayCount(object.date_orders, selectYear, selectMonth, selectDay)})
              </span>
              )}
              {searchQuery.length > 0 && (
                <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({count})
              </span>
              )}
              {!!getDayAmount(object.date_orders, selectYear, selectMonth, selectDay) && (
                <span style={{
                  marginLeft: 12,
                  fontSize: 21,
                  fontWeight: 400,
                }}>
                {new Intl.NumberFormat("ru-RU").format(Number(
                  (getDayAmount(object.date_orders, selectYear, selectMonth, selectDay) / 100).toFixed(2)))}
                  <span
                    style={{
                      marginLeft: 4,
                      fontSize: 12,
                      fontWeight: 400,
                      opacity: 0.75,
                    }}
                  >
                            руб.
                          </span>

                        </span>
              )}
            </>}

            {!!object && !selectDay && !!selectMonth && !!selectYear && <>
              {searchQuery.length === 0 && !!object.count_all_orders && (
                <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({object.count_all_orders})
              </span>
              )}
              {searchQuery.length > 0 && (
                <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({count})
              </span>
              )}
              {!!object.count_all_amounts && (
                <span style={{
                  marginLeft: 12,
                  fontSize: 21,
                  fontWeight: 400,
                }}>
                {new Intl.NumberFormat("ru-RU").format(Number(
                  (object.count_all_amounts / 100).toFixed(2)))}
                  <span
                    style={{
                      marginLeft: 4,
                      fontSize: 12,
                      fontWeight: 400,
                      opacity: 0.75,
                    }}
                  >
                            руб.
                          </span>

                        </span>
              )}
            </>}

            {!!object && !selectDay && !selectMonth && !!selectYear && <>
              {searchQuery.length === 0 && !!object.count_all_orders && (
                <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({object.count_all_orders})
              </span>
              )}
              {searchQuery.length > 0 && (
                <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({count})
              </span>
              )}
              {!!object.count_all_amounts && (
                <span style={{
                  marginLeft: 12,
                  fontSize: 21,
                  fontWeight: 400,
                }}>
                {new Intl.NumberFormat("ru-RU").format(Number(
                  (object.count_all_amounts / 100).toFixed(2)))}
                  <span
                    style={{
                      marginLeft: 4,
                      fontSize: 12,
                      fontWeight: 400,
                      opacity: 0.75,
                    }}
                  >
                            руб.
                          </span>

                        </span>
              )}
            </>}
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{marginLeft: 8}}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            {!!list && !!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 10,
                      title: 10,
                    },
                    {
                      value: 100,
                      title: 100,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!list && !!count && count > pageLimit && (
              <div style={{marginLeft: 8}}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {list.map((item) => (
                <Fragment key={"item_" + item.uuid}>
                  <RealizationOrderItem {...props} object={item}/>
                </Fragment>
              ))}
            </ul>
          </div>
        )}

        {!!modalItemEdit && (
          <ModalRealizationEdit
            object={modalItemEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemEdit(null)}
          />
        )}
        {!!isModalObjectAdd && (
          <ModalRealizationAdd
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalObjectAdd(false)}
          />
        )}

        {!!modalOrderItem2Add && (
          <ModalAddOrderItem2
            object={modalOrderItem2Add}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalOrderItem2Add(null)}
          />
        )}

        {!!modalOrderItem3Edit && (
          <ModalOrderItemEdit3
            object={modalOrderItem3Edit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalOrderItem3Edit(null)}
          />
        )}

        {!!modalItemTransaction && (
          <ModalOrderItemTransaction
            object={modalItemTransaction}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemTransaction(null)}
          />
        )}

        {!!modalItemTransactionRollback && (
          <ModalOrderItemTransactionRollback
            object={modalItemTransactionRollback}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemTransactionRollback(null)}
          />
        )}

        {!!modalOrderEditPayment && (
          <ModalOrderEditPayment
            object={modalOrderEditPayment}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalOrderEditPayment(null)}
          />
        )}

        {!!isModalFilterEdit && (
          <ModalOrderFilterEdit
            {...props}
            handleClose={() => setIsModalFilterEdit(null)}
          />
        )}

      </div>
    </ScrollBlock>
  );
}

export default RealizationOrdersPage;
