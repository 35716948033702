import React, { useRef } from "react";
import {
  Modal,
  useList, Search, TextSearchMarker,
} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";
import moment from "moment/moment";

function ModalCowParentSelect(props) {
  const {
    list: [list],
    search: [searchQuery, setSearchQuery],
  } = useList({ apiUrl: "/api/cows/list", rootUrl: ROOT_URL,
    isSearch: true,
  urlParams: {
    gender: (!!props.typeTag && props.typeTag) || null
  }});

  const changeAdd = (item) => {
        props.successfulRequest(item);
        props.handleClose();
  };

  const inputSearchText = useRef(null);
  function onChangeSearchText() {
    setSearchQuery(inputSearchText.current.value);
  }

  return (
    !!list && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div className="Form__title">
            Выбор&nbsp;
            {!!props.typeTag && props.typeTag === "MALE" && "папы"}
            {!!props.typeTag && props.typeTag === "FEMALE" && "мамы"}
            <div className="Text__signature">КРС</div>
          </div>

          <div className="Form__field">
            <Search
              reference={inputSearchText}
              type="text"
              label={"Поиск"}
              isFixed={true}
              onChange={() => onChangeSearchText()}
            />
          </div>

          <div className="Form__field Form__field_last"
          style={{
            maxHeight: 250,
            overflowY: "auto"
          }}>
            {list.map((item, index) => (
              <div key={"item_" + index} onClick={() => changeAdd(item)} style={{
                padding: "4px 8px",
                border: "1px solid #dbdbdb",
                cursor: "pointer",
                marginBottom: 6,
                borderRadius: 5,
              }}>
                <TextSearchMarker
                  text={
                  (!!item.name
                    ? item.name
                    : "") +
                    "("+item.id+")"+" - "+
                    (!!item.birthdate
                      ? moment(item.birthdate.substring(0, 19)).format("DD.MM.YYYY")
                      : "")
                }
                  search={searchQuery}
                />
              </div>
            ))}
          </div>

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalCowParentSelect;
