import React, {Fragment, useEffect} from "react";
import {ButtonIcon, ButtonIconExport, getAccountFio, TextSearchMarker,} from "@vokymlak/kabinet-ui";
import moment from "moment";
import RealizationOrderRealizationList from "./RealizationOrderRealizationList";
import {ROOT_URL} from "../../../../config/url";
import {NavLink} from "react-router-dom";

function RealizationOrderItem(props) {
  const {
    object,

    searchQuery,

    setIsUpdateObject,
    isUpdateObject,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    isUnrollObject,

    isModalObjectAdd, setIsModalObjectAdd,
    modalItemEdit, setModalItemEdit,
    modalOrderItem2Add, setModalOrderItem2Add,
    modalOrderItem3Edit, setModalOrderItem3Edit,

    modalAddBoning, setModalAddBoning,

    modalItemTransaction, setModalItemTransaction,
    modalItemTransactionRollback, setModalItemTransactionRollback,

    modalOrderEditAction, setModalOrderEditAction,
    modalOrderEditPayment, setModalOrderEditPayment,
  } = props;

  useEffect(() => {
    if (isUnrollObject && !!object) {
      changeUnroll(object.uuid);
    }
  }, []);

  function isBoningOrderTransaction(object) {
    let isTransaction = false

    if (!!object.items) {
      object.items.forEach(i => {
        if (i.is_transaction) {
          isTransaction = true
        }
      })
    }

    return isTransaction
  }

  return (
    <>
      <li
        className={
          isUnroll(object.uuid) ? "List__item active" : "List__item"
        }
        onClick={() => changeUnroll(object.uuid)}
        style={{
          padding: "10px 12px",
          position: "relative",
          marginBottom: 12
        }}
      >
        <div
          className="Item__title"
          style={{
            width: "100%",
          }}
        >
          <div
            className="Element__title"
            style={{
              width: "100%",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                fontSize: 19,
                fontWeight: 700,
                width: "100%",
              }}
            >
              №<TextSearchMarker
              text={object.id}
              search={searchQuery}
            />{!!object.date && ' от ' + moment(object.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}
            </div>

            {!!object.contractor && (
              <div
                style={{
                  fontSize: 15,
                  fontWeight: 700,
                  width: "100%",
                  padding: "4px 8px",
                  border: "1px solid #bdbdbd",
                  marginBottom: 6,
                  marginTop: 6,
                  borderRadius: 5,
                }}
              >

                {!!object.contractor.short_name && (
                  <TextSearchMarker
                    text={object.contractor.short_name}
                    search={searchQuery}
                  />
                )}
                <div style={{
                  fontSize: 12,
                  fontWeight: 400,
                }}>
                  {!!object.contractor.name && (
                    <TextSearchMarker
                      text={object.contractor.name}
                      search={searchQuery}
                    />
                  )}
                </div>
                <div style={{
                  fontSize: 12,
                  fontWeight: 400,
                }}>
                              <span style={{marginRight: 8}}>ОГРН:&nbsp;{(!!object.contractor.ogrn && (
                                <TextSearchMarker
                                  text={object.contractor.ogrn}
                                  search={searchQuery}
                                />
                              )) || "-"}</span>
                  <span style={{marginRight: 8}}>ИНН:&nbsp;{(!!object.contractor.inn && (
                    <TextSearchMarker
                      text={object.contractor.inn}
                      search={searchQuery}
                    />
                  )) || "-"}</span>
                  <span style={{marginRight: 8}}>КПП:&nbsp;{(!!object.contractor.kpp && (
                    <TextSearchMarker
                      text={object.contractor.kpp}
                      search={searchQuery}
                    />
                  )) || "-"}</span>
                </div>
                <div style={{
                  fontSize: 12,
                  fontWeight: 400,
                }}>
                              <span
                                style={{marginRight: 8}}>Банк:&nbsp;{(!!object.contractor.bank && object.contractor.bank) || "-"}</span>
                  <span
                    style={{marginRight: 8}}>Номер&nbsp;счёта:&nbsp;{(!!object.contractor.invoice_number && (
                    <TextSearchMarker
                      text={object.contractor.invoice_number}
                      search={searchQuery}
                    />
                  )) || "-"}</span>
                </div>
              </div>
            )}

            {!!object.paymaster && (
              <div
                style={{
                  fontSize: 15,
                  fontWeight: 400,
                  width: "100%",
                  marginTop: 6,
                }}
              >
                <span style={{marginRight: 4}}>Кассир:</span>
                <TextSearchMarker
                  text={getAccountFio(object.paymaster)}
                  search={searchQuery}
                />
              </div>
            )}

            {!!object.final_amount && (
              <div
                style={{
                  width: "100%",
                  marginTop: 4,
                  fontWeight: 700,
                  fontSize: 24
                }}
              >
                          <span style={{marginRight: 16}}>
                            {!!object.final_amount && new Intl.NumberFormat("ru-RU").format(Math.round(Number(
                              (object.final_amount / 100).toFixed(2))))}

                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>
                            {!!object.final_amount && "(" + new Intl.NumberFormat("ru-RU").format(Number(
                              (object.final_amount / 100).toFixed(2))) + ")"}
                            </span>

                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб.</span></span>
              </div>
            )}

            {!!object.payment_type && (
              <div style={{
                width: "100%",
                backgroundColor:
                  object.payment_type === 'CASH'
                    ? "#4CAF5033"
                    : object.payment_type === 'CARD'
                      ? "#654CAF33"
                      : object.payment_type === 'ONLINE'
                        ? "#934CAF33"
                        : object.payment_type === 'CHECK'
                          ? "#ffc10733"
                          : object.payment_type === 'PREPAID_EXPENSE'
                            ? "#0061fc33"
                            : "#0061fc33",
                padding: "6px 8px 6px",
                borderRadius: 5,
                marginBottom: 6,
                marginTop: 6
              }}
              >
                <div style={{
                  fontSize: 13
                }}>Оплата
                </div>
                <div style={{
                  marginRight: 2,
                  fontSize: 15,
                }}>
                  {object.payment_type === 'CASH' && 'Наличными'}
                  {object.payment_type === 'CARD' && 'Картой'}
                  {object.payment_type === 'ONLINE' && 'Онлайн'}
                  {object.payment_type === 'CHECK' && 'По счёту'}
                  {object.payment_type === 'PREPAID_EXPENSE' && 'В счëт зарплаты'}
                </div>
              </div>
            )}

            {!!object.items && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  marginTop: 2,
                }}
              >
                <div
                  style={{
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                >
                  {object.items.map((i, index) => (
                    <div key={"k_" + object.uuid + ":" + i.uuid}
                         style={{marginTop: 3, marginBottom: 3}}>
                      <div style={{
                        fontSize: 12,
                        backgroundColor: !i.is_transaction ? "#0061fc33" : "#20202033",
                        display: "inline-block",
                        color: "#202020",
                        padding: "2px 4px 2px",
                        borderRadius: 3,
                        margin: "0 2px",
                      }}>
                        {index + 1} # {i.nomenclature.name}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "absolute",
                top: -8,
                right: 0,
                justifyContent: "flex-start",
                width: "100%",
                padding: "0 8px",
              }}
            >
              {object.is_paid && (
                <div
                  style={{
                    fontSize: 11,
                    fontWeight: 700,
                    backgroundColor: "#ffe082",
                    display: "inline-block",
                    color: "#202020",
                    padding: "2px 4px 2px",
                    borderRadius: 3,
                    margin: "0 2px",
                  }}
                >
                  Оплачен!
                </div>
              )}
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "absolute",
                top: -8,
                left: 0,
                justifyContent: " flex-end",
                width: "100%",
                padding: "0 8px",
              }}
            >
            </div>
          </div>
        </div>
        <div className="Item__settings">
          <div className="Element__datetime">
          </div>
        </div>
      </li>

      {isUnroll(object.uuid) && (
        <li className="List__content" style={{
          backgroundColor: "#00000005",
          border: "1px solid #00000005",
          borderRadius: 7,
          marginBottom: 6,
          paddingTop: 6,
          paddingRight: 14,
          paddingLeft: 14,
        }}>
          <div className="Content__header">
            <div className="Header__title">
              {!object.is_paid && (
                <div
                  className="Button__text Button__text_right"
                  onClick={() => setModalOrderItem2Add(object)}
                >
                  Добавить позицию
                </div>
              )}

              <NavLink
                className="Button__text Button__text_right"
                to={"/realization/" + object.uuid}>
                Подробнее
              </NavLink>
            </div>
            <div className="Header__settings">
              <div
                className="Button__text Button__text_left"
                onClick={() => setModalOrderEditPayment(object)}
              >
                Отметка об оплате
              </div>
              <ButtonIconExport
                tooltipText={"Расходная накладная"}
                iconName={"custom-download"}
                iconColor={"#202020"}
                size={24}
                style={{marginLeft: 12}}
                apiUrl={"/api/realization-order/" + object.uuid + "/export/print"}
                rootUrl={ROOT_URL}
                mimeType={
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
                fileName={"№" + object.id + (!!object.date && '_от_' + moment(object.date.substring(0, 19)).format("DD.MM.YYYY")) + ".xlsx"}
              />
              <ButtonIconExport
                tooltipText={"Товарная накладная"}
                iconName={"custom-archive"}
                iconColor={"#202020"}
                size={24}
                style={{marginLeft: 12}}
                apiUrl={"/api/realization-order/" + object.uuid + "/export/print/packing-list"}
                rootUrl={ROOT_URL}
                mimeType={
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
                fileName={"№" + object.id + (!!object.date && '_от_' + moment(object.date.substring(0, 19)).format("DD.MM.YYYY")) + ".xlsx"}
              />

              {!isBoningOrderTransaction(object) && (
                <ButtonIcon
                  tooltipText={"Редактировать"}
                  iconName={"custom-edit"}
                  iconColor={"#202020"}
                  size={36}
                  style={{marginLeft: 12}}
                  onClick={() => setModalItemEdit(object)}
                />
              )}
            </div>
          </div>

          <RealizationOrderRealizationList
            {...props}
            object={object}
            isUpdateList={isUpdateObject}
            setIsUpdateList={setIsUpdateObject}
          />

          <div className="Content__footer">
            <div className="Footer__title">
              {!object.is_paid && (
                <div
                  className="Button__text Button__text_right"
                  onClick={() => setModalOrderItem2Add(object)}
                >
                  Добавить позицию
                </div>
              )}
            </div>
            <div className="Footer__settings">
            </div>
          </div>
        </li>
      )}
    </>
  );
}

export default RealizationOrderItem;
