import React, {useRef, useState} from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../config/url";
import {
  getErrorMessageByKey, Input,
  Modal,
  Select,
  useList,
  useNotification,
  useObject,
} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";

export const ModalAddEditOrderProduction = (props) => {
  const { notification } = useNotification();

  const inputValue = useRef(null);

  const {
    object: [nomenclature],
  } = useObject({
    apiUrl: "api/nomenclature/" + props.object.composition.nomenclature.uuid,
    rootUrl: ROOT_URL,
  });

  const {
    object: [productionOrder],
  } = useObject({
    apiUrl: "api/production-order/" + props.object.order.uuid,
    rootUrl: ROOT_URL,
  });

  const {
    object: [acceptance],
  } = useObject({
    apiUrl: "api/production-order-item/" + props.object.item.uuid,
    rootUrl: ROOT_URL,
  });

  const numberConvertV = (numberString) => {
    return !!numberString && numberString.length > 0 && numberString !== "null"
      ? Number(String(Number(numberString).toFixed(3)))
      : null;
  };

  const changeAdd = (event) => {
    event.preventDefault();

    const value = inputValue.current.value;

    const jsonData = JSON.stringify({
      value: numberConvertV(value),
      nomenclature: {
        uuid: nomenclature.uuid,
      },
      measurement_unit_type: !!props.object.composition.measurement_unit_type ? {
        tag: props.object.composition.measurement_unit_type.tag,
      } : null,
    });

    axios
      .post("/api/production-order-item/"+props.object.item.uuid+"/count/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Размер сохранён", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };


  return (
    !!productionOrder && !!acceptance && !!nomenclature && (
      <>
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div
            className="Form__title"
            style={{ fontSize: 19, fontWeight: 700 }}
          >
            №{productionOrder.id}{!!productionOrder.date && ' от ' + moment(productionOrder.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}
            <br/>
            {!!acceptance.nomenclature && acceptance.nomenclature.name} - {nomenclature.name}
            <div className="Text__signature">Указать размер</div>
          </div>

          <div className="Form__field">
            <Input reference={inputValue} type={'number'} label={"Размер (" +
              (!!props.object.composition.measurement_unit_type ? props.object.composition.measurement_unit_type.designation : "-") + ")"}
                   value={!!props.object.count && !!props.object.count.value && !!props.object.composition.measurement_unit_type
                     ? (props.object.count.value / props.object.composition.measurement_unit_type.ratio)
                     : !!props.object.count && !!props.object.count.value && props.object.count.value}
            />
          </div>


          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Сохранить
            </button>
          </div>
        </form>
      </Modal>
</>
    )
  );
};
