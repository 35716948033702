import React, {Fragment, useState} from "react";
import {
  ButtonIcon,
  getAccountFio,
  Icon,
  isProfileRoleTypeTags,
  Loading,
  Pagination,
  Search,
  Select,
  TextSearchMarker,
  Tooltip,
} from "@vokymlak/kabinet-ui";
import ModalTicketAdd from "./ModalTicketAdd";
import ModalTicketEdit from "./ModalTicketEdit";
import {ROLE_ADMINISTRATOR, TICKET_TYPE_ERROR, TICKET_TYPE_IDEA,} from "../../../../config/constants";
import moment from "moment";
import ModalTicketAdminEdit from "./ModalTicketAdminEdit";
import {ScrollBlock} from "../../../../components/ScrollBlock/ScrollBlock";
import ModalTicketFileAdd from "./ModalTicketFileAdd";
import styleContent from "../../../../styles/modules/Block.module.scss";
import styleCard from "../../../../styles/modules/Card.module.scss";
import ModalTicketFileEdit from "./ModalTicketFileEdit";
import ModalTicketChecking from "./ModalTicketChecking";
import ModalTicketAtWork from "./ModalTicketAtWork";
import ModalTicketLaborCost from "./ModalTicketLaborCost";
import TicketLaborCostView from "./TicketLaborCostView";
import TicketInfoView from "./TicketInfoView";

function TicketsPage(props) {
  const {
    profile,

    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    // isArchive,
    // setIsArchive,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    changeViewer,

    isArchive,
    isTopicNull,
    ticketTopicUuid,
    authorId,
    executorId,

    types,
    statuses,
    employees,
    topics,
  } = props;

  const [isModalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [modalItemEdit, setModalItemEdit] = useState(null);
  const [modalItemAdminEdit, setModalItemAdminEdit] = useState(null);
  const [modalTicketFileAdd, setModalTicketFileAdd] = useState(null);
  const [modalTicketFileEdit, setModalTicketFileEdit] = useState(null);
  const [modalTicketAtWork, setModalTicketAtWork] = useState(null);
  const [modalTicketChecking, setModalTicketChecking] = useState(null);
  const [modalTicketLaborCost, setModalTicketLaborCost] = useState(null);

  function getTopicTitleByUuid(topics, ticketTopicUuid) {
    let title = null;
    if (!!topics) {
      topics.forEach(t => {
        if (t.uuid === ticketTopicUuid) {
          title = t.title
        }
      })
    }
    return title;
  }

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
              }}
            >
              <div>
                {!authorId && !executorId && !isArchive && !isTopicNull && !ticketTopicUuid && "Все тикеты"}

                {!!authorId && "Мои тикеты"}
                {!!executorId && "Я исполнитель"}
                {isArchive && "В архиве"}
                {isTopicNull && "Без темы"}
                {!!ticketTopicUuid && getTopicTitleByUuid(topics, ticketTopicUuid)}

                {!!list && (
                  <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                    ({count})
                  </span>
                )}
              </div>
              <span
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                Задачи по улучшениям и ошибкам
              </span>
            </div>
            {/*<ButtonIcon*/}
            {/*  tooltipText={"Архив"}*/}
            {/*  iconName={"custom-archive"}*/}
            {/*  iconColor={isArchive ? "#202020" : "#20202075"}*/}
            {/*  size={24}*/}
            {/*  style={{*/}
            {/*    marginLeft: 16,*/}
            {/*  }}*/}
            {/*  onClick={() => setIsArchive(!isArchive)}*/}
            {/*/>*/}
            <span style={{width: 140, marginLeft: 16}}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{marginLeft: 8}}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 8}}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 100,
                      title: 100,
                    },
                    {
                      value: 1000,
                      title: 1000,
                    },
                  ]}
                  label="Лимит"
                  onChangeSelect={(limit) => setPageLimit(Number(limit))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{marginLeft: 8}}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{marginLeft: 12}}
              onClick={() => setIsModalObjectAdd(true)}
            />
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {!!list &&
                list.map((item) => (
                  <Fragment key={"item_" + item.uuid}>
                    <li
                      className={
                        isUnroll(item.uuid) ? "List__item active" : "List__item"
                      }
                      onClick={() => changeUnroll(item.uuid)}
                      style={{
                        padding: "10px 12px",
                        backgroundColor: !!item.ticket_status && item.ticket_status.tag === "AT_WORK"
                          ? "#0061ff25"
                          : item.is_priority && "#ffe082",
                        position: "relative",
                        marginBottom: !isUnroll(item.uuid) && 16
                      }}
                    >
                      <div
                        className="Item__title"
                        style={{
                          width: "100%",
                        }}
                      >
                        <div
                          className="Element__title"
                          style={{
                            width: "100%",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              fontSize: 19,
                              fontWeight: 700,
                              width: "100%",
                            }}
                          >
                            {(!!item.id && (
                                <span style={{
                                  fontSize: 24,
                                  fontWeight: 900,
                                  opacity: .5,
                                  marginRight: 12
                                }}>#{item.id}</span>
                              )) ||
                              "-"}

                            {(!!item.title && (
                                <TextSearchMarker
                                  text={item.title}
                                  search={searchQuery}
                                />
                              )) ||
                              "-"}

                            {!!item.files && (
                              <Tooltip text={'Прикрепления'} style={{height: 14, marginLeft: 8}}>
                                <Icon
                                  name={"custom-attach-file"}
                                  color={"#202020"}
                                  size={14}
                                />
                              </Tooltip>
                            )}
                          </div>

                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexDirection: "row",
                              flexWrap: "nowrap",
                              marginTop: 2,
                            }}
                          >
                            {!!item.description && (
                              <div
                                style={{
                                  fontSize: 13,
                                  fontWeight: 500,
                                }}
                              >
                                <TextSearchMarker
                                  text={item.description}
                                  search={searchQuery}
                                />
                              </div>
                            )}
                          </div>

                          <TicketInfoView object={item}/>

                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexDirection: "row",
                              flexWrap: "nowrap",
                              opacity: 0.75,
                              marginTop: 4,
                            }}
                          >
                            <div
                              style={{
                                fontSize: 13,
                                fontWeight: 500,
                              }}
                            >
                              {!!item.updated_at
                                ? moment(item.updated_at).format(
                                  "DD.MM.YYYY HH:mm"
                                )
                                : moment(item.created_at).format(
                                  "DD.MM.YYYY"
                                )}
                            </div>

                            {!!item.author && (
                              <div
                                style={{
                                  fontSize: 13,
                                  fontWeight: 500,
                                }}
                              >
                                {!!item.author && getAccountFio(item.author)}
                              </div>
                            )}
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              position: "absolute",
                              top: -8,
                              left: 0,
                              justifyContent: " flex-end",
                              width: "100%",
                              padding: "0 8px",
                            }}
                          >
                            {!!item.date_end && (
                              <div
                                style={{
                                  fontSize: 11,
                                  fontWeight: 700,
                                  backgroundColor: "#fff",
                                  display: "inline-block",
                                  color: "#202020",
                                  padding: "2px 4px 2px",
                                  borderRadius: 3,
                                  margin: "0 2px",
                                }}
                              >
                                {moment(item.date_end).format("DD.MM.YYYY")}
                              </div>
                            )}

                            {!!item.ticket_status && (
                              <div
                                style={{
                                  fontSize: 11,
                                  fontWeight: 700,
                                  backgroundColor: "#202020",
                                  display: "inline-block",
                                  color: "white",
                                  padding: "2px 4px 2px",
                                  borderRadius: 3,
                                  margin: "0 2px",
                                }}
                              >
                                {item.ticket_status.name}
                              </div>
                            )}

                            {!!item.ticket_type &&
                              item.ticket_type.tag === TICKET_TYPE_ERROR && (
                                <div
                                  style={{
                                    fontSize: 11,
                                    fontWeight: 700,
                                    backgroundColor: "#f44336",
                                    display: "inline-block",
                                    color: "white",
                                    padding: "2px 4px 2px",
                                    borderRadius: 3,
                                    margin: "0 2px",
                                  }}
                                >
                                  {item.ticket_type.name}
                                </div>
                              )}
                            {!!item.ticket_type &&
                              item.ticket_type.tag === TICKET_TYPE_IDEA && (
                                <div
                                  style={{
                                    fontSize: 11,
                                    fontWeight: 700,
                                    backgroundColor: "#368ff4",
                                    display: "inline-block",
                                    color: "white",
                                    padding: "2px 4px 2px",
                                    borderRadius: 3,
                                    margin: "0 2px",
                                  }}
                                >
                                  {item.ticket_type.name}
                                </div>
                              )}
                          </div>

                          <div
                            style={{
                              height: 4,
                              width: "100%",
                              backgroundColor: "#2020200f",
                              borderRadius: 2,
                              marginTop: 4,
                            }}
                          >
                            <div
                              style={{
                                height: 4,
                                width: item.percentage_completion + "%",
                                backgroundColor:
                                  item.percentage_completion < 100
                                    ? "#202020"
                                    : "#4caf50",
                                borderRadius: 2,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="Item__settings">
                        <div className="Element__datetime">
                        </div>
                      </div>
                    </li>

                    {isUnroll(item.uuid) && (
                      <li className="List__content">
                        <div className="Content__header">
                          <div className="Header__title">
                            {isProfileRoleTypeTags(profile, [
                              ROLE_ADMINISTRATOR,
                            ]) && (
                              <div
                                className="Button__text Button__text_right"
                                onClick={() => setModalItemAdminEdit(item)}
                              >
                                Администрирование
                              </div>
                            )}

                            {!item.archived_at && (
                              <div
                                className="Button__text Button__text_right"
                                onClick={() => setModalTicketFileAdd(item)}
                              >
                                Прикрепить файлы
                              </div>
                            )}
                          </div>
                          <div className="Header__settings">
                            {!(!!item.archived_at || (
                              !!item.ticket_status && (item.ticket_status.tag === "COMPLETED" ||
                                item.ticket_status.tag === "REJECTED")
                            )) && (
                              <div
                                className="Button__text Button__text_left"
                                onClick={() => setModalTicketLaborCost(item)}
                              >
                                Отметить затраты
                              </div>
                            )}

                            {(!!item.executor_id && profile.id === item.executor_id &&
                              !!item.ticket_status && item.ticket_status.tag === "PLANNED") && (
                              <div
                                className="Button__text Button__text_left"
                                onClick={() => setModalTicketAtWork(item)}
                              >
                                В работу
                              </div>
                            )}

                            {(!!item.executor_id && profile.id === item.executor_id &&
                              !!item.ticket_status && item.ticket_status.tag === "AT_WORK") && (
                              <div
                                className="Button__text Button__text_left"
                                onClick={() => setModalTicketChecking(item)}
                              >
                                На проверку
                              </div>
                            )}

                            {!item.archived_at && !item.ticket_status && (
                              <ButtonIcon
                                tooltipText={"Редактировать"}
                                iconName={"custom-edit"}
                                iconColor={"#202020"}
                                size={36}
                                style={{marginLeft: 12}}
                                onClick={() => setModalItemEdit(item)}
                              />
                            )}
                          </div>
                        </div>

                        {!!item.files && (
                          <div className={styleContent._block}>
                            <div className={styleContent._block__content}>
                              {item.files.map((file) => (
                                <div
                                  className={styleCard._block + " " + styleCard._block_hover}
                                  key={item.uuid + ":" + file.uuid}
                                  onClick={() => setModalTicketFileEdit(file)}
                                  onContextMenu={(!!file.mime_type && (file.mime_type === "image/jpeg" || file.mime_type === "image/png"))
                                    ? (event) => (event.preventDefault(), changeViewer(file.data, file.mime_type))
                                    : (event) => event.preventDefault()
                                  }>
                                  {!!file.data && !!file.mime_type && (file.mime_type === "image/jpeg" || file.mime_type === "image/png") && (
                                    <>
                                      <img src={`data:${file.mime_type};base64, ${file.data}`} height={24} style={{
                                        borderRadius: 3
                                      }
                                      }/>
                                    </>
                                  )}
                                  {!(!!file.data && !!file.mime_type && (file.mime_type === "image/jpeg" || file.mime_type === "image/png")) && (
                                    <div
                                      style={{
                                        height: 26,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "center",
                                        textTransform: "uppercase",
                                        fontSize: 24,
                                        fontWeight: 700
                                      }}>
                                      {file.expansion.slice(1)}
                                    </div>
                                  )}
                                  <div className={styleCard._block__title} style={{
                                    fontSize: 15
                                  }}>
                                    {file.name}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}

                        <TicketLaborCostView object={item}/>
                      </li>
                    )}
                  </Fragment>
                ))}
            </ul>
          </div>
        )}

        {!!modalItemEdit && (
          <ModalTicketEdit
            object={modalItemEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemEdit(null)}
          />
        )}
        {!!modalItemAdminEdit && (
          <ModalTicketAdminEdit
            object={modalItemAdminEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemAdminEdit(null)}
          />
        )}
        {!!isModalObjectAdd && (
          <ModalTicketAdd
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalObjectAdd(false)}
          />
        )}

        {!!modalTicketFileAdd && (
          <ModalTicketFileAdd
            object={modalTicketFileAdd}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalTicketFileAdd(null)}
          />
        )}
        {!!modalTicketFileEdit && (
          <ModalTicketFileEdit
            ticketFile={modalTicketFileEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalTicketFileEdit(null)}
          />
        )}

        {!!modalTicketAtWork && (
          <ModalTicketAtWork
            object={modalTicketAtWork}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalTicketAtWork(null)}
          />
        )}

        {!!modalTicketChecking && (
          <ModalTicketChecking
            object={modalTicketChecking}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalTicketChecking(null)}
          />
        )}

        {!!modalTicketLaborCost && (
          <ModalTicketLaborCost
            object={modalTicketLaborCost}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalTicketLaborCost(null)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default TicketsPage;
