// Роль администратора.
export const ROLE_ADMINISTRATOR = 'ADMINISTRATOR'
// Роль руководитель.
export const ROLE_DIRECTOR = 'DIRECTOR'
// Роль клиент.
export const ROLE_CLIENT = 'CLIENT'
// Роль менеджер.
export const ROLE_MANAGER = 'MANAGER'

// Код ошибки таймаута запроса.
export const ERROR_CODE_ECONNABORTED = 'ECONNABORTED'
// Код ошибки отмены запроса.
export const ERR_CANCELED = 'ERR_CANCELED'

// Код ошибки 400.
export const ERR_BAD_REQUEST = 'ERR_BAD_REQUEST'

// Продукт.
export const PRODUCT = 'PRODUCT'
// Материал.
export const MATERIAL = 'MATERIAL'
// Ресурс.
export const RESOURCE = 'RESOURCE'

// Количество.
export const AMOUNT = 'AMOUNT'
// Масса.
export const WEIGHT = 'WEIGHT'
// Объем.
export const VOLUME = 'VOLUME'
// Пропорция.
export const PROPORTION = 'PROPORTION'
// Процент.
export const PRECENT = 'PRECENT'

// Поступление.
export const INCOMING = 'INCOMING'
// Изготовление.
export const MAKING = 'MAKING'
// Расход.
export const EXPENSE = 'EXPENSE'
// Списание.
export const CANCELLATION = 'CANCELLATION'
// Ревизия.
export const REVISION = 'REVISION'

// Тикет идея.
export const TICKET_TYPE_IDEA = 'IDEA'
// Тикет ошибка.
export const TICKET_TYPE_ERROR = 'ERROR'

// Тикет запланировано.
export const TICKET_STATUS_PLANNED = 'PLANNED'
// Тикет завершено.
export const TICKET_STATUS_COMPLETED = 'COMPLETED'
// Тикет отклонено.
export const TICKET_STATUS_REJECTED = 'REJECTED'
