import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  ROLE_MANAGER,
} from "../../../config/constants";
import {
  getErrorMessageByKey,
  isValidateNullText,
  jsonFormatText,
  Modal,
  Input,
  useNotification,
  Select,
  useObject,
  getAccountFio,
} from "@vokymlak/kabinet-ui";
import { Avatar } from "../../../components/Avatar/Avatar";

export const ModalEditEmployee = (props) => {
  const [isPassword, setIsPassword] = useState(false);

  const inputLastName = useRef(null);
  const inputFirstName = useRef(null);
  const inputPatronymic = useRef(null);
  const inputLogin = useRef(null);
  const inputNewPassword = useRef(null);
  const inputEmail = useRef(null);
  const inputRoleTypeTag = useRef(null);

  const { notification } = useNotification();

  const {
    object: [account],
    setApiUrl,
  } = useObject({
    apiUrl: "/api/employee/" + props.account.uuid,
    rootUrl: ROOT_URL,
  });

  useEffect(() => {
    if (props.account) {
      setApiUrl("/api/employee/" + props.account.uuid);
    }
  }, [props.account]);

  const changeEdit = (event) => {
    event.preventDefault();

    const lastName = jsonFormatText(inputLastName.current.value);
    const firstName = jsonFormatText(inputFirstName.current.value);
    const patronymic = jsonFormatText(inputPatronymic.current.value);
    const login = jsonFormatText(inputLogin.current.value);
    const email = jsonFormatText(inputEmail.current.value);
    const roleTypeTag = inputRoleTypeTag.current.value;

    if (
      isValidateNullText(lastName) ||
      isValidateNullText(firstName) ||
      isValidateNullText(login)
    ) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      last_name: lastName,
      first_name: firstName,
      patronymic: patronymic,
      login: login,
      email: email,
      role_type_tag: roleTypeTag,
    });

    axios
      .put("/api/employee/" + props.account.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changePassword = (event) => {
    event.preventDefault();

    const newPassword = inputNewPassword.current.value;

    if (!newPassword || newPassword.length === 0) {
      return;
    }

    const jsonData = JSON.stringify({
      password: newPassword,
    });

    axios
      .put("/api/employee/" + props.account.uuid + "/edit/password", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsPassword(false);
        notification("Пароль изменён", "green");
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!account && (
      <Modal handleClose={() => props.handleClose()}>
        {!isPassword && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div
              style={{
                borderRadius: 7,
                backgroundColor: "#f3f3f3",
                height: 96,
                width: 96,
                marginBottom: 8,
              }}
            >
              <Avatar size={96} value={getAccountFio(account)} />
            </div>
            <div
              className="Form__title"
              style={{ fontSize: 19, fontWeight: 700 }}
            >
              {getAccountFio(account)}
              <div
                style={{
                  fontSize: 12,
                  opacity: 0.5,
                }}
              >
                {"@" + account.login}
              </div>
              <div className="Text__signature">Сотрудник</div>
            </div>

            <div className="Form__field">
              <Input
                reference={inputLastName}
                label="Фамилия *"
                value={account.last_name}
              />
            </div>

            <div className="Form__field">
              <Input
                reference={inputFirstName}
                label="Имя *"
                value={account.first_name}
              />
            </div>

            <div className="Form__field">
              <Input
                reference={inputPatronymic}
                label="Отчество"
                value={account.patronymic}
              />
            </div>

            <div className="Form__field">
              <Input
                reference={inputLogin}
                label="Логин"
                value={account.login}
              />
            </div>

            <div className="Form__field">
              <Input
                reference={inputEmail}
                label="Электронная почта"
                value={account.email}
              />
            </div>

            <div className="Form__field Form__field_last">
              <Select
                isFixed={true}
                reference={inputRoleTypeTag}
                options={[
                  {
                    value: ROLE_MANAGER,
                    title: "Менеджер",
                  },
                ]}
                label="Роль"
                value={
                  !!account.account_roles && account.account_roles.length > 0
                    ? account.account_roles[0].role_type.tag
                    : null
                }
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            <div className="Form__actions">
              <div
                className="Action__link Action__link_blue"
                onClick={() => setIsPassword(true)}
              >
                Смена пароля
              </div>
              <div className="Action__link" onClick={() => props.handleClose()}>
                Отмена
              </div>
            </div>
          </form>
        )}
        {!!isPassword && (
          <form className="Form" onSubmit={(event) => changePassword(event)}>
            <div
              className="Form__title"
              style={{ fontSize: 19, fontWeight: 700 }}
            >
              {getAccountFio(account)}
              <div className="Text__signature">Смена пароля</div>
            </div>

            <div className="Form__field Form__field_last">
              <Input reference={inputNewPassword} label="Новый пароль" />
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div
                className="Action__link"
                onClick={() => setIsPassword(false)}
              >
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
};
