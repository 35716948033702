import React, { useEffect, useState, useContext } from "react";
import "./MenuHorizontal.scss";
import { useNavigate } from "react-router-dom";
import {
  getAccountFio,
  AuthContext,
  authLogout,
  ProfileContext, useProfile, useAction, profileInit, ButtonIcon
} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";
import {Avatar} from "../../../components/Avatar/Avatar";
import ModalProfile from "../../Profile/ModalProfile";
import ModalLogout from "../ModalLogout";
import MenuRouting from "../MenuRouting";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";

function MenuHorizontal() {
  const { authDispatch } = useContext(AuthContext);
  const { profileDispatch } = useContext(ProfileContext);

  const [isModalProfile, setIsModalProfile] = useState(false);
  const [isModalLogout, setIsModalLogout] = useState(false);
  const [isNavigate, setIsNavigate] = useState(false);
  const { profile } = useProfile();
  const { setAction } = useAction(!!profile ? profile.uuid : null, () => {
    void profileInit(profileDispatch, ROOT_URL, null).then();
  });
  useEffect(() => {
    setAction(!!profile ? profile.uuid : null);
  }, [profile]);
  const navigate = useNavigate();
  const handleLogout = () => {
    authLogout(authDispatch, () => {
      navigate("/");
    });
  };

  function isMD5Hash(str) {
    // Проверяем, соответствует ли строка формату MD5 хэша.
    const md5Regex = /^[a-f0-9]{32}$/i;
    return md5Regex.test(str);
  }


  return (
    <div className="MenuHorizontal">
      <ul className="Menu__list">
        <li className="List__link">
          <ButtonIcon
            onClick={() => setIsNavigate(!isNavigate)}
            iconName={isNavigate ? "custom-close" : "custom-menu"}
            iconColor={"#20202090"}
            size={30}
          />
        </li>
      </ul>

      <ul className="Menu__list">
        {profile && (
          <li
            className="List__link List__link_header"
            onClick={() => setIsModalProfile(true)}
          >
            <div className="Link__text">
              <div className="Text__signature">
                {"@" + profile.login}
              </div>
              <div
                className="Text__title"
              >
                {getAccountFio(profile)}
              </div>
            </div>
            <div className="Link__text">
              <div
                style={{
                  borderRadius: 5,
                  backgroundColor: "#fff",
                  boxShadow: "0 8px 12px rgba(0, 0, 0, 0.03)",
                  height: 36,
                  width: 36,
                  marginLeft: 8
                }}
              >
                {!!profile.avatar && isMD5Hash(profile.avatar) && (
                  <img height={36} width={36} style={{
                    borderRadius: 7,
                  }}  src={ROOT_URL + "/api/account/avatar/" + profile.avatar}/>
                )}
                {!!profile.avatar && !isMD5Hash(profile.avatar) && (
                  <img height={36} width={36} style={{
                    borderRadius: 7,
                  }}  src={"data:image/jpeg;base64, "+ profile.avatar}/>
                )}
                {!profile.avatar && (
                  <Avatar size={36} value={getAccountFio(profile)} />
                )}
              </div>
            </div>
          </li>
        )}
        <li className="List__hr"></li>
        <li className="List__link">
          <ButtonIcon
            onClick={() => setIsModalLogout(true)}
            iconName={"custom-exit"}
            iconColor={"#20202090"}
            size={36}
          />
        </li>
      </ul>

      {!!isModalProfile && (
        <ModalProfile handleClose={() => setIsModalProfile(false)} />
      )}

      {!!isModalLogout && (
        <ModalLogout
          successfulRequest={() => (setIsModalLogout(false), handleLogout())}
          handleClose={() => setIsModalLogout(false)}
        />
      )}

      {isNavigate && (
        <div  style={{
          position: "fixed",
          top: 58,
          bottom: 0,
          left: 0,
          right: 0,
          borderTop: "1px solid #20202025",
          backgroundColor: "#f1f1f1",
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          justifyContent: "flex-start",
          alignItems: "stretch",
          height: "100%",
          overflow: "hidden",
        }}>
          <ScrollBlock
            style={{
              width: "100%",
              height: "calc(100% - 58px)",
            }}
          >
            <div className="Menu__navigate">
              <div>
                <MenuRouting onChange={() => setIsNavigate(false)} />
              </div>
            </div>
          </ScrollBlock>
        </div>
      )}
    </div>
  );
}
export default MenuHorizontal;
