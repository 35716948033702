import React, { useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
  useObject,
  Input,
  useList, Select, Textarea, jsonFormatText, Search, TextSearchMarker,
} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";

function ModalSpecificationItemSelect(props) {
  const {
    list: [list],
    search: [searchQuery, setSearchQuery],
  } = useList({ apiUrl: "/api/nomenclatures/list", rootUrl: ROOT_URL,
    isSearch: true,});

  const changeAdd = (item) => {
        props.successfulRequest(item);
        props.handleClose();
  };

  const inputSearchText = useRef(null);
  function onChangeSearchText() {
    setSearchQuery(inputSearchText.current.value);
  }

  return (
    !!list && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div className="Form__title">
            Выбор номенклатуры
          </div>

          <div className="Form__field">
            <Search
              reference={inputSearchText}
              type="text"
              label={"Поиск"}
              isFixed={true}
              onChange={() => onChangeSearchText()}
            />
          </div>

          <div className="Form__field Form__field_last"
          style={{
            maxHeight: 250,
            overflowY: "auto"
          }}>
            {list.map((item, index) => (
              <div key={"item_" + index} onClick={() => changeAdd(item)} style={{
                padding: "4px 8px",
                border: "1px solid #dbdbdb",
                cursor: "pointer",
                marginBottom: 6,
                borderRadius: 5,
              }}>
                <TextSearchMarker
                  text={item.id + ". " + item.name + (!!item.article ? " - " + item.article : "") + (!!item.code ? " - (" + item.code + ")" : "")}
                  search={searchQuery}
                />
              </div>
            ))}
          </div>

          {!!props.item && (
            <div className="Form__actions Form__actions_center">
              <div
                className="Action__link Action__link_red"
                onClick={() => (props.successfulRequest(null), props.handleClose())}
              >
                Удалить
              </div>
            </div>
          )}

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalSpecificationItemSelect;
