import React, {Fragment} from "react";
import {ROLE_PACKER, ROLE_PICKER, ROLE_STICKER} from "../../../config/constants";
import {
  ButtonIcon,
  Loading,
  Pagination,
  Search,
  Select,
  isProfileRoleTypeTags,
} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {Nomenclature} from "../NomenclatureItem/Nomenclature";
import ModalSpecificationsImport from "../NomenclatureItem/ModalSpecificationsImport";
import ModalNomenclatureInCategory from "../NomenclatureItem/ModalNomenclatureInCategory";
import ModalNomenclatureEdit from "../NomenclaturePage/ModalNomenclatureEdit";
import ModalNomenclaturesInCategory from "../NomenclatureItem/ModalNomenclaturesInCategory";
import ModalNomenclaturesIsPublish from "../NomenclatureItem/ModalNomenclaturesIsPublish";

export const NomenclaturesNoCategoryPage = (props) => {
    const {
      list,
      setList,
      count,

      pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,

    setIsUpdateList,
    isUpdateList,


    profile,

      modalObjectAdd,
      setModalObjectAdd,

      modalNomenclatureInCategory,
      setModalNomenclatureInCategory,

      modalEdit, setModalEdit,

      isSelect,
      selectNomenclatures, setSelectNomenclatures,
      isSelectNomenclature, changeSelectNomenclature,

      isModalNomenclaturesInCategory,
      setIsModalNomenclaturesInCategory,
      isModalNomenclaturesIsPublish,
      setIsModalNomenclaturesIsPublish,
  } = props;

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
    <div className="Page">
      <div className="Page__title">
        <div
          className="Title__text"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 21,
            fontWeight: 700,
          }}
        >
          Без категории
          {!!list && (
            <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                ({count})
              </span>
          )}
          <span style={{ width: 140, marginLeft: 16 }}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>

          {!!selectNomenclatures && selectNomenclatures.length > 0 && (
            <div
              style={{
                backgroundColor: "#0061ff",
                padding: "2px 3px 4px 8px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 24,
                marginLeft: 12,
              }}
            >
              <span
                style={{
                  color: "white",
                  fontSize: 14,
                  pointerEvents: "auto",
                  fontWeight: 700,
                  cursor: "default",
                }}
              >
                {selectNomenclatures.length}
              </span>
              <ButtonIcon
                style={{
                  marginLeft: 4,
                  width: 18,
                  height: 18
                }}
                tooltipText={"Отменить"}
                iconName={"custom-circle-close"}
                iconColor={"white"}
                size={18}
                onClick={() => setSelectNomenclatures([])}
              />
            </div>
          )}
        </div>

        <div className="Title__settings">
          {!!list && (
            <ButtonIcon
              tooltipText={
                list.length === unrolls.length ? "Свернуть" : "Развернуть"
              }
              iconName={
                list.length === unrolls.length
                  ? "custom-unfold-less"
                  : "custom-unfold-more"
              }
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 8 }}
              onClick={() => changeAllUnroll(list, "uuid")}
            />
          )}

          <ButtonIcon
            tooltipText={"Обновить"}
            iconName={"custom-change-circle"}
            iconColor={"#20202075"}
            size={28}
            style={{ marginLeft: 8 }}
            onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
          />

          {!!count && !!pageLimit && (
            <div
              style={{
                marginLeft: 12,
                width: 64,
              }}
            >
              <Select
                isFixed={true}
                isDefaultOption={false}
                value={pageLimit}
                options={[
                  {
                    value: 100,
                    title: 100,
                  },
                  {
                    value: 1000,
                    title: 1000,
                  },
                ]}
                label="Лимит"
                onChange={(event) => setPageLimit(Number(event.target.value))}
              />
            </div>
          )}

          {!!count && count > pageLimit && (
            <div style={{ marginLeft: 8 }}>
              <Pagination
                totalRecords={count}
                pageLimit={pageLimit}
                currentPage={pageNumber}
                onPageChanged={(data) => changePage(data)}
              />
            </div>
          )}
        </div>
      </div>

      {!list && (
        <div className={"Page__loader"}>
          <Loading />
        </div>
      )}

      {!!list && (
        <div className="Page__content">
          <ul className="List">
            {list.map((item) => (
              <Fragment key={"item_" + item.uuid}>
                <Nomenclature {...props} item={item} isEdit={true} />
              </Fragment>
            ))}
          </ul>
        </div>
      )}


      {!!selectNomenclatures && selectNomenclatures.length > 0 && (
        <div
          style={{
            position: "fixed",
            bottom: 24,
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            alignItems: "center",
            justifyContent: "center",
            right: 48,
            zIndex: 9,
            pointerEvents: "none",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#202020",
                padding: "8px 12px",
                color: "white",
                marginLeft: 3,
                marginRight: 3,
                cursor: "pointer",
                borderRadius: 24,
                fontSize: 15,
                fontWeight: 700,
                pointerEvents: "auto"
              }}
              onClick={() => setIsModalNomenclaturesIsPublish(true)}
            >
              Публикация на сайт
            </div>
            <div
              style={{
                backgroundColor: "#0061ff",
                padding: "8px 12px",
                color: "white",
                marginLeft: 3,
                marginRight: 3,
                cursor: "pointer",
                borderRadius: 24,
                fontSize: 15,
                fontWeight: 700,
                pointerEvents: "auto"
              }}
              onClick={() => setIsModalNomenclaturesInCategory(true)}
            >
              Перенести в категорию
            </div>
          </div>
        </div>
      )}

      {!!isModalNomenclaturesIsPublish && (
        <ModalNomenclaturesIsPublish
          nomenclatures={selectNomenclatures}
          successfulRequest={() => (setSelectNomenclatures([]), setIsUpdateList(!isUpdateList))}
          handleClose={() => setIsModalNomenclaturesIsPublish(false)}
        />
      )}

      {!!isModalNomenclaturesInCategory && (
        <ModalNomenclaturesInCategory
          nomenclatures={selectNomenclatures}
          successfulRequest={() => (setSelectNomenclatures([]), setIsUpdateList(!isUpdateList))}
          handleClose={() => setIsModalNomenclaturesInCategory(false)}
        />
      )}

      {!!modalObjectAdd && (
        <ModalSpecificationsImport
          object={modalObjectAdd}
          successfulRequest={() =>
            setIsUpdateList(!isUpdateList)
          }
          handleClose={() => setModalObjectAdd(null)}
        />
      )}

      {!!modalNomenclatureInCategory && (
        <ModalNomenclatureInCategory
          object={modalNomenclatureInCategory}
          successfulRequest={() =>
            setIsUpdateList(!isUpdateList)
          }
          handleClose={() => setModalNomenclatureInCategory(null)}
        />
      )}


      {!!modalEdit && (
        <ModalNomenclatureEdit
          object={modalEdit}
          successfulRequest={() =>
            setIsUpdateList(!isUpdateList)
          }
          handleClose={() => setModalEdit(null)}
        />
      )}
    </div>
    </ScrollBlock>
  );
}
