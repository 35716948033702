import React, {useRef, useState} from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  useObject,
  useList,
  Select,
  Modal,
} from "@vokymlak/kabinet-ui";

function ModalNomenclaturesIsPublish(props) {
  const [isPublish, setIsPublish] = useState(false);

  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const jsonData = JSON.stringify({
      nomenclatures: props.nomenclatures,
      is_published: isPublish,
    });

    axios
      .put("/api/nomenclatures/edit/publish", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
      <Modal handleClose={() => props.handleClose()}>
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              Номенклатура
              <div className="Text__signature">Публикация на розницу</div>
            </div>

            <div className="Form__field Form__field_last">
              <div
                className="Button__swith"
                style={{
                  padding: "8px",
                  width: "100%",
                  border: isPublish ? "2px solid #ffe082" : "2px dashed #20202015",
                  backgroundColor: isPublish ? "#ffe082" : "#ffffff",
                  borderRadius: 6,
                  marginTop: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => setIsPublish(!isPublish)}
              >
                <div
                  className="Swith__text"
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Опубликовать на розницу
                </div>
                <div
                  className={
                    "Swith Swith_gray" +
                    ((isPublish && " Swith_active Swith_active_gray") || "")
                  }
                ></div>
              </div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>
          </form>
      </Modal>
  );
}

export default ModalNomenclaturesIsPublish;
