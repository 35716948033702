import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  Textarea,
  useNotification,
  Modal,
  useObject,
  jsonFormatText,
  isValidateNullText, Input,
} from "@vokymlak/kabinet-ui";
import moment from "moment";

function ModalSiteShopOrderEdit(props) {
  // const [isPickup, setIsPickup] = useState(false);

  const {
    object: [object],
  } = useObject({
    apiUrl: "api/site-shop-order/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackResponse: (data) => {
      // setIsPickup(!!data && data.is_pickup);
    },
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputDate = useRef(null);

  const inputBuyerName = useRef(null);
  const inputBuyerPhone = useRef(null);
  const inputBuyerEmail = useRef(null);

  // const inputByTime = useRef(null);
  //
  // const inputStreet = useRef(null);
  // const inputHouse = useRef(null);
  // const inputApartment = useRef(null);
  // const inputEntrance = useRef(null);
  // const inputFloor = useRef(null);
  //
  // const inputCommentOnOrder = useRef(null);

  const [isDelete, setIsDelete] = useState(false);

  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const date = jsonFormatText(inputDate.current.value);

    const buyerName = jsonFormatText(inputBuyerName.current.value);
    const buyerPhone = jsonFormatText(inputBuyerPhone.current.value);
    const buyerEmail = jsonFormatText(inputBuyerEmail.current.value);

    // const byTime = jsonFormatText(inputByTime.current.value);

    let street = null;
    let house = null;
    let apartment = null;
    let entrance = null;
    let floor = null;

    // if (!isPickup) {
    //   street = jsonFormatText(inputStreet.current.value);
    //   house = jsonFormatText(inputHouse.current.value);
    //   apartment = jsonFormatText(inputApartment.current.value);
    //   entrance = jsonFormatText(inputEntrance.current.value);
    //   floor = jsonFormatText(inputFloor.current.value);
    // }
    //
    // const commentOnOrder = jsonFormatText(inputCommentOnOrder.current.value);

    if (isValidateNullText(date)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      date: date,

      buyer_name: buyerName,
      buyer_phone: buyerPhone,
      buyer_email: buyerEmail,

      // by_time: byTime,
      //
      // is_pickup: isPickup,
      //
      // street: street,
      // house: house,
      // apartment: apartment,
      // entrance: entrance,
      // floor: floor,
      // comment_on_order: commentOnOrder,
    });

    axios
      .put("/api/site-shop-order/" + props.object.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("api/site-shop-order/" + props.object.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Заказ удалён", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
        {!isDelete && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div
              className="Form__title"
              style={{ fontSize: 19, fontWeight: 700 }}
            >
              №{object.id}{!!object.date && ' от ' + moment(object.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}
              <div className="Text__signature">Заказ по рознице</div>
            </div>

            {/*<div className="Form__field">*/}
            {/*  <Input reference={inputDate} type={'datetime-local'} isFixed={true} label="Дата заказа *"*/}
            {/*         value={*/}
            {/*           !!object.date*/}
            {/*             ? moment(object.date.substring(0, 19)).format(*/}
            {/*               "YYYY-MM-DD HH:mm"*/}
            {/*             )*/}
            {/*             : null*/}
            {/*         } />*/}
            {/*</div>*/}

            {/*<div className="Form__field">*/}
            {/*  <Input reference={inputBuyerName} label="Имя покупателя"*/}
            {/*         value={!!object.buyer_name && object.buyer_name} />*/}
            {/*</div>*/}

            {/*<div className="Form__field">*/}
            {/*  <Input type={'tel'} reference={inputBuyerPhone} label="Телефон покупателя (79991234567)"*/}
            {/*         value={!!object.buyer_phone && object.buyer_phone} />*/}
            {/*</div>*/}

            {/*<div className="Form__field">*/}
            {/*  <Input type={'email'} reference={inputBuyerEmail} label="Email покупателя"*/}
            {/*         value={!!object.buyer_email && object.buyer_email} />*/}
            {/*</div>*/}

            {/*<div className="Form__field">*/}
            {/*  <Input reference={inputByTime} type={'datetime-local'} isFixed={true} label="Ко времени"*/}
            {/*         value={*/}
            {/*    !!object.by_time*/}
            {/*      ? moment(object.by_time.substring(0, 19)).format(*/}
            {/*        "YYYY-MM-DD HH:mm"*/}
            {/*      )*/}
            {/*      : null*/}
            {/*  } />*/}
            {/*</div>*/}

            {/*<div className="Form__field">*/}
            {/*  <div*/}
            {/*    className="Button__swith"*/}
            {/*    style={{*/}
            {/*      padding: "8px",*/}
            {/*      width: "100%",*/}
            {/*      border: isPickup ? "2px solid #ffe082" : "2px dashed #20202015",*/}
            {/*      backgroundColor: isPickup ? "#ffe082" : "#ffffff",*/}
            {/*      borderRadius: 6,*/}
            {/*      marginTop: 20,*/}
            {/*      display: "flex",*/}
            {/*      alignItems: "center",*/}
            {/*      justifyContent: "space-between",*/}
            {/*      cursor: "pointer",*/}
            {/*    }}*/}
            {/*    onClick={() => setIsPickup(!isPickup)}*/}
            {/*  >*/}
            {/*    <div*/}
            {/*      className="Swith__text"*/}
            {/*      style={{*/}
            {/*        fontSize: 17,*/}
            {/*        fontWeight: 600,*/}
            {/*        display: "flex",*/}
            {/*        alignItems: "center",*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      Самовывоз*/}
            {/*    </div>*/}
            {/*    <div*/}
            {/*      className={*/}
            {/*        "Swith Swith_gray" +*/}
            {/*        ((isPickup && " Swith_active Swith_active_gray") || "")*/}
            {/*      }*/}
            {/*    ></div>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/*{!isPickup && (*/}
            {/*  <>*/}
            {/*    <div className="Form__field">*/}
            {/*      <Input reference={inputStreet} label="Улица"*/}
            {/*             value={!!object.street && object.street} />*/}
            {/*    </div>*/}

            {/*    <div className="Form__field">*/}
            {/*      <Input reference={inputHouse} label="Дом"*/}
            {/*             value={!!object.house && object.house} />*/}
            {/*    </div>*/}

            {/*    <div className="Form__field">*/}
            {/*      <Input reference={inputApartment} label="Квартира"*/}
            {/*             value={!!object.apartment && object.apartment} />*/}
            {/*    </div>*/}

            {/*    <div className="Form__field">*/}
            {/*      <Input reference={inputEntrance} label="Подъезд"*/}
            {/*             value={!!object.entrance && object.entrance} />*/}
            {/*    </div>*/}

            {/*    <div className="Form__field">*/}
            {/*      <Input reference={inputFloor} label="Этаж"*/}
            {/*             value={!!object.floor && object.floor} />*/}
            {/*    </div>*/}
            {/*  </>*/}
            {/*)}*/}

            {/*<div className="Form__field Form__field_last">*/}
            {/*  <Textarea reference={inputCommentOnOrder} label="Комментарий к заказу"*/}
            {/*            value={!!object.comment_on_order && object.comment_on_order} />*/}
            {/*</div>*/}

            {/*<div className="Form__actions Form__actions_center">*/}
            {/*  <button className="Action__button Action__button_all Action__button_blue">*/}
            {/*    Применить*/}
            {/*  </button>*/}
            {/*</div>*/}

            <div className="Form__actions Form__actions_center">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>
              {/*<div className="Action__link" onClick={() => props.handleClose()}>*/}
              {/*  Отмена*/}
              {/*</div>*/}
            </div>
          </form>
        )}
        {isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div
              className="Form__title"
              style={{ fontSize: 19, fontWeight: 700 }}
            >
              №{object.id}{!!object.date && ' от ' + moment(object.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Удалить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsDelete(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalSiteShopOrderEdit;
