import React, {useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {
  getErrorMessageByKey,
  Input,
  jsonFormatText,
  Modal,
  Textarea,
  useNotification,
  useObject,
} from "@vokymlak/kabinet-ui";

function ModalContractorEdit(props) {
  const inputShortName = useRef(null);
  const inputName = useRef(null);
  const inputOgrn = useRef(null);
  const inputInn = useRef(null);
  const inputKpp = useRef(null);
  const inputBank = useRef(null);
  const inputInvoiceNumber = useRef(null);

  const {
    object: [object],
  } = useObject({
    apiUrl: "api/contractor/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const [isDelete, setIsDelete] = useState(false);

  const {notification} = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();


    const shortName = jsonFormatText(inputShortName.current.value);
    const name = jsonFormatText(inputName.current.value);
    const ogrn = jsonFormatText(inputOgrn.current.value);
    const inn = jsonFormatText(inputInn.current.value);
    const kpp = jsonFormatText(inputKpp.current.value);
    const bank = jsonFormatText(inputBank.current.value);
    const invoiceNumber = jsonFormatText(inputInvoiceNumber.current.value);

    const jsonData = JSON.stringify({
      short_name: shortName,
      name: name,
      ogrn: ogrn,
      inn: inn,
      kpp: kpp,
      bank: bank,
      invoice_number: invoiceNumber,
    });

    axios
      .put("/api/contractor/" + props.object.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("api/contractor/" + props.object.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Контрагент удалён", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <>
        <Modal handleClose={() => props.handleClose()}>
          {!isDelete && (
            <form className="Form" onSubmit={(event) => changeEdit(event)}>
              <div
                className="Form__title"
                style={{fontSize: 19, fontWeight: 700}}
              >
                {(!!object.short_name && object.short_name) || "-"}
                <div className="Text__signature">Контрагент</div>
              </div>

              <div className="Form__field">
                <Textarea reference={inputShortName} label="Краткое наименование"
                          value={!!object.short_name && object.short_name}/>
              </div>

              <div className="Form__field">
                <Textarea reference={inputName} label="Полное наименование"
                          value={!!object.name && object.name}/>
              </div>

              <div className="Form__field">
                <Input reference={inputOgrn} label="ОГРН"
                       value={!!object.ogrn && object.ogrn}/>
              </div>

              <div className="Form__field">
                <Input reference={inputInn} label="ИНН"
                       value={!!object.inn && object.inn}/>
              </div>

              <div className="Form__field">
                <Input reference={inputKpp} label="КПП"
                       value={!!object.kpp && object.kpp}/>
              </div>

              <div className="Form__field">
                <Textarea reference={inputBank} label="Банк"
                          value={!!object.bank && object.bank}/>
              </div>

              <div className="Form__field Form__field_last">
                <Input reference={inputInvoiceNumber} label="Номер счёта"
                       value={!!object.invoice_number && object.invoice_number}/>
              </div>

              <div className="Form__actions Form__actions_center">
                <button className="Action__button Action__button_all Action__button_blue">
                  Применить
                </button>
              </div>

              <div className="Form__actions Form__actions_center">
                <div
                  className="Action__link Action__link_red"
                  onClick={() => setIsDelete(true)}
                >
                  Удалить
                </div>
              </div>
            </form>
          )}
          {isDelete && (
            <form className="Form" onSubmit={(event) => changeDelete(event)}>
              <div
                className="Form__title"
                style={{fontSize: 19, fontWeight: 700}}
              >
                {(!!object.short_name && object.short_name) || "-"}
                <div className="Text__signature">Удалить</div>
              </div>

              <div className="Form__actions Form__actions_center">
                <button className="Action__button Action__button_all Action__button_red">
                  Удалить
                </button>
              </div>

              <div className="Form__actions Form__actions_center">
                <div className="Action__link" onClick={() => setIsDelete(false)}>
                  Отмена
                </div>
              </div>
            </form>
          )}
        </Modal>
      </>
    )
  );
}

export default ModalContractorEdit;
