import React, {Fragment} from "react";
import {ButtonIcon, Loading,} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../../components/ScrollBlock/ScrollBlock";
import ModalBoningOrderEdit from "../BoningOrderItem/ModalBoningOrderEdit";
import {ModalAddOrderItem} from "../BoningOrderItem/ModalAddOrderItem";
import ModalOrderItemEdit from "../BoningOrderItem/ModalOrderItemEdit";
import {ModalAddEditOrderBoning} from "../BoningOrderItem/ModalAddEditOrderBoning";
import ModalOrderItemTransaction from "../BoningOrderItem/ModalOrderItemTransaction";
import ModalOrderItemTransactionRollback from "../BoningOrderItem/ModalOrderItemTransactionRollback";
import ModalOrderEditAction from "../BoningOrderItem/ModalOrderEditAction";
import {BoningOrderItem} from "../BoningOrderItem";

function BoningOrderView(props) {
  const {
    object,

    setIsUpdateObject,
    isUpdateObject,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    isModalObjectAdd, setIsModalObjectAdd,
    modalItemEdit, setModalItemEdit,
    modalOrderItemAdd, setModalOrderItemAdd,
    modalOrderItemEdit, setModalOrderItemEdit,

    modalAddBoning, setModalAddBoning,

    modalItemTransaction, setModalItemTransaction,
    modalItemTransactionRollback, setModalItemTransactionRollback,

    modalOrderEditAction, setModalOrderEditAction,
  } = props;

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Обвалка
          </div>

          <div className="Title__settings">
            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 8}}
              onClick={() => (setIsUpdateObject(!isUpdateObject))}
            />
          </div>
        </div>

        {!object && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!object && (
          <div className="Page__content">
            <ul className="List">
              <Fragment key={"item_" + object.uuid}>
                <BoningOrderItem {...props} isUnrollObject={true}/>
              </Fragment>
            </ul>
          </div>
        )}

        {!!modalItemEdit && (
          <ModalBoningOrderEdit
            object={modalItemEdit}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalItemEdit(null)}
          />
        )}

        {!!modalOrderItemAdd && (
          <ModalAddOrderItem
            object={modalOrderItemAdd}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalOrderItemAdd(null)}
          />
        )}
        {!!modalOrderItemEdit && (
          <ModalOrderItemEdit
            object={modalOrderItemEdit}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalOrderItemEdit(null)}
          />
        )}

        {!!modalAddBoning && (
          <ModalAddEditOrderBoning
            object={modalAddBoning}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalAddBoning(null)}
          />
        )}

        {!!modalItemTransaction && (
          <ModalOrderItemTransaction
            object={modalItemTransaction}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalItemTransaction(null)}
          />
        )}

        {!!modalItemTransactionRollback && (
          <ModalOrderItemTransactionRollback
            object={modalItemTransactionRollback}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalItemTransactionRollback(null)}
          />
        )}

        {!!modalOrderEditAction && (
          <ModalOrderEditAction
            object={modalOrderEditAction}
            successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
            handleClose={() => setModalOrderEditAction(null)}
          />
        )}

      </div>
    </ScrollBlock>
  );
}

export default BoningOrderView;
