import React, {useRef, useState} from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
  useObject,
  Textarea,
  Input,
  jsonFormatText, Select, isValidateNullText,
} from "@vokymlak/kabinet-ui";
import ModalTransactionAddComing from "./ModalTransactionAddComing";
import ModalTransactionAddIntake from "./ModalTransactionAddIntake";
import ModalTransactionAddRevision from "./ModalTransactionAddRevision";
import ModalTransactionAddWriteOff from "./ModalTransactionAddWriteOff";

function ModalTransactionAdd(props) {
  const {
    object: [nomenclature],
  } = useObject({
    apiUrl: "/api/nomenclature/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });



  const [modalObjectComing, setModalObjectComing] = useState(null)
  const [modalObjectIntake, setModalObjectIntake] = useState(null)
  const [modalObjectWriteOff, setModalObjectWriteOff] = useState(null)
  const [modalObjectRevision, setModalObjectRevision] = useState(null)

  return (
    !!nomenclature && (
      <>
      <Modal handleClose={() => props.handleClose()}>
        <div className="Form">
          <div className="Form__title">
            {nomenclature.name}
            <div className="Text__signature">Учёт</div>
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue" onClick={() => setModalObjectComing(nomenclature)}>
              Приход
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue" onClick={() => setModalObjectIntake(nomenclature)}>
              Расход
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_red"
                    onClick={() => setModalObjectWriteOff(nomenclature)}>
              Списание
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_gray"
                    onClick={() => setModalObjectRevision(nomenclature)}>
              Ревизия
            </button>
          </div>

        </div>
      </Modal>

        {!!modalObjectComing && (
          <ModalTransactionAddComing
            nomenclature={modalObjectComing}
            successfulRequest={() => (props.successfulRequest(), props.handleClose())}
            handleClose={() => setModalObjectComing(null)}
          />
        )}
        {!!modalObjectIntake && (
          <ModalTransactionAddIntake
            nomenclature={modalObjectIntake}
            successfulRequest={() => (props.successfulRequest(), props.handleClose())}
            handleClose={() => setModalObjectIntake(null)}
          />
        )}
        {!!modalObjectWriteOff && (
          <ModalTransactionAddWriteOff
            nomenclature={modalObjectWriteOff}
            successfulRequest={() => (props.successfulRequest(), props.handleClose())}
            handleClose={() => setModalObjectWriteOff(null)}
          />
        )}
        {!!modalObjectRevision && (
          <ModalTransactionAddRevision
            nomenclature={modalObjectRevision}
            successfulRequest={() => (props.successfulRequest(), props.handleClose())}
            handleClose={() => setModalObjectRevision(null)}
          />
        )}
        </>
    )
  );
}

export default ModalTransactionAdd;
