import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  Textarea,
  useNotification,
  Modal,
  jsonFormatText,
  isValidateNullText, Input,
} from "@vokymlak/kabinet-ui";
import moment from "moment";

function ModalSiteShopOrderAdd(props) {
  const inputDate = useRef(null);

  const inputBuyerName = useRef(null);
  const inputBuyerPhone = useRef(null);
  const inputBuyerEmail = useRef(null);

  // const inputByTime = useRef(null);
  //
  // const [isPickup, setIsPickup] = useState(false);
  //
  // const inputStreet = useRef(null);
  // const inputHouse = useRef(null);
  // const inputApartment = useRef(null);
  // const inputEntrance = useRef(null);
  // const inputFloor = useRef(null);
  //
  // const inputCommentOnOrder = useRef(null);

  const { notification } = useNotification();

  function extractNumbers(str) {
    return str.replace(/\D/g, "");
  }

  const changeAdd = (event) => {
    event.preventDefault();

    const date = jsonFormatText(inputDate.current.value);

    const buyerName = jsonFormatText(inputBuyerName.current.value);
    const buyerPhone = jsonFormatText(extractNumbers(inputBuyerPhone.current.value));
    const buyerEmail = jsonFormatText(inputBuyerEmail.current.value);

    // const byTime = jsonFormatText(inputByTime.current.value);

    let street = null;
    let house = null;
    let apartment = null;
    let entrance = null;
    let floor = null;

    // if (!isPickup) {
    //   street = jsonFormatText(inputStreet.current.value);
    //   house = jsonFormatText(inputHouse.current.value);
    //   apartment = jsonFormatText(inputApartment.current.value);
    //   entrance = jsonFormatText(inputEntrance.current.value);
    //   floor = jsonFormatText(inputFloor.current.value);
    // }
    //
    // const commentOnOrder = jsonFormatText(inputCommentOnOrder.current.value);

    if (isValidateNullText(date)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      date: date,

      buyer_name: buyerName,
      buyer_phone: buyerPhone,
      buyer_email: buyerEmail,

      // by_time: byTime,
      //
      // is_pickup: isPickup,
      //
      // street: street,
      // house: house,
      // apartment: apartment,
      // entrance: entrance,
      // floor: floor,
      // comment_on_order: commentOnOrder,
    });

    axios
      .post("/api/site-shop-order/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Заказ добавлен", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">
          Новый
          <div className="Text__signature">Заказ по рознице</div>
        </div>

        <div className="Form__field">
          <Input reference={inputDate} type={'datetime-local'} isFixed={true} label="Дата заказа *" value={moment().format("YYYY-MM-DD HH:mm")} />
        </div>

        <div className="Form__field">
          <Input reference={inputBuyerName} label="Имя покупателя" />
        </div>

        <div className="Form__field">
          <Input type={'tel'} reference={inputBuyerPhone} label="Телефон покупателя (79991234567)" />
        </div>

        <div className="Form__field">
          <Input type={'email'} reference={inputBuyerEmail} label="Email покупателя" />
        </div>

        {/*<div className="Form__field">*/}
        {/*  <Input reference={inputByTime} type={'datetime-local'} isFixed={true} label="Ко времени" value={null} />*/}
        {/*</div>*/}

        {/*<div className="Form__field">*/}
        {/*  <div*/}
        {/*    className="Button__swith"*/}
        {/*    style={{*/}
        {/*      padding: "8px",*/}
        {/*      width: "100%",*/}
        {/*      border: isPickup ? "2px solid #ffe082" : "2px dashed #20202015",*/}
        {/*      backgroundColor: isPickup ? "#ffe082" : "#ffffff",*/}
        {/*      borderRadius: 6,*/}
        {/*      marginTop: 20,*/}
        {/*      display: "flex",*/}
        {/*      alignItems: "center",*/}
        {/*      justifyContent: "space-between",*/}
        {/*      cursor: "pointer",*/}
        {/*    }}*/}
        {/*    onClick={() => setIsPickup(!isPickup)}*/}
        {/*  >*/}
        {/*    <div*/}
        {/*      className="Swith__text"*/}
        {/*      style={{*/}
        {/*        fontSize: 17,*/}
        {/*        fontWeight: 600,*/}
        {/*        display: "flex",*/}
        {/*        alignItems: "center",*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      Самовывоз*/}
        {/*    </div>*/}
        {/*    <div*/}
        {/*      className={*/}
        {/*        "Swith Swith_gray" +*/}
        {/*        ((isPickup && " Swith_active Swith_active_gray") || "")*/}
        {/*      }*/}
        {/*    ></div>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*{!isPickup && (*/}
        {/*  <>*/}
        {/*<div className="Form__field">*/}
        {/*  <Input reference={inputStreet} label="Улица" />*/}
        {/*</div>*/}

        {/*<div className="Form__field">*/}
        {/*  <Input reference={inputHouse} label="Дом" />*/}
        {/*</div>*/}

        {/*<div className="Form__field">*/}
        {/*  <Input reference={inputApartment} label="Квартира" />*/}
        {/*</div>*/}

        {/*<div className="Form__field">*/}
        {/*  <Input reference={inputEntrance} label="Подъезд" />*/}
        {/*</div>*/}

        {/*<div className="Form__field">*/}
        {/*  <Input reference={inputFloor} label="Этаж" />*/}
        {/*</div>*/}
        {/*  </>*/}
        {/*)}*/}

        {/*<div className="Form__field Form__field_last">*/}
        {/*  <Textarea reference={inputCommentOnOrder} label="Комментарий к заказу" />*/}
        {/*</div>*/}

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Добавить
          </button>
        </div>

        {/*<div className="Form__actions Form__actions_center">*/}
        {/*  <div className="Action__link" onClick={() => props.handleClose()}>*/}
        {/*    Отмена*/}
        {/*  </div>*/}
        {/*</div>*/}
      </form>
    </Modal>
  );
}

export default ModalSiteShopOrderAdd;
