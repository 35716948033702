import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { ROOT_URL } from "../../../config/url";
import {
  useAction,
  useList,
  useUnroll,
  useNotification,
  getErrorMessageByKey,
} from "@vokymlak/kabinet-ui";

export const useDirectors = () => {
  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, changeUpdate],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
  } = useList({
    apiUrl: "/api/directors",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 100,
    isSearch: true,
  });

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const [isModalAddAccount, setIsModalAddAccount] = useState(false);
  const [modalEditAccount, setModalEditAccount] = useState(null);

  const inputSearchText = useRef(null);
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  const { setAction } = useAction(["directors", "isUpdate"], () => changeUpdate());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.account.uuid);
      setAction([...listUuid, "directors", "isUpdate"]);
    } else {
      setAction(["directors", "isUpdate"]);
    }
  }, [list]);

  const { notification } = useNotification();

  function onChangeSearchText() {
    setSearchQuery(inputSearchText.current.value);
  }

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  const isActivatedAccount = (uuid) => {
    axios
      .get("/api/director/" + uuid + "/edit/activate", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsUpdateList(!isUpdateList);
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return {
    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,

    isActivatedAccount,

    modalEditAccount,
    setModalEditAccount,
    isModalAddAccount,
    setIsModalAddAccount,
  };
};
