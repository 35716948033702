import React, {Fragment, useEffect} from "react";
import {ButtonIcon, ButtonIconExport, TextSearchMarker} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";
import ProductionOrderProductionList from "./ProductionOrderProductionList";
import {ROOT_URL} from "../../../../config/url";
import {NavLink} from "react-router-dom";

function ProductionOrderItem(props) {
  const {
    object,

    searchQuery,

    setIsUpdateObject,
    isUpdateObject,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    isUnrollObject,

    isModalObjectAdd, setIsModalObjectAdd,
    modalItemEdit, setModalItemEdit,
    modalOrderItem2Add, setModalOrderItem2Add,
    modalOrderItem3Edit, setModalOrderItem3Edit,

    modalAddBoning, setModalAddBoning,

    modalItemTransaction, setModalItemTransaction,
    modalItemTransactionRollback, setModalItemTransactionRollback,

    modalOrderEditAction, setModalOrderEditAction,
  } = props;

  useEffect(() => {
    if (isUnrollObject && !!object) {
      changeUnroll(object.uuid);
    }
  }, []);

  function isBoningOrderTransaction(object) {
    let isTransaction = false

    if (!!object.items) {
      object.items.forEach(i => {
        if (i.is_transaction) {
          isTransaction = true
        }
      })
    }

    return isTransaction
  }

  return (
    <>
      <li
        className={
          isUnroll(object.uuid) ? "List__item active" : "List__item"
        }
        onClick={() => changeUnroll(object.uuid)}
        style={{
          padding: "10px 12px",
          position: "relative",
        }}
      >
        <div
          className="Item__title"
          style={{
            width: "100%",
          }}
        >
          <div
            className="Element__title"
            style={{
              width: "100%",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                fontSize: 19,
                fontWeight: 700,
                width: "100%",
              }}
            >
              №<TextSearchMarker
              text={object.id}
              search={searchQuery}
            />{!!object.date && ' от ' + moment(object.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}
            </div>

            {!!object.final_amount && (
              <div
                style={{
                  width: "100%",
                  marginTop: 4,
                  fontWeight: 700,
                  fontSize: 24
                }}
              >
                          <span style={{marginRight: 16}}>
                            {!!object.final_amount && new Intl.NumberFormat("ru-RU").format(Math.round(Number(
                              (object.final_amount / 100).toFixed(2))))}

                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>
                            {!!object.final_amount && "(" + new Intl.NumberFormat("ru-RU").format(Number(
                              (object.final_amount / 100).toFixed(2))) + ")"}
                            </span>

                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб.</span></span>
              </div>
            )}

            {!!object.items && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  marginTop: 2,
                }}
              >
                <div
                  style={{
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                >
                  {object.items.map((i, index) => (
                    <div key={"k_" + object.uuid + ":" + i.uuid}
                         style={{marginTop: 3, marginBottom: 3}}>
                      <div style={{
                        fontSize: 12,
                        backgroundColor: !i.is_transaction ? "#0061fc33" : "#20202033",
                        display: "inline-block",
                        color: "#202020",
                        padding: "2px 4px 2px",
                        borderRadius: 3,
                        margin: "0 2px",
                      }}>
                        {index + 1} # {i.nomenclature.name}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "absolute",
                top: -8,
                left: 0,
                justifyContent: " flex-end",
                width: "100%",
                padding: "0 8px",
              }}
            >
              {!!object.production_order_status_type && (
                <div
                  style={{
                    fontSize: 11,
                    fontWeight: 700,
                    display: "inline-block",
                    padding: "2px 4px 2px",
                    borderRadius: 3,
                    margin: "0 2px",
                    backgroundColor: object.production_order_status_type.tag === 'AT_WORK' ? "#0064ff" : object.production_order_status_type.tag === 'NEW' ? "#2e7d32" : "#202020",
                    color: (object.production_order_status_type.tag === 'AT_WORK' || object.production_order_status_type.tag === 'NEW') ? "white" : "white",
                  }}
                >
                  {object.production_order_status_type.name}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="Item__settings">
          <div className="Element__datetime">
          </div>
        </div>
      </li>

      {isUnroll(object.uuid) && (
        <li className="List__content">
          <div className="Content__header">
            <div className="Header__title">
              <div
                className="Button__text Button__text_right"
                onClick={() => setModalOrderItem2Add(object)}
              >
                Добавить позицию
              </div>
              <div
                className="Button__text Button__text_right"
                onClick={() => setModalOrderEditAction(object)}
              >
                Статус
              </div>

              <NavLink
                className="Button__text Button__text_right"
                to={"/production/" + object.uuid}>
                Подробнее
              </NavLink>
            </div>

            <div className="Header__settings">
              <ButtonIconExport
                tooltipText={"Выгрузить накладную"}
                iconName={"custom-download"}
                iconColor={"#202020"}
                size={24}
                style={{marginLeft: 12}}
                apiUrl={"/api/production-order/" + object.uuid + "/export/print"}
                rootUrl={ROOT_URL}
                mimeType={"application/pdf"}
                fileName={"№" + object.id + (!!object.date && '_от_' + moment(object.date.substring(0, 19)).format("DD.MM.YYYY_HH:mm")) + ".pdf"}
                // fileName={"price.html"}
              />

              {!isBoningOrderTransaction(object) && (
                <ButtonIcon
                  tooltipText={"Редактировать"}
                  iconName={"custom-edit"}
                  iconColor={"#202020"}
                  size={36}
                  style={{marginLeft: 12}}
                  onClick={() => setModalItemEdit(object)}
                />
              )}
            </div>
          </div>

          <ProductionOrderProductionList
            {...props}
            object={object}
            isUpdateList={isUpdateObject}
            setIsUpdateList={setIsUpdateObject}
          />

          <div className="Content__footer">
            <div className="Footer__title">
              {!object.is_paid && (
                <div
                  className="Button__text Button__text_right"
                  onClick={() => setModalOrderItem2Add(object)}
                >
                  Добавить позицию
                </div>
              )}
            </div>
            <div className="Footer__settings">
            </div>
          </div>
        </li>
      )}
    </>
  );
}

export default ProductionOrderItem;
