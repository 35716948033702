import React from "react";
import styles from "./Viewer.module.scss";
import {Icon, Loading} from "@vokymlak/kabinet-ui";

const ViewerView = (props) => {
  const { file, view, mimeType, viewerClose, isLoading } = props;

  function isUUIDv4(str) {
    const uuidv4Pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidv4Pattern.test(str);
  }

  return (
    (!!view || !!file || isLoading) && (
      <div className={styles._block}>
        <div className={styles._mask} onClick={() => viewerClose()} />
        <div className={styles._close} onClick={() => viewerClose()}>
          <div className={styles._close__button}>
            <Icon name={"custom-close"} color={"#ffffff"} size={24} />
          </div>
        </div>

        {!!view && <div className={styles._image}>{view}</div>}

        {!view && (<>
        {!!file ? (
          <>
          {!isUUIDv4(file) && (
            <img className={styles._image} src={`data:${mimeType};base64, ${file}`} />
          )}
            {isUUIDv4(file) && (
              <img className={styles._image} src={mimeType} />
            )}
          </>
        ) : isLoading ? (
          <div className={styles._load}>
            <Loading color={"white"} />
          </div>
        ) : null}
          </>)}
      </div>
    )
  );
};

export default ViewerView;
