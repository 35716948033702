import React, {useEffect, useRef, useState} from "react";
import { ROOT_URL } from "../../../config/url";
import {useList, useAction, useUnroll, useNotification,getErrorMessageByKey} from "@vokymlak/kabinet-ui";
import axios from "axios";

function useOrders() {
  const {notification} = useNotification();

  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
  } = useList({
    apiUrl: "/api/site-shop-orders",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 100,
    isSearch: true,
  });

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const { setAction } = useAction(["site-shop-orders"], () => updateList());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "site-shop-orders"]);
    } else {
      setAction(["site-shop-orders"]);
    }
  }, [list]);

  const inputSearchText = useRef(null);
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  const onChangeSearchText = () => {
    setSearchQuery(inputSearchText.current.value);
  };

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  // const isPublishedNomenclature = (object) => {
  //   axios
  //     .get("/api/nomenclature/" + object.uuid + "/edit/publish", {
  //       withCredentials: false,
  //       baseURL: ROOT_URL,
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + localStorage.getItem("tokenSession"),
  //       },
  //     })
  //     .then(() => {
  //       setIsUpdateList(!isUpdateList);
  //     })
  //     .catch((error) => {
  //       notification(getErrorMessageByKey(error.response.data.error), "red");
  //     });
  // };

  return {
    list,
    count,
    setList,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,

    // isPublishedNomenclature,
  };
}

export default useOrders;
