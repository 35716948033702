import React, {useEffect, useRef, useState} from "react";
import { ROOT_URL } from "../../../../../config/url";
import {useList, useAction, useUnroll, getErrorMessageByKey, useNotification} from "@vokymlak/kabinet-ui";
import axios from "axios";

function useNomenclatureTransactions(props) {
  const [selectAct, setSelectAct] = useState(null);

  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, changeUpdate],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
  } = useList({
    apiUrl: "/api/nomenclature/" + props.object.uuid + "/transactions",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 100,
    isSearch: true,
  });

  const inputSearchText = useRef(null);
  function onChangeSearchText() {
    setSearchQuery(inputSearchText.current.value);
  }

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  const {notification} = useNotification();

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const { setAction } = useAction(props.object.uuid, () => changeUpdate());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, props.object.uuid]);
    } else {
      setAction(props.object.uuid);
    }
  }, [list]);

  const [modalImport, setModalImport] = useState(null);
  const [modalAdd, setModalAdd] = useState(null);
  const [modalEdit, setModalEdit] = useState(null);

  return {
    object: props.object,

    list,
    setList,
    count,

    setIsUpdateList,
    isUpdateList,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    modalAdd,
    setModalAdd,

    modalEdit,
    setModalEdit,

    setModalImport,
    modalImport,

    selectAct,
    setSelectAct,
  };
}

export default useNomenclatureTransactions;
