import React, {useRef, useState} from "react";
import {getAccountFio, Modal, Select, useList,} from "@vokymlak/kabinet-ui";
import ModalContractorItemSelect from "../RealizationOrderItem/ModalContractorItemSelect";
import {ROOT_URL} from "../../../../config/url";

function ModalOrderFilterEdit(props) {
  const {
    selectMonth,
    setSelectMonth,

    selectContractor,
    setSelectContractor,
    filterContractor,
    setFilterContractor,

    selectPaid,
    setSelectPaid,

    selectPayment,
    setSelectPayment,
    filterPayment,
    setFilterPayment,

    selectPaymaster,
    setSelectPaymaster,
    filterPaymaster,
    setFilterPaymaster,
  } = props

  const {
    list: [employees],
  } = useList({
    apiUrl: "/api/employees/list",
    rootUrl: ROOT_URL,
  });

  const inputMonth = useRef(null);

  const [isNullContractor, setIsNullContractor] = useState(!!filterContractor && filterContractor === "null");
  const [inputContractor, setInputContractor] = useState(selectContractor);
  const [isModalSelectContractor, setIsModalSelectContractor] = useState(false);

  const inputPaid = useRef(null);

  const [isNullPayment, setIsNullPayment] = useState(!!filterPayment && filterPayment === "null");
  const inputPayment = useRef(null);

  const [isNullPaymaster, setIsNullPaymaster] = useState(!!filterPaymaster && filterPaymaster === "null");
  const [inputPaymaster, setInputPaymaster] = useState(selectPaymaster);

  const changeEdit = (event) => {
    event.preventDefault();

    const month = inputMonth.current.value;
    const paid = inputPaid.current.value;
    let payment = null;
    if (!isNullPayment) {
      payment = inputPayment.current.value;
    }

    setSelectMonth((month !== "null" && month) || null)

    setFilterContractor(isNullContractor ? "null" : (!!inputContractor ? inputContractor.uuid : null))
    setSelectContractor((!isNullContractor && inputContractor) || null)

    setSelectPaid((paid !== "null" && paid) || null)

    setFilterPayment(isNullPayment ? "null" : (!!payment && payment !== "null" && payment))
    setSelectPayment((!isNullPayment && (!!payment && payment !== "null" && payment)) || null)

    setFilterPaymaster(isNullPaymaster ? "null" : (!!inputPaymaster ? inputPaymaster.uuid : null))
    setSelectPaymaster((!isNullPaymaster && inputPaymaster) || null)

    props.handleClose();
  };

  const monthText = (month) => {
    const months = [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ];

    return months[Number(month) - 1];
  };

  const getMonthSelectOptions = () => {
    const options = [
      {
        value: "null",
        title: "Все",
      },
    ];

    const list = ['01', '02', '03', '05', '06', '07', '08', '09', '10', '11', '12']

    list.map((item) => {
      const option = {
        value: item,
        title: monthText(item),
      };

      options.push(option);
    });

    return options;
  };

  const getPaidSelectOptions = () => {
    return [
      {
        value: "null",
        title: "Все",
      },
      {
        value: "true",
        title: "Да",
      },
      {
        value: "false",
        title: "Нет",
      },
    ];
  };

  const getPaymentSelectOptions = () => {
    return [
      {
        value: "null",
        title: "Все",
      },
      {
        value: "CASH",
        title: "Наличными",
      },
      {
        value: "CARD",
        title: "Картой",
      },
      {
        value: "ONLINE",
        title: "Онлайн",
      },
      {
        value: "CHECK",
        title: "По счёту",
      },
      {
        value: "PREPAID_EXPENSE",
        title: "В счëт зарплаты",
      },
    ];
  };

  const getPaymasterSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Все",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.account.uuid,
        title: getAccountFio(item.account),
      };

      options.push(option);
    });

    return options;
  };

  const changePaymaster = (uuid) => {
    if (uuid === "null") {
      setInputPaymaster(null);
    } else {
      let paymaster = null;
      employees.forEach(e => {
        if (e.account.uuid === uuid) {
          paymaster = e.account;
        }
      });
      setInputPaymaster(paymaster);
    }
  }

  return (!!employees &&
    <>
      <Modal handleClose={() => props.handleClose()}>

        <form className="Form" onSubmit={(event) => changeEdit(event)}>
          <div className="Form__title">
            Фильтры
          </div>

          <div className="Form__field">
            <Select
              isFixed={true}
              reference={inputMonth}
              options={getMonthSelectOptions()}
              label="Месяц"
              value={selectMonth}
            />
          </div>

          <div className="Form__field">
            <div
              className="Button__swith"
              style={{
                padding: "8px",
                width: "100%",
                border: isNullContractor ? "2px solid #ffe082" : "2px dashed #20202015",
                backgroundColor: isNullContractor ? "#ffe082" : "#ffffff",
                borderRadius: 6,
                marginTop: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
              onClick={() => setIsNullContractor(!isNullContractor)}
            >
              <div
                className="Swith__text"
                style={{
                  fontSize: 17,
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Не указан контрагент
              </div>
              <div
                className={
                  "Swith Swith_gray" +
                  ((isNullContractor && " Swith_active Swith_active_gray") || "")
                }
              ></div>
            </div>
          </div>

          {!isNullContractor && (
            <>
              {!inputContractor && (
                <div className="Form__field"
                     style={{
                       cursor: "pointer"
                     }}
                     onClick={() => setIsModalSelectContractor(true)}>
                  <div className="Field__label">
                    Контрагент
                  </div>
                  <div className="Field__text">
                    -
                  </div>
                </div>
              )}

              {!!inputContractor && (
                <div className="Form__field"
                     style={{
                       cursor: "pointer"
                     }}
                     onClick={() => setIsModalSelectContractor(true)}>
                  <div className="Field__label">
                    Контрагент
                  </div>
                  <div className="Field__text">
                    <div
                      style={{
                        fontSize: 15,
                        fontWeight: 700,
                        width: "100%",
                      }}
                    >
                      {!!inputContractor.short_name && inputContractor.short_name}
                      <div style={{
                        fontSize: 12,
                        fontWeight: 400,
                      }}>
                        {!!inputContractor.name && inputContractor.name}
                      </div>
                      <div style={{
                        fontSize: 12,
                        fontWeight: 400,
                      }}>
                    <span
                      style={{marginRight: 8}}>ОГРН:&nbsp;{(!!inputContractor.ogrn && inputContractor.ogrn) || "-"}</span>
                        <span
                          style={{marginRight: 8}}>ИНН:&nbsp;{(!!inputContractor.inn && inputContractor.inn) || "-"}</span>
                        <span
                          style={{marginRight: 8}}>КПП:&nbsp;{(!!inputContractor.kpp && inputContractor.kpp) || "-"}</span>
                      </div>
                      <div style={{
                        fontSize: 12,
                        fontWeight: 400,
                      }}>
                    <span
                      style={{marginRight: 8}}>Банк:&nbsp;{(!!inputContractor.bank && inputContractor.bank) || "-"}</span>
                        <span
                          style={{marginRight: 8}}>Номер&nbsp;счёта:&nbsp;{(!!inputContractor.invoice_number && inputContractor.invoice_number) || "-"}</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          <div className="Form__field">
            <div
              className="Button__swith"
              style={{
                padding: "8px",
                width: "100%",
                border: isNullPayment ? "2px solid #ffe082" : "2px dashed #20202015",
                backgroundColor: isNullPayment ? "#ffe082" : "#ffffff",
                borderRadius: 6,
                marginTop: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
              onClick={() => setIsNullPayment(!isNullPayment)}
            >
              <div
                className="Swith__text"
                style={{
                  fontSize: 17,
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Не указана оплата
              </div>
              <div
                className={
                  "Swith Swith_gray" +
                  ((isNullPayment && " Swith_active Swith_active_gray") || "")
                }
              ></div>
            </div>
          </div>

          {!isNullPayment && (
            <>
              <div className="Form__field">
                <Select
                  isFixed={true}
                  reference={inputPayment}
                  options={getPaymentSelectOptions()}
                  label="Оплата"
                  value={selectPayment}
                />
              </div>
            </>
          )}

          <div className="Form__field">
            <Select
              isFixed={true}
              reference={inputPaid}
              options={getPaidSelectOptions()}
              label="Оплачен"
              value={selectPaid}
            />
          </div>

          <div className="Form__field">
            <div
              className="Button__swith"
              style={{
                padding: "8px",
                width: "100%",
                border: isNullPaymaster ? "2px solid #ffe082" : "2px dashed #20202015",
                backgroundColor: isNullPaymaster ? "#ffe082" : "#ffffff",
                borderRadius: 6,
                marginTop: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
              onClick={() => setIsNullPaymaster(!isNullPaymaster)}
            >
              <div
                className="Swith__text"
                style={{
                  fontSize: 17,
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Не указан кассир
              </div>
              <div
                className={
                  "Swith Swith_gray" +
                  ((isNullPaymaster && " Swith_active Swith_active_gray") || "")
                }
              ></div>
            </div>
          </div>

          {!isNullPaymaster && (
            <>
              <div className="Form__field">
                <Select
                  isFixed={true}
                  options={getPaymasterSelectOptions(employees)}
                  label="Кассир"
                  value={(!!inputPaymaster && inputPaymaster.uuid) || null}
                  onChange={(event) => changePaymaster(event.target.value)}
                />
              </div>
            </>
          )}

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Применить
            </button>
          </div>

        </form>
      </Modal>

      {isModalSelectContractor && (
        <ModalContractorItemSelect
          item={inputContractor}
          successfulRequest={item =>
            setInputContractor(item)
          }
          handleClose={() => setIsModalSelectContractor(false)}
        />
      )}
    </>
  );
}

export default ModalOrderFilterEdit;
