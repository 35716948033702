import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import MenuVertical from "./Menu/MenuVertical/MenuVertical";
import { Login } from "./Login/Login";
import "../App.scss";
import {
  useProfile,
  useAuth,
  WebsocketProvider,
  useWindowSize,
} from "@vokymlak/kabinet-ui";
import { WS_URL } from "../config/url";
import MenuHorizontal from "./Menu/MenuHorizontal/MenuHorizontal";
import {Routing} from "./Routing";

export const Screen = () => {
  const { profile } = useProfile();
  const { authToken } = useAuth();
  const { windowSize } = useWindowSize();

  return (
    !!windowSize &&
    <div className={ "App " + ((windowSize.width < 1024 ||
      (windowSize.width < windowSize.height)) ? 'App_horizontal' : 'App_vertical')}>
      {!!authToken && !!profile && (
        <WebsocketProvider wsUrl={WS_URL}>
            <div className="Navigate">
              {(windowSize.width < 1024 ||
                (windowSize.width < windowSize.height)) ? <MenuHorizontal /> : <MenuVertical />}
            </div>
            <div className="Content">
              <Routing />
            </div>
        </WebsocketProvider>
      )}

      {!authToken && (
        <Routes>
          <Route exact path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={<Navigate to="/login" />} />
        </Routes>
      )}
    </div>
  );
};
