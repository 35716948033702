import React, {useRef, useState} from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../config/url";
import {
  getErrorMessageByKey, Input,
  Modal,
  Select,
  useList,
  useNotification,
  useObject,
} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";
import ModalOrderItemSelect from "./ModalOrderItemSelect";

export const ModalAddOrderItem = (props) => {
  const selectNomenclatureUuid = useRef(null);

  const [selectItem, setSelectItem] = useState(null)
  const [isModalSelectItem, setIsModalSelectItem] = useState(false)

  const { notification } = useNotification();

  // const inputValue = useRef(null);
  // const inputQuantity = useRef(null);

  const {
    list: [list],
  } = useList({
    apiUrl: "api/nomenclatures/list",
    rootUrl: ROOT_URL,
  });

  const {
    object: [object],
  } = useObject({
    apiUrl: "api/boning-order/" + props.object.uuid,
    rootUrl: ROOT_URL,
  });

  // const quantityConvert = (numberString) => {
  //   return !!numberString && numberString.length > 0 && numberString !== "null"
  //     ? Number(String(Number(numberString).toFixed(3)).replace(".", ""))
  //     : 1;
  // };
  //
  // const valueConvert = (numberString) => {
  //   return !!numberString && numberString.length > 0 && numberString !== "null"
  //     ? Number(String(Number(numberString).toFixed(6)).replace(".", ""))
  //     : null;
  // };


  const changeAdd = (event) => {
    event.preventDefault();

    // const value = inputValue.current.value;
    // const quantity = inputQuantity.current.value;

    if (!selectItem) {
      notification("Укажите номенклатуру", "red");
      return;
    }

    const jsonData = JSON.stringify({
      // value: valueConvert(value),
      // quantity: quantityConvert(quantity),
      nomenclature: {
        uuid: selectItem.uuid,
      },
    });

    axios
      .post("/api/boning-order/" + props.object.uuid + "/item/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Позиция добавлена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const selectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
        const option = {
          value: item.uuid,
          title: item.name,
        };

        options.push(option);
    });

    return options;
  };

  return (
    !!list &&
    !!object && (
      <>
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div
            className="Form__title"
            style={{ fontSize: 19, fontWeight: 700 }}
          >
            №{object.id}{!!object.date && ' от ' + moment(object.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}
            <div className="Text__signature">Добавить позицию на обвалку</div>
          </div>


          {!selectItem && (
            <div className="Form__field Form__field_last"
                 style={{
                   cursor: "pointer"
                 }}
                 onClick={() => setIsModalSelectItem(true)}>
              <div className="Field__label">
                Номенклатура
              </div>
              <div className="Field__text">
                -
              </div>
            </div>
          )}

          {!!selectItem && (
            <div className="Form__field Form__field_last"
                 style={{
                   cursor: "pointer"
                 }}
                 onClick={() => setIsModalSelectItem(true)}>
              <div className="Field__label">
                Номенклатура
              </div>
              <div className="Field__text">
                {selectItem.id + ". " + selectItem.name   + (!!selectItem.article ? " - " + selectItem.article : "")}
              </div>
            </div>
          )}

          {/*<div className="Form__field">*/}
          {/*  <Input reference={inputValue} type={'number'} label="Размер (кг)"*/}
          {/*         value={*/}
          {/*           (!!object.value && object.value / 1000000) || null*/}
          {/*         } />*/}
          {/*</div>*/}

          {/*<div className="Form__field Form__field_last">*/}
          {/*  <Input reference={inputQuantity} type={'number'} label="Количество"*/}
          {/*         value={*/}
          {/*           (!!object.quantity && object.quantity / 1000) || 1*/}
          {/*         } />*/}
          {/*</div>*/}

          {/*<div className="Form__field">*/}
          {/*  <Select*/}
          {/*    isFixed={true}*/}
          {/*    reference={selectNomenclatureUuid}*/}
          {/*    options={*/}
          {/*      (!!list && selectOptions(list)) ||*/}
          {/*      []*/}
          {/*    }*/}
          {/*    label="Номенклатура"*/}
          {/*  />*/}
          {/*</div>*/}



          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Добавить
            </button>
          </div>

          {/*<div className="Form__actions Form__actions_center">*/}
          {/*  <div className="Action__link" onClick={() => props.handleClose()}>*/}
          {/*    Отмена*/}
          {/*  </div>*/}
          {/*</div>*/}
        </form>
      </Modal>

  {isModalSelectItem && (
    <ModalOrderItemSelect
      item={selectItem}
      successfulRequest={item =>
        setSelectItem(item)
      }
      handleClose={() => setIsModalSelectItem(false)}
    />
  )}
</>
    )
  );
};
