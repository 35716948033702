import React from "react";
import {ButtonIcon, useUnroll} from "@vokymlak/kabinet-ui";

function ProductionNomenclatureSpecifications(props) {
  const {setModalAddBoning} = props
  const { isUnroll, changeUnroll } = useUnroll();

  const getQuantity = (compositionQuantity, quantity) => {
    let coefficient = quantity / 1;
    return (compositionQuantity * coefficient).toFixed(3);
  };

  const getCount = (value, count) => {
    return (count / value).toFixed(3);
  };


  function getItemCount(item, composition) {
    let value = null

    if (!!item.counts) {
      item.counts.forEach(r => {
        if (r.nomenclature_id === composition.nomenclature_id) {
          value = r
        }
      })
    }

    return value
  }

  return (
    !!props.object && !!props.object.nomenclature && !!props.object.specification && (
        <table className="Table">
          <thead>
          <tr
            className="Table__tr"
            style={{
              backgroundColor: "#c5c5c5",
              border: "2px solid #202020",
            }}
          >
            <th className="Table__td">
              <div className="Td__title">{props.object.specification.name}</div>
            </th>
            <th
              className="Table__td"
              style={{
                textAlign: "right",
              }}
            >
              {props.object.nomenclature.is_pcs && (
                <>
                  x{props.object.value}
                </>
              )}
              {!props.object.nomenclature.is_pcs && (
                <>
              x{getCount(props.object.specification.value, props.object.value)}
                </>
                )}
            </th>
            <th
              className="Table__td"
              style={{
                textAlign: "right",
              }}
            ></th>
            <th
              className="Table__td"
              style={{
                textAlign: "right",
              }}
            ></th>
          </tr>
          </thead>
          <tbody>
          {!!props.object.specification.compositions &&
            props.object.specification.compositions.map((composition, compositionIndex) => (
              <React.Fragment key={"composition_" + composition.uuid}>
                <tr
                  className={"Table__tr"}
                  style={{
                    backgroundColor: isUnroll(composition.uuid)
                      ? "#c8e6c9"
                      : "inherit",
                    borderStyle: "2px solid",
                    borderLeftColor: isUnroll(composition.uuid)
                      ? "#81c784"
                      : "#bdbdbd",
                    borderRightColor: isUnroll(composition.uuid)
                      ? "#81c784"
                      : "#bdbdbd",
                    borderBottomColor: isUnroll(composition.uuid)
                      ? "#81c784"
                      : "#bdbdbd",
                  }}
                  // onClick={() => changeUnroll(composition.uuid)}
                >
                  <td
                    className="Table__td"
                    style={{
                      width: "100%",
                    }}
                  >
                    <div className="Td__title">
                      {compositionIndex + 1} # {composition.nomenclature.name}
                    </div>
                  </td>
                  <td
                    className="Table__td Table__td_all-border"
                    style={{
                      textAlign: "right",
                      fontSize: 20,
                      fontWeight: 700,
                    }}
                  >
                    <span style={{
                      textDecoration: !!getItemCount(props.object, composition) && "line-through",
                      opacity: !!getItemCount(props.object, composition) && 0.5
                    }}>
                    {props.object.nomenclature.is_pcs && (
                      <>
                        {getQuantity(composition.quantity, props.object.value)}
                      </>
                    )}
                    {!props.object.nomenclature.is_pcs && (
                      <>
                        {getQuantity(composition.quantity, getCount(props.object.nomenclature.basic_specification.value, props.object.value))}
                      </>
                    )}
                      </span>

                    {!!getItemCount(props.object, composition) && (<>&nbsp;</>)}
                    {!!getItemCount(props.object, composition) ? !!composition.measurement_unit_type
                      ? new Intl.NumberFormat("ru-RU").format((getItemCount(props.object, composition).value / composition.measurement_unit_type.ratio).toFixed(3))
                      : !!getItemCount(props.object, composition).value && getItemCount(props.object, composition).value : ""}

                  </td>
                  <td
                    className="Table__td Table__td_all-border"
                    style={{
                      textAlign: "center",
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  >
                    {!!composition.measurement_unit_type ? composition.measurement_unit_type.designation :
                      !!composition.measurement_type
                      && composition.measurement_type
                        .designation}
                  </td>
                  <td
                    className="Table__td Table__td_all-border">
                    {!props.object.is_transaction && (
                      <ButtonIcon
                        tooltipText={"Редактировать"}
                        iconName={"custom-edit"}
                        iconColor={"#202020"}
                        size={36}
                        onClick={() => setModalAddBoning({
                            order: props.order,
                            item: props.object,
                            composition: composition,
                            count: getItemCount(props.object, composition)
                          })}
                      />
                    )}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      )
  );
}

export default ProductionNomenclatureSpecifications;
