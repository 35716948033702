import React, {Fragment, useState} from "react";
import {ROOT_URL} from "../../../config/url";
import styleContent from "../../../styles/modules/Block.module.scss";
import styleCard from "../../../styles/modules/Card.module.scss";
import ModalNomenclaturePhotoEdit from "../NomenclaturePage/ModalNomenclaturePhotoEdit";
import {useViewer} from "../../../components/Viewer";
import ImageUploadB64 from "../../../functions/ImageUpload64";

function NomenclaturePhotosList(props) {
  const {object, isEdit, setIsUpdateList, isUpdateList} = props;

  const {viewerChildren} = useViewer();

  const changeViewer = (image, mimeType) => {
    viewerChildren(image, mimeType);
  };

  // const {
  //   list: [list],
  //   update: [isUpdateList, setIsUpdateList],
  // } = useList({
  //   apiUrl:"/api/nomenclature/" + object.uuid + "/photos",
  //   rootUrl:ROOT_URL
  // });

  const [modalNomenclaturePhotoEdit, setModalNomenclaturePhotoEdit] = useState(null);

  function isDatePattern(str) {
    const datePattern = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;
    const match = datePattern.test(str);
    return match;
  }

  return (!!object.photos && (
    <Fragment>
        <div className={styleContent._block}>
          <div className={styleContent._block__title}>Изображения</div>
          <div className={styleContent._block__content}>
            {object.photos.map((photo) => (<Fragment
                key={"item" + ":" + photo.uuid}>

                {!isDatePattern(photo.data) && (<div
                    className={styleCard._block + " " + styleCard._block_hover}
                    style={{
                      minWidth: 12 + 72, height: 12 + 72, padding: 6
                    }}
                    onContextMenu={isEdit ? (event) => (event.preventDefault(), setModalNomenclaturePhotoEdit(photo)) : () => null}
                    onClick={(!!photo.mime_type && (photo.mime_type === "image/jpeg" || photo.mime_type === "image/png")) ? (event) => (event.preventDefault(), changeViewer(photo.data, photo.mime_type)) : (event) => event.preventDefault()}>
                    {!!photo.data && !!photo.mime_type && (photo.mime_type === "image/jpeg" || photo.mime_type === "image/png") && (<>
                        <img src={`data:${photo.mime_type};base64, ${photo.data}`} height={72} style={{
                          borderRadius: 5
                        }} alt={""}/>
                      </>)}
                  </div>)}
                {!!isDatePattern(photo.data) && (<div
                    className={styleCard._block + " " + styleCard._block_hover}
                    style={{
                      minWidth: 12 + 72, height: 12 + 72, padding: 6
                    }}
                    onContextMenu={(event) => (event.preventDefault(), setModalNomenclaturePhotoEdit(photo))}
                    onClick={() => changeViewer(<ImageUploadB64
                      url={ROOT_URL + "/api/nomenclature/photo/" + photo.uuid + "/b64"}/>)}>
                    <ImageUploadB64 url={ROOT_URL + "/api/nomenclature/photo/" + photo.uuid + "/b64"} height={72}
                                    width={72}/>
                  </div>)}
              </Fragment>))}
          </div>
        </div>

        {!!modalNomenclaturePhotoEdit && (<ModalNomenclaturePhotoEdit
            nomenclaturePhoto={modalNomenclaturePhotoEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalNomenclaturePhotoEdit(null)}
          />)}
      </Fragment>
  ));
}

export default NomenclaturePhotosList;
