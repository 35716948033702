import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Accounts from "./ScreenAccounts/Accounts/Accounts";
import {
  ROLE_ADMINISTRATOR,
  ROLE_CLIENT,
  ROLE_DIRECTOR,
  ROLE_MANAGER,
} from "../config/constants";
import { Directors } from "./ScreenAccounts/Directors";
import { Clients } from "./ScreenAccounts/Clients";
import { Employees } from "./ScreenAccounts/Employees";
import {
  useProfile,
  isProfileRoleTypeTags,
} from "@vokymlak/kabinet-ui";
import {Nomenclature} from "./ScreenNomenclatures/NomenclaturePage";
import {Nomenclatures} from "./ScreenNomenclatures/Nomenclatures";
import {NomenclaturesRouting} from "./ScreenNomenclatures/NomenclaturesRouting";
import {HandbookRouting} from "./modules/ScreenHandbook/HandbookRouting";
import {Cows} from "./ScreenCows/Cows";
import {ProductionOrders} from "./ScreenErp/ScreenProductionOrders/ProductionOrders";
import {BoningOrders} from "./ScreenErp/ScreenBoningOrders/BoningOrders";
import {TicketsRouting} from "./modules/ScreenTickets/TicketsRouting";
import {SiteShopOrders} from "./ScreenSite/SiteShopOrders";
import {Contractors} from "./ScreenCrm/Contractors";
import {IncomeOrders} from "./ScreenCrm/ScreenIncomeOrders/IncomeOrders";
import {RealizationOrder} from "./ScreenCrm/ScreenRealizationOrders/RealizationOrder";
import {RealizationOrders} from "./ScreenCrm/ScreenRealizationOrders/RealizationOrders";
import {IncomeOrder} from "./ScreenCrm/ScreenIncomeOrders/IncomeOrder";
import {ProductionOrder} from "./ScreenErp/ScreenProductionOrders/ProductionOrder";
import {BoningOrder} from "./ScreenErp/ScreenBoningOrders/BoningOrder";
import PlanWarehouse from "./ScreenPlans/PlanWarehouseNew/PlanWarehouse";

export const Routing = () => {
  const { profile } = useProfile();

  return (
    !!profile && (
    <Routes>
      {isProfileRoleTypeTags(profile, [ROLE_CLIENT]) && (
        <>
        </>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
        ROLE_MANAGER,
      ]) && (
        <>
          <Route path="/employees" element={<Employees />} />
          <Route path="/clients" element={<Clients />} />

          <Route path="/tickets/*" element={<TicketsRouting />}/>
          <Route path="/handbook/*" element={<HandbookRouting />} />

          <Route path="/all-nomenclatures" element={<Nomenclatures isEdit={true} />} />
          <Route path="/nomenclatures/*" element={<NomenclaturesRouting />} />
          <Route path="/nomenclature/:nomenclatureUuid/*" element={<Nomenclature />} />

          <Route path="/cows" element={<Cows />} />

          <Route path="/site-shop-orders" element={<SiteShopOrders />}/>

          <Route path="/contractors" element={<Contractors />} />

          <Route path="/realization-orders" element={<RealizationOrders />} />
          <Route path="/realization/:realizationOrderUuid" element={<RealizationOrder />} />
          <Route path="/plan-warehouse" element={<PlanWarehouse/>}/>

          <Route path="/income-orders" element={<IncomeOrders />} />
          <Route path="/income/:incomeOrderUuid" element={<IncomeOrder />} />

          <Route path="/production-orders" element={<ProductionOrders />} />
          <Route path="/production/:productionOrderUuid" element={<ProductionOrder />} />

          <Route path="/boning-orders" element={<BoningOrders />} />
          <Route path="/boning/:boningOrderUuid" element={<BoningOrder />} />

          {isProfileRoleTypeTags(profile, [
            ROLE_ADMINISTRATOR,
            ROLE_DIRECTOR,
          ]) && (
            <>
              <Route path="/directors" element={<Directors />} />

              {isProfileRoleTypeTags(profile, [ROLE_ADMINISTRATOR]) && (
                <>
                  <Route path="/accounts" element={<Accounts />} />
                </>
              )}
            </>
          )}
        </>
      )}

      {isProfileRoleTypeTags(profile, [ROLE_CLIENT]) && (
        <Route
          exact
          path="/"
          element={<Navigate to="/my" replace />}
        />
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_DIRECTOR,
        ROLE_MANAGER,
      ]) && (
        <Route
          exact
          path="/"
          element={<Navigate to="/all-nomenclatures" replace />}
        />
      )}

      {isProfileRoleTypeTags(profile, [ROLE_ADMINISTRATOR]) && (
        <Route
          exact
          path="/"
          element={<Navigate to="/accounts" replace />}
        />
      )}

      <Route path="/login" element={<Navigate to="/" />} />
    </Routes>
  )
  );
};
