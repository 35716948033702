import React, {useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../../config/url";
import {
  getErrorMessageByKey,
  Input,
  isValidateNullText,
  Modal,
  useNotification,
  useObject,
} from "@vokymlak/kabinet-ui";
import moment from "moment";
import styleTumbler from "../../../../styles/modules/Tumbler.module.scss";

function ModalOrderItemEdit3(props) {
  const [isOpt, setIsOpt] = useState(false);

  const {
    object: [object],
  } = useObject({
    apiUrl: "api/realization-order-item/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputValue = useRef(null);
  const inputQuantity = useRef(null);
  const inputPrice = useRef(null);
  const inputPriceOpt = useRef(null);

  const [isDelete, setIsDelete] = useState(false);

  const {notification} = useNotification();

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0 && numberString !== "null"
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const numberConvertV = (numberString) => {
    return !!numberString && numberString.length > 0 && numberString !== "null"
      ? Number(String(Number(numberString).toFixed(3)))
      : null;
  };

  const quantityConvert = (numberString) => {
    return !!numberString && numberString.length > 0 && numberString !== "null"
      ? Number(String(Number(numberString).toFixed(6)).replace(".", ""))
      : null;
  };

  const [tumbler, setTumbler] = useState("ac");
  const [tumbler2, setTumbler2] = useState("aa");

  const changeEdit = (event) => {
    event.preventDefault();

    let quantity;
    let price = numberConvert(inputPrice.current.value);
    let priceOpt = numberConvert(inputPriceOpt.current.value);

    if (isOpt) {
      if (!!object.nomenclature && !!object.nomenclature.price_opt) {
        priceOpt = object.nomenclature.price_opt
      }
    }

    if (tumbler === "aa") {
      const value = inputValue.current.value;
      const v = Number((Number(1).toFixed(6) * (!!object.nomenclature.value && !!object.nomenclature.measurement_unit_type ?
        (object.nomenclature.value / object.nomenclature.measurement_unit_type.ratio) : !!object.nomenclature.value && object.nomenclature.value)).toFixed(3))
      quantity = Number(value / v).toFixed(6)

    } else {
      quantity = inputQuantity.current.value
    }

    if (tumbler2 === "aa" && !!props.object.final_amount && (!!price || price === 0)) {
      price = props.object.final_amount - price
    }

    if (tumbler2 === "ad" && !!props.object.final_amount && (!!price || price === 0)) {
      price = props.object.final_amount - (props.object.final_amount / 100 * (price / 100))
    }

    if (tumbler2 === "ab" && !!price) {
      price = price * quantity
    }

    if (isValidateNullText(quantity)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      price_opt: priceOpt,
      quantity: quantityConvert(quantity),
      final_amount: price,
    });

    axios
      .put("/api/realization-order-item/" + props.object.uuid + "/edit/quantity", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("api/realization-order-item/" + props.object.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Позиция на производство удалена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()} contentStyle={{width: 360}}>
        {!isDelete && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div
              className="Form__title"
              style={{fontSize: 19, fontWeight: 700}}
            >
              {object.nomenclature.name}
              <div className="Text__signature">
                №{object.realization_order.id}
                {!!object.realization_order.date && ' от ' + moment(object.realization_order.date.substring(0, 19))
                  .format("DD.MM.YYYY HH:mm")}
              </div>
            </div>

            <div className="Form__field">
              <ul
                className={styleTumbler._}
                style={{
                  justifyContent: "space-between",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <li className={styleTumbler._link}>
                  <a
                    className={
                      styleTumbler._text +
                      ((!!tumbler &&
                          tumbler === "ac" &&
                          " " + styleTumbler.active + " ") ||
                        "")
                    }
                    onClick={() => setTumbler("ac")}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Количество
                  </a>
                </li>
                <li className={styleTumbler._link}>
                  <a
                    className={
                      styleTumbler._text +
                      ((!!tumbler &&
                          tumbler === "aa" &&
                          " " + styleTumbler.active + " ") ||
                        "")
                    }
                    onClick={() => setTumbler("aa")}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Размер
                  </a>
                </li>
              </ul>
            </div>

            {!!tumbler && tumbler === "aa" && (
              <div className="Form__field">
                <Input reference={inputValue} type={'number'} label={"Размер (" +
                  (!!object.nomenclature.measurement_unit_type ? object.nomenclature.measurement_unit_type.designation : "-") + ") *"}
                       value={
                         Number((Number(object.quantity / 1000000).toFixed(6) * (!!object.nomenclature.value && !!object.nomenclature.measurement_unit_type ?
                           (object.nomenclature.value / object.nomenclature.measurement_unit_type.ratio) : !!object.nomenclature.value && object.nomenclature.value)).toFixed(3))
                       }
                />
              </div>
            )}

            {!!tumbler && tumbler === "ac" && (
              <div className="Form__field">
                <Input reference={inputQuantity} type={'number'} label="Количество *"
                       value={
                         (!!object.quantity && object.quantity / 1000000) || null
                       }/>
              </div>
            )}

            <div className="Form__field">
              <Input type={'number'} reference={inputPriceOpt} label={"Цена оптовая (за " +
                (Number(((!!object.nomenclature.value && !!object.nomenclature.measurement_unit_type ?
                  (object.nomenclature.value / object.nomenclature.measurement_unit_type.ratio) : !!object.nomenclature.value && object.nomenclature.value)).toFixed(3))) +
                " " +
                (!!object.nomenclature.measurement_unit_type ? object.nomenclature.measurement_unit_type.designation : "-")
                + ")"}
                     value={
                       (!!object.price_opt && object.price_opt / 100) || null
                     }/>
            </div>

            {!!object.nomenclature && !!object.nomenclature.price_opt && (
              <div className="Form__field">
                <div
                  className="Button__swith"
                  style={{
                    padding: "8px",
                    width: "100%",
                    border: isOpt ? "2px solid #ffe082" : "2px dashed #20202015",
                    backgroundColor: isOpt ? "#ffe082" : "#ffffff",
                    borderRadius: 6,
                    marginTop: 20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsOpt(!isOpt)}
                >
                  <div
                    className="Swith__text"
                    style={{
                      fontSize: 17,
                      fontWeight: 600,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Оптовая цена

                    <span style={{
                      marginLeft: 12,
                      fontSize: 21,
                      fontWeight: 400,
                    }}>
                {new Intl.NumberFormat("ru-RU").format(Number(
                  (object.nomenclature.price_opt / 100).toFixed(2)))}
                      <span
                        style={{
                          marginLeft: 4,
                          fontSize: 12,
                          fontWeight: 400,
                          opacity: 0.75,
                        }}
                      >
                            руб.
                          </span>

                        </span>
                  </div>
                  <div
                    className={
                      "Swith Swith_gray" +
                      ((isOpt && " Swith_active Swith_active_gray") || "")
                    }
                  ></div>
                </div>
              </div>
            )}

            <hr className={"Page__hr"} style={{marginBottom: 24}}/>

            <div className="Form__field">
              <div className="Field__text">Скидка</div>
            </div>

            <div className="Form__field">
              <ul
                className={styleTumbler._}
                style={{
                  justifyContent: "space-between",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <li className={styleTumbler._link}>
                  <a
                    className={
                      styleTumbler._text +
                      ((!!tumbler2 &&
                          tumbler2 === "aa" &&
                          " " + styleTumbler.active + " ") ||
                        "")
                    }
                    onClick={() => setTumbler2("aa")}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Разница
                  </a>
                </li>
                <li className={styleTumbler._link}>
                  <a
                    className={
                      styleTumbler._text +
                      ((!!tumbler2 &&
                          tumbler2 === "ad" &&
                          " " + styleTumbler.active + " ") ||
                        "")
                    }
                    onClick={() => setTumbler2("ad")}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Процент
                  </a>
                </li>
                <li className={styleTumbler._link}>
                  <a
                    className={
                      styleTumbler._text +
                      ((!!tumbler2 &&
                          tumbler2 === "ab" &&
                          " " + styleTumbler.active + " ") ||
                        "")
                    }
                    onClick={() => setTumbler2("ab")}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Изменённая
                  </a>
                </li>
                <li className={styleTumbler._link}>
                  <a
                    className={
                      styleTumbler._text +
                      ((!!tumbler2 &&
                          tumbler2 === "ac" &&
                          " " + styleTumbler.active + " ") ||
                        "")
                    }
                    onClick={() => setTumbler2("ac")}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Итоговая
                  </a>
                </li>
              </ul>
            </div>

            {!!tumbler2 && tumbler2 === "aa" && (
              <div className="Form__field">
                <div className="Form__field Form__field_last">
                  <Input type={'number'} reference={inputPrice} label="Разница скидки"/>
                </div>
              </div>
            )}

            {!!tumbler2 && tumbler2 === "ad" && (
              <div className="Form__field">
                <div className="Form__field Form__field_last">
                  <Input type={'number'} reference={inputPrice} label="Процент скидки, %"/>
                </div>
              </div>
            )}

            {!!tumbler2 && tumbler2 === "ab" && (
              <div className="Form__field">
                <div className="Form__field Form__field_last">
                  <Input type={'number'} reference={inputPrice} label="Другая цена"/>
                </div>
              </div>
            )}


            {!!tumbler2 && tumbler2 === "ac" && (
              <div className="Form__field">
                <div className="Form__field Form__field_last">
                  <Input type={'number'} reference={inputPrice} label="Сумма со скидкой"/>
                </div>
              </div>
            )}


            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>
            <div className="Form__actions Form__actions_center">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>
            </div>
          </form>
        )}
        {isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div
              className="Form__title"
              style={{fontSize: 19, fontWeight: 700}}
            >
              {object.nomenclature.name}
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Удалить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsDelete(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalOrderItemEdit3;
