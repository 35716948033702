import React, {useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../../config/url";
import {getErrorMessageByKey, Modal, useList, useNotification, useObject,} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";
import ModalOrderItemSelect from "./ModalOrderItemSelect";

export const ModalAddOrderItem2 = (props) => {
  const [selectItem, setSelectItem] = useState(null)
  const [isModalSelectItem, setIsModalSelectItem] = useState(false)

  const {notification} = useNotification();

  const {
    list: [list],
  } = useList({
    apiUrl: "api/nomenclatures/list",
    rootUrl: ROOT_URL,
  });

  const {
    object: [object],
  } = useObject({
    apiUrl: "api/production-order/" + props.object.uuid,
    rootUrl: ROOT_URL,
  });

  const changeAdd = (event) => {
    event.preventDefault();

    if (!selectItem) {
      notification("Укажите номенклатуру", "red");
      return;
    }

    const jsonData = JSON.stringify({
      nomenclature: {
        uuid: selectItem.uuid,
      },
    });

    axios
      .post("/api/production-order/" + props.object.uuid + "/item/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Позиция добавлена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!list &&
    !!object && (
      <>
        <Modal handleClose={() => props.handleClose()}>
          <form className="Form" onSubmit={(event) => changeAdd(event)}>
            <div
              className="Form__title"
              style={{fontSize: 19, fontWeight: 700}}
            >
              №{object.id}{!!object.date && ' от ' + moment(object.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}
              <div className="Text__signature">Добавить позицию на производство</div>
            </div>

            {!selectItem && (
              <div className="Form__field Form__field_last"
                   style={{
                     cursor: "pointer"
                   }}
                   onClick={() => setIsModalSelectItem(true)}>
                <div className="Field__label">
                  Номенклатура
                </div>
                <div className="Field__text">
                  -
                </div>
              </div>
            )}

            {!!selectItem && (
              <div className="Form__field Form__field_last"
                   style={{
                     cursor: "pointer"
                   }}
                   onClick={() => setIsModalSelectItem(true)}>
                <div className="Field__label">
                  Номенклатура
                </div>
                <div className="Field__text">
                  {selectItem.id + ". " + selectItem.name + (!!selectItem.article ? " - " + selectItem.article : "")}
                </div>
              </div>
            )}

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Добавить
              </button>
            </div>
          </form>
        </Modal>

        {isModalSelectItem && (
          <ModalOrderItemSelect
            item={selectItem}
            successfulRequest={item =>
              setSelectItem(item)
            }
            handleClose={() => setIsModalSelectItem(false)}
          />
        )}
      </>
    )
  );
};
