import React, {useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {
  getErrorMessageByKey,
  Input,
  isValidateNullText,
  jsonFormatText, Loading,
  Modal,
  useNotification,
} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";


function ModalPlanWarehouseUpload(props) {
  const [isRequest, setIsRequest] = useState(false);

  const inputMonth = useRef(null);

  const {notification} = useNotification();

  const changeEdit = () => {
    if (isRequest) {
      return;
    }

    const month = jsonFormatText(inputMonth.current.value);

    if (isValidateNullText(month)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const selectMonth = month.split("-")[1];
    const selectYear = month.split("-")[0];

    setIsRequest(true);

    axios
      .get("/api/plan/warehouse" +
        "/month/" +
        selectMonth +
        "/year/" +
        selectYear, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(response => {
        props.setSelectMonthYear(month);
        props.successfulRequest(response.data);
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      })
      .finally(() => {
        setIsRequest(false);
      });
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <div className="Form">
        <div
          className="Form__title"
          style={{fontSize: 19, fontWeight: 700}}
        >
          План на сырьё
          <div className="Text__signature">Укажите параметры для отчёта</div>
        </div>

        <div className="Form__field">
          <Input reference={inputMonth} type={'month'} label="Месяц и год *" isFixed={true} value={(!!props.monthYear && props.monthYear) || moment().format("YYYY-MM")}/>
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue"
                  onClick={() => changeEdit()}>
            {!isRequest
              ? "Сформировать"
              : <Loading size="medium" color="blue" />}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalPlanWarehouseUpload;
