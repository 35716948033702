import React, {useState} from "react";
import { ScrollBlock } from "../../../components/ScrollBlock/ScrollBlock";
import styleTumbler from "../../../styles/modules/Tumbler.module.scss";
import {Navigate, NavLink, Route, Routes} from "react-router-dom";
import NomenclatureSpecifications from "./components/NomenclatureSpecifications/NomenclatureSpecifications";
import {ButtonIcon} from "@vokymlak/kabinet-ui";
import ModalNomenclatureEdit from "./ModalNomenclatureEdit";
import NomenclatureTransactions from "./components/NomenclatureTransactions/NomenclatureTransactions";

function NomenclaturePage(props) {
  const { object, setIsUpdateObject, isUpdateObject } = props;
  const [modalEdit, setModalEdit] = useState(null)

  return (
    !!object && (
      <ScrollBlock style={{ width: "100%", height: "100%" }}>
        <div className="Page">
          <div
            className="Page__title"
            style={{
              marginBottom: 12,
            }}
          >
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                flexWrap: "nowrap",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  fontSize: 19,
                  fontWeight: 700,
                }}
              >
                <div>
                  {!!object && !!object.name && (
                    <span>
                    {object.name} {!!object.article && "(" + object.article + ")"}
                  </span>
                  )}
                  {!!object && !object.name && !!object.article && (
                    <span>
                    {object.article}
                  </span>
                  )}
                </div>
                <span
                  style={{
                    fontSize: 11,
                    maxWidth: 240,
                    fontWeight: 500,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    marginTop: 4,
                    textOverflow: "ellipsis",
                  }}
                >
              Подробнее
                </span>
              </div>

              <div style={{
                padding: "4px 8px",
                backgroundColor: ((!!object.quantity && (object.quantity > 0 ? "#2e7d3233" : "#bf360c33")) || "#20202010"),
                borderRadius: 4,
                marginLeft: 12,
                textAlign: "right"
              }}>
                <div style={{
                  fontSize: 21,
                  fontWeight: 700,
                }}>
                  {!object.is_pcs && (
                    <>
                      {(!!object.quantity && !!object.measurement_unit_type ? (object.quantity / object.measurement_unit_type.ratio).toFixed(3) : !!object.quantity && object.quantity.toFixed(3)) || "0"}
                      {!!object.measurement_unit_type && <span style={{
                        marginLeft: 4,
                        fontWeight: 400,
                        fontSize: 13
                      }}>{object.measurement_unit_type.designation + '.'}</span>}
                    </>
                  )}

                  {object.is_pcs && (
                    <>
                      {(!!object.quantity && (object.quantity)) || "0"}
                    </>
                  )}
                </div>
                <div style={{
                  fontSize: 12,
                  fontWeight: 600,
                }}>
                  кол.
                </div>
              </div>

              {!!object &&
                object.is_without_volume && (
                  <div
                    style={{
                      backgroundColor: "#202020",
                      padding: "0 6px",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 24,
                      marginLeft: 12,
                    }}
                  >
                    <div
                      style={{
                        padding: "4px 4px",
                        color: "white",
                        fontSize: 14,
                        pointerEvents: "auto",
                        fontWeight: 700,
                        cursor: "default"
                      }}
                    >
                      Без объёма
                    </div>
                  </div>
                )}

              <ButtonIcon
                tooltipText={"Редактировать"}
                iconName={"custom-edit"}
                iconColor={"#202020"}
                size={36}
                style={{
                  marginLeft: 16,
                  zIndex: 9,
                }}
                onClick={() => setModalEdit(object)}
              />
            </div>


            <div className="Title__settings">
              <ul className={styleTumbler._}>
                {/*<li className={styleTumbler._link}>*/}
                {/*  <NavLink*/}
                {/*    className={({ isActive }) =>*/}
                {/*      isActive*/}
                {/*        ? [styleTumbler._text, styleTumbler.active].join(" ")*/}
                {/*        : styleTumbler._text*/}
                {/*    }*/}
                {/*    to={"./info"}*/}
                {/*    style={{*/}
                {/*      position: "relative",*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    Данные*/}
                {/*  </NavLink>*/}
                {/*</li>*/}
                <li className={styleTumbler._link}>
                  <NavLink
                    className={({isActive}) =>
                      isActive
                        ? [styleTumbler._text, styleTumbler.active].join(" ")
                        : styleTumbler._text
                    }
                    to={"./specifications"}
                    style={{
                      position: "relative",
                    }}
                  >
                    Спецификации
                  </NavLink>
                </li>
                <li className={styleTumbler._link}>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? [styleTumbler._text, styleTumbler.active].join(" ")
                        : styleTumbler._text
                    }
                    to={"./transactions"}
                    style={{
                      position: "relative",
                    }}
                  >
                    Учёт
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>

          <hr className={"Page__hr"} style={{marginBottom: 6}}/>

          <Routes>
            {/*<Route*/}
            {/*  path="/info"*/}
            {/*  element={<NomenclatureInfo object={object} />}*/}
            {/*/>*/}
            <Route
              path="/specifications"
              element={<NomenclatureSpecifications object={object} />}
            />

            <Route
              path="/transactions"
              element={<NomenclatureTransactions object={object} />}
            />

            <Route exact path="/" element={<Navigate to="./specifications" />} />
          </Routes>

          {!!modalEdit && (
            <ModalNomenclatureEdit
              object={modalEdit}
              successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
              handleClose={() => setModalEdit(null)}
            />
          )}
        </div>
      </ScrollBlock>
    )
  );
}

export default NomenclaturePage;
