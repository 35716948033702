import React, {Fragment, useState} from "react";
import {ButtonIcon, Icon} from "@vokymlak/kabinet-ui";
import styleContent from "../../../../styles/modules/Block.module.scss";

function BoningOrderItemList(props) {
  const {
    object,
    setModalOrderItemEdit,
    setModalAddBoning,
    setModalItemTransaction,
    setModalItemTransactionRollback,
  } = props;

  const [selectComposition, setSelectComposition] = useState(null);

  const isSelectComposition = (composition) => {
    return !!selectComposition && selectComposition.uuid === composition.uuid;
  };

  const changeSelectComposition = (composition) => {
    if (!!selectComposition && selectComposition.uuid === composition.uuid) {
      setSelectComposition(null);
    } else {
      setSelectComposition(composition);
    }
  };

  function getCount(item, composition) {
    let value = null

    if (!!item.counts) {
      item.counts.forEach(r => {
        if (r.nomenclature_id === composition.nomenclature_id) {
          value = r
        }
      })
    }

    return value
  }

  return (
    !!object && (
      <Fragment>
        <div className={styleContent._block} style={{marginTop: 12}}>
          <div className={styleContent._block__title}>Обвалка</div>
          <div className={styleContent._block__content}>
            <table className="Table">
              <tbody>
              {object.items &&
                object.items.map((item, index) => (
                  <Fragment key={"acceptance_" + item.uuid}>
                    <tr
                      className={
                        isSelectComposition(item)
                          ? "Table__tr Table__tr_hover Table__tr_focus"
                          : "Table__tr Table__tr_hover"
                      }
                      style={{
                        borderStyle: "2px solid",
                        borderColor: "#bdbdbd",
                        borderBottomColor: isSelectComposition(item)
                          ? "#202020"
                          : "#bdbdbd",
                      }}
                    >
                      <td className="Table__td">
                        <div className="Td__title Td__title_flex">
                          {index + 1} # {item.nomenclature.name}
                          {!!item.nomenclature.price && (
                            <span
                              style={{
                                marginLeft: 12,
                              }}
                            >
                            |&nbsp;&nbsp;
                              {!!item.nomenclature && !!item.nomenclature.value &&
                                new Intl.NumberFormat("ru-RU").format(Number(!!item.nomenclature.measurement_unit_type ?
                                  (item.nomenclature.value / item.nomenclature.measurement_unit_type.ratio) : item.nomenclature.value).toFixed(3))}
                              <span
                                style={{
                                  marginLeft: 2,
                                  fontSize: 12,
                                  fontWeight: 400,
                                  opacity: 0.75,
                                }}
                              >
                              {!!item.nomenclature.measurement_unit_type && item.nomenclature.measurement_unit_type.designation + "."}
                            </span>
                              &nbsp;&nbsp;
                              {(Number(item.nomenclature.price) / 100).toFixed(2)}
                              <span
                                style={{
                                  marginLeft: 2,
                                  fontSize: 12,
                                  fontWeight: 400,
                                  opacity: 0.75,
                                }}
                              >
                              руб.
                            </span>
                          </span>
                          )}

                          {!!item.specification && (
                            <div
                              className="Button__circle"
                              onClick={() => changeSelectComposition(item)}
                              onContextMenu={() => changeSelectComposition(item)}
                              style={{
                                padding:
                                  !!selectComposition &&
                                  item.uuid === selectComposition.uuid
                                    ? "3px 4px 5px 4px"
                                    : "5px 4px 3px 4px",
                              }}
                            >
                              {!!selectComposition &&
                                item.uuid === selectComposition.uuid && (
                                  <Icon
                                    name={"custom-expand-less"}
                                    color={"#202020"}
                                    size={24}
                                  />
                                )}
                              {(!selectComposition ||
                                (!!selectComposition &&
                                  item.uuid !== selectComposition.uuid)) && (
                                <Icon
                                  name={"custom-expand-more"}
                                  color={"#202020"}
                                  size={24}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="Table__td">
                        <div className="Td__title">
                        <span style={{
                          fontWeight: 700,
                          fontSize: 24
                        }}>
                          {(!!item.quantity && new Intl.NumberFormat("ru-RU").format(Number(item.quantity / 1000).toFixed(3))) || "-"}
                          <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>
                                      кол-во</span>
                                    </span>
                        </div>
                      </td>
                      <td className="Table__td">
                        <div className="Td__title">
                                    <span style={{
                                      fontWeight: 700,
                                      fontSize: 24
                                    }}>
                                      {(!!item.value && new Intl.NumberFormat("ru-RU").format(Number(item.value / 1000000).toFixed(6))) || "-"}
                                      <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>
                                      кг.</span>
                                    </span>
                        </div>
                      </td>
                      <td className="Table__td">
                        <div className="Td__title">
                                    <span style={{
                                      fontWeight: 700,
                                      fontSize: 24
                                    }}>

                                      {(!!item.amount && new Intl.NumberFormat("ru-RU").format(Number(
                                        (item.amount / 100).toFixed(2)))) || "-"}
                                      <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб.</span></span>
                        </div>
                      </td>
                      <td className="Table__td"
                          style={{
                            width: 36,
                          }}>
                        <div
                          style={{
                            height: 36,
                            display: "flex",
                          }}
                        >
                          {!item.is_transaction && (
                            <ButtonIcon
                              tooltipText={"Редактировать"}
                              iconName={"custom-edit"}
                              iconColor={"#202020"}
                              size={36}
                              onClick={() =>
                                setModalOrderItemEdit(item)
                              }
                            />
                          )}
                        </div>
                      </td>

                      <td className="Table__td"
                          style={{
                            width: 36,
                          }}>
                        <div
                          style={{
                            height: 28,
                            display: "flex",
                          }}
                        >
                          {item.is_transaction && (
                            <div
                              className={
                                "Button__collected"
                              }
                              style={{
                                height: 28,
                                padding: "3px 8px 4px",
                                display: "flex"
                              }}
                            >
                              Учтено
                              <ButtonIcon
                                style={{marginLeft: 12}}
                                tooltipText={"Отменить учёт"}
                                iconName={"edit-undo"}
                                iconColor={"#202020"}
                                size={28}
                                onClick={() =>
                                  setModalItemTransactionRollback(item)
                                }
                              />
                            </div>
                          )}
                          {!item.is_transaction && (
                            <div
                              className={
                                "Button__collected Button__collected_blue"
                              }
                              style={{
                                height: 28,
                                padding: "3px 8px 4px",
                                cursor: "pointer"
                              }}
                              onClick={() =>
                                setModalItemTransaction(item)
                              }
                            >
                              Учесть
                            </div>
                          )}
                        </div>

                      </td>
                    </tr>
                    {!!item.specification &&
                      !!selectComposition && item.uuid === selectComposition.uuid && (
                        <tr
                          className={
                            isSelectComposition(item)
                              ? "Table__tr Table__tr_focus_light"
                              : "Table__tr"
                          }
                          style={{
                            borderStyle: "2px solid",
                            borderColor: isSelectComposition(item)
                              ? "#202020"
                              : "#bdbdbd",
                          }}
                        >
                          <td
                            className="Table__td"
                            colSpan={6}
                            style={{
                              padding: "0 0 0 32px",
                            }}
                          >

                            <table className="Table">
                              <thead>
                              <tr
                                className="Table__tr"
                                style={{
                                  backgroundColor: "#c5c5c5",
                                  borderStyle: "2px solid",
                                  borderColor: "#bdbdbd",
                                }}
                              >
                              </tr>
                              </thead>
                              <tbody>
                              {!!item.specification.compositions &&
                                item.specification.compositions.map((composition, compositionIndex) => (
                                  <React.Fragment key={"composition_" + composition.uuid}>
                                    <tr
                                      className={"Table__tr"}
                                    >
                                      <td
                                        className="Table__td"
                                        style={{
                                          width: "100%",
                                        }}
                                      >
                                        <div className="Td__title">
                                          {compositionIndex + 1} # {composition.nomenclature.name}
                                        </div>
                                      </td>
                                      <td
                                        className="Table__td "
                                        style={{
                                          textAlign: "right",
                                          fontSize: 20,
                                          fontWeight: 700,
                                        }}
                                      >
                                        {!!getCount(item, composition) ? !!composition.measurement_unit_type
                                          ? new Intl.NumberFormat("ru-RU").format((getCount(item, composition).value / composition.measurement_unit_type.ratio).toFixed(3))
                                          : !!getCount(item, composition).value && getCount(item, composition).value : "-"}
                                      </td>
                                      <td
                                        className="Table__td Table__td_all-border"
                                        style={{
                                          textAlign: "center",
                                          fontSize: 14,
                                          fontWeight: 600,
                                        }}
                                      >
                                        {!!composition.measurement_unit_type ? composition.measurement_unit_type.designation :
                                          !!composition.measurement_type
                                          && composition.measurement_type
                                            .designation}
                                      </td>

                                      {!item.is_transaction && (
                                        <td
                                          className="Table__td Table__td_all-border">
                                          {!item.is_transaction && (
                                            <ButtonIcon
                                              tooltipText={"Редактировать"}
                                              iconName={"custom-edit"}
                                              iconColor={"#202020"}
                                              size={36}
                                              onClick={() => setModalAddBoning({
                                                order: object,
                                                item: item,
                                                composition: composition,
                                                count: getCount(item, composition)
                                              })}
                                            />
                                          )}
                                        </td>
                                      )}
                                    </tr>
                                  </React.Fragment>
                                ))}
                              </tbody>
                            </table>

                          </td>
                        </tr>
                      )}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    )
  );
}

export default BoningOrderItemList;
