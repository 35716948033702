import React, {useRef} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {getErrorMessageByKey, Input, jsonFormatText, Modal, Textarea, useNotification} from "@vokymlak/kabinet-ui";

function ModalContractorAdd(props) {
  const inputShortName = useRef(null);
  const inputName = useRef(null);
  const inputOgrn = useRef(null);
  const inputInn = useRef(null);
  const inputKpp = useRef(null);
  const inputBank = useRef(null);
  const inputInvoiceNumber = useRef(null);

  const {notification} = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const shortName = jsonFormatText(inputShortName.current.value);
    const name = jsonFormatText(inputName.current.value);
    const ogrn = jsonFormatText(inputOgrn.current.value);
    const inn = jsonFormatText(inputInn.current.value);
    const kpp = jsonFormatText(inputKpp.current.value);
    const bank = jsonFormatText(inputBank.current.value);
    const invoiceNumber = jsonFormatText(inputInvoiceNumber.current.value);

    const jsonData = JSON.stringify({
      short_name: shortName,
      name: name,
      ogrn: ogrn,
      inn: inn,
      kpp: kpp,
      bank: bank,
      invoice_number: invoiceNumber,
    });

    axios
      .post("/api/contractor/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Контрагент добавлен", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    <>
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div className="Form__title">
            Новый
            <div className="Text__signature">Контрагент</div>
          </div>

          <div className="Form__field">
            <Textarea reference={inputShortName} label="Краткое наименование"/>
          </div>

          <div className="Form__field">
            <Textarea reference={inputName} label="Полное наименование"/>
          </div>

          <div className="Form__field">
            <Input reference={inputOgrn} label="ОГРН"/>
          </div>

          <div className="Form__field">
            <Input reference={inputInn} label="ИНН"/>
          </div>

          <div className="Form__field">
            <Input reference={inputKpp} label="КПП"/>
          </div>

          <div className="Form__field">
            <Textarea reference={inputBank} label="Банк"/>
          </div>

          <div className="Form__field Form__field_last">
            <Input reference={inputInvoiceNumber} label="Номер счёта"/>
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Добавить
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default ModalContractorAdd;
