import React, { Fragment } from "react";
import { ModalAddAccount } from "./ModalAddAccount";
import { ModalEditAccount } from "./ModalEditAccount";
import { ModalAddAccountRoleType } from "./ModalAddAccountRoleType";
import { ScrollBlock } from "../../../components/ScrollBlock/ScrollBlock";
import { Avatar } from "../../../components/Avatar/Avatar";
import {
  ButtonIcon,
  Loading,
  Pagination,
  Search,
  Select,
  getAccountFio,
  TextSearchMarker,
  Marker,
} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";
import {ROOT_URL} from "../../../config/url";

export const AccountsPage = (props) => {
  const {
    profile,

    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,

    isActivatedAccount,
    deleteAccountRoleType,

    modalEditAccount,
    setModalEditAccount,
    isModalAddAccount,
    setIsModalAddAccount,
    modalAddAccountRoleType,
    setModalAddAccountRoleType,
  } = props;

  function isMD5Hash(str) {
    // Проверяем, соответствует ли строка формату MD5 хэша.
    const md5Regex = /^[a-f0-9]{32}$/i;
    return md5Regex.test(str);
  }


  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Аккаунты
            {!!list && (
              <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                ({count})
              </span>
            )}
            <span style={{ width: 220, marginLeft: 16 }}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{ marginLeft: 8 }}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 8 }}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 100,
                      title: 100,
                    },
                    {
                      value: 1000,
                      title: 1000,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{ marginLeft: 8 }}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{ marginLeft: 12 }}
              onClick={() => setIsModalAddAccount(true)}
            />
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading />
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {list.map((item) => (
                <Fragment key={"item_" + item.uuid}>
                  <li
                    className={
                      isUnroll(item.uuid) ? "List__item active" : "List__item"
                    }
                    onClick={() => changeUnroll(item.uuid)}
                    style={{ paddingLeft: 8 }}
                  >
                    <div className="Item__title">
                      <div className="Element__title">
                        {!!item.avatar && isMD5Hash(item.avatar) && (
                          <img height={36} width={36} style={{
                            borderRadius: 36,
                          }}  src={ROOT_URL + "/api/account/avatar/" + item.avatar}/>
                        )}
                        {!!item.avatar && !isMD5Hash(item.avatar) && (
                          <img height={36} width={36} style={{
                            borderRadius: 36,
                          }}  src={"data:image/jpeg;base64, "+ item.avatar}/>
                        )}
                        {!item.avatar && (
                          <Avatar size={36} value={getAccountFio(item)} />
                        )}

                        <div style={{ marginLeft: 8 }}>
                          <TextSearchMarker
                            text={getAccountFio(item)}
                            search={searchQuery}
                          />
                          <div
                            style={{
                              fontSize: 12,
                              opacity: 0.5,
                            }}
                          >
                            <TextSearchMarker
                              text={"@" + item.login}
                              search={searchQuery}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Item__settings">
                      <div className="Element__datetime">
                        {!!item.last_action_in &&
                          moment(item.last_action_in.substring(0, 19)).format(
                            "HH:mm DD.MM.YYYY"
                          )}
                        <Marker
                          size={10}
                          style={{
                            marginLeft: 8,
                            marginRight: 4,
                          }}
                          color={
                            !!item.last_action_in
                              ? moment()
                                  .subtract(5, "minutes")
                                  .isBefore(
                                    moment(item.last_action_in.substring(0, 19))
                                  )
                                ? "#4caf50"
                                : "#9e9e9e"
                              : "#9e9e9e"
                          }
                        />
                      </div>
                    </div>
                  </li>
                  {isUnroll(item.uuid) && (
                    <li className="List__content">
                      {profile.uuid !== item.uuid && (
                        <div className="Content__header">
                          <div className="Header__title">
                            <div
                              className="Button__text Button__text_right"
                              onClick={() => setModalAddAccountRoleType(item)}
                            >
                              Добавить роль
                            </div>
                          </div>
                          <div className="Header__settings">
                            <div className="Button__swith">
                              <div className="Swith__text">Доступ к работе</div>
                              <div
                                className={
                                  "Swith" +
                                  ((!!item.is_activated && " Swith_active ") ||
                                    "")
                                }
                                onClick={() => isActivatedAccount(item)}
                              ></div>
                            </div>
                            <ButtonIcon
                              tooltipText={"Редактировать"}
                              iconName={"custom-edit"}
                              iconColor={"#202020"}
                              size={36}
                              style={{ marginLeft: 12 }}
                              onClick={() => setModalEditAccount(item)}
                            />
                          </div>
                        </div>
                      )}
                      <div>
                        <table className="Table">
                          <tbody>
                            {item.account_roles &&
                              item.account_roles.map((role, index) => (
                                <tr
                                  className="Table__tr"
                                  key={
                                    item.uuid + ":" + role.uuid + ":" + index
                                  }
                                >
                                  <td className="Table__td">
                                    <div className="Td__title">
                                      {role.role_type.name}
                                    </div>
                                  </td>
                                  <td className="Table__td Table__td_child">
                                    {profile.uuid !== item.uuid && (
                                      <div
                                        style={{
                                          height: 32,
                                          display: "flex",
                                          marginLeft: 12,
                                        }}
                                      >
                                        <ButtonIcon
                                          tooltipText={"Удалить"}
                                          iconName={"custom-delete"}
                                          iconColor={"#0064ff"}
                                          size={32}
                                          onClick={() =>
                                            deleteAccountRoleType(role.uuid)
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </li>
                  )}
                </Fragment>
              ))}
            </ul>
          </div>
        )}

        {!!modalEditAccount && (
          <ModalEditAccount
            account={modalEditAccount}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalEditAccount(null)}
          />
        )}
        {!!isModalAddAccount && (
          <ModalAddAccount
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalAddAccount(false)}
          />
        )}
        {!!modalAddAccountRoleType && (
          <ModalAddAccountRoleType
            account={modalAddAccountRoleType}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalAddAccountRoleType(null)}
          />
        )}
      </div>
    </ScrollBlock>
  );
};
