import React, {useState} from "react";
import styleContent from "../../../../../../styles/modules/Block.module.scss";
import ModalItemEdit from "./ModalItemEdit";

function ItemsList(props) {
  const {specification, object, setIsUpdateList, isUpdateList} = props

  const [modalItemEdit, setModalItemEdit] = useState(null)

  function getAmountComposition(composition) {
    if (!!composition && !!composition.nomenclature && !!composition.nomenclature.price) {
      const amount =  composition.value / (composition.nomenclature.value / composition.nomenclature.price)
      return (amount / 100).toFixed(3);
    }
    return 0;
  }

  return (
    <>
      {!!specification && !!specification.compositions && !!specification.compositions.length > 0 && (
        <>
          <div
            className={styleContent._block + " " + styleContent._block_bottom}
          >
            <div className={styleContent._block__title}>Позиции</div>
            <div className={styleContent._block__content}>
              <table className="Table">
                <tbody>
                  {specification.compositions.map((composition, compositionIndex) => (
                    <tr
                      className="Table__tr Table__tr_hover"
                      key={props.specification.uuid + ":" + composition.uuid}
                      onClick={!specification.code ? () => setModalItemEdit(composition) : () => null}
                      style={{
                        cursor: !specification.code ? "pointer" : "default",

                        borderStyle: "2px solid",
                        borderColor: "#bdbdbd"
                    }}
                    >
                        <td
                          className="Table__td"
                          style={{
                            width: "100%",
                          }}
                        >
                          <div className="Td__title Td__title_flex">
                            {compositionIndex + 1} #{" "}
                            {composition.nomenclature.name}
                            {composition.nomenclature
                              .specification_count > 0 && (
                              <div
                                className="Marker Marker_left Marker_inline Marker_small"
                              />
                            )}
                          </div>
                        </td>
                      <td
                        className="Table__td"
                        style={{
                          textAlign: "right",
                          fontSize: 20,
                          fontWeight: 700,
                          opacity: .33
                        }}
                      >
                        {(!!composition.quantity && getAmountComposition(composition)) || 0}
                        <span style={{
                          textAlign: "center",
                          fontSize: 14,
                          fontWeight: 600,
                          marginLeft: 6
                        }}>
                          руб
                        </span>
                      </td>
                        <td
                          className="Table__td"
                          style={{
                            textAlign: "right",
                            fontSize: 20,
                            fontWeight: 700,
                          }}
                        >
                          {(!!composition.quantity && composition.quantity) || "-"}
                        </td>
                        <td
                          className="Table__td Table__td_all-border"
                          style={{
                            textAlign: "center",
                            fontSize: 14,
                            fontWeight: 600,
                          }}
                        >
                          {/*{!!composition.measurement_type && composition.measurement_type.designation}*/}
                          {!!composition.measurement_unit_type ? composition.measurement_unit_type.designation :
                            (!!composition.measurement_type
                              && composition.measurement_type
                                .designation)}
                        </td>
                      </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {!!modalItemEdit && (
            <ModalItemEdit
              object={specification}
              item={modalItemEdit}
              successfulRequest={() => setIsUpdateList(!isUpdateList)}
              handleClose={() => setModalItemEdit(null)}
            />
          )}
        </>
      )}
    </>
  );
}

export default ItemsList;
