import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../config/url";
import {
  getErrorMessageByKey,
  Textarea,
  useNotification,
  Modal,
  useObject,
  jsonFormatText,
  isValidateNullText, Input,
} from "@vokymlak/kabinet-ui";
import moment from "moment";

function ModalBoningOrderEdit(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "api/boning-order/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputDate = useRef(null);

  const [isDelete, setIsDelete] = useState(false);

  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const date = jsonFormatText(inputDate.current.value);

    if (isValidateNullText(date)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      date: date,
    });

    axios
      .put("/api/boning-order/" + props.object.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("api/boning-order/" + props.object.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Наряд удалён", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
        {!isDelete && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div
              className="Form__title"
              style={{ fontSize: 19, fontWeight: 700 }}
            >
              №{object.id}{!!object.date && ' от ' + moment(object.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}
              <div className="Text__signature">Обвалка</div>
            </div>

            <div className="Form__field">
              <Input reference={inputDate} type={'datetime-local'} isFixed={true} label="Дата заказа *"
                     value={
                       !!object.date
                         ? moment(object.date.substring(0, 19)).format(
                           "YYYY-MM-DD HH:mm"
                         )
                         : null
                     } />
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>
            </div>
          </form>
        )}

        {isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div
              className="Form__title"
              style={{ fontSize: 19, fontWeight: 700 }}
            >
              №{object.id}{!!object.date && ' от ' + moment(object.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Удалить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsDelete(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalBoningOrderEdit;
