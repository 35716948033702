import React, {useRef, useState} from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
  useObject,
  Textarea,
  Input,
  jsonFormatText, Select, isValidateNullText,
} from "@vokymlak/kabinet-ui";

function ModalSpecificationAdd(props) {
  const {
    object: [nomenclature],
  } = useObject({
    apiUrl: "/api/nomenclature/" + props.nomenclature.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputName = useRef(null);
  const inputDescription = useRef(null);

  const selectMeasurementUnitType = useRef(null);
  const inputValue = useRef(null);
  const inputPrice = useRef(null);

  const { notification } = useNotification();

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0 && numberString !== "null"
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const numberConvertV = (numberString) => {
    return !!numberString && numberString.length > 0 && numberString !== "null"
      ? Number(String(Number(numberString).toFixed(3)))
      : null;
  };

  const changeAdd = (event) => {
    event.preventDefault();

    const name = jsonFormatText(inputName.current.value);
    const description = jsonFormatText(inputDescription.current.value);
    let measurementUnitType = selectMeasurementUnitType.current.value;
    measurementUnitType = measurementUnitType.length > 0
      ? measurementUnitType === "null"
        ? null
        : measurementUnitType
      : null
    const value = inputValue.current.value;
    // const price = inputPrice.current.value;

    if (isValidateNullText(name)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      name: name,
      description: description,
      measurement_unit_type: !!measurementUnitType ? {
        tag: measurementUnitType,
      } : null,
      value: numberConvertV(value),
      // price: numberConvert(price),
    });

    axios
      .post("/api/nomenclature/" + props.nomenclature.uuid + "/specification/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Спецификация добавлена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!nomenclature && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div className="Form__title">
            Новая
            <div className="Text__signature">Спецификация</div>
          </div>

          <div className="Form__field">
            <Textarea reference={inputName} label="Название *" />
          </div>

          <div className="Form__field ">
            <Textarea reference={inputDescription} label="Описание" />
          </div>

          <div className="Form__field">
            <Select
              isFixed={true}
              reference={selectMeasurementUnitType}
              options={[
                {
                  value: "null",
                  title: "Не выбрано",
                },
                {
                  value: "MG",
                  title: "Миллиграммы",
                },
                {
                  value: "G",
                  title: "Граммы",
                },
                {
                  value: "KG",
                  title: "Килограммы",
                },
                {
                  value: "ML",
                  title: "Миллилитры",
                },
                {
                  value: "L",
                  title: "Литры",
                },
                {
                  value: "PCS",
                  title: "Штуки",
                },
                {
                  value: "MM",
                  title: "Миллиметры",
                },
                {
                  value: "M",
                  title: "Метры",
                }]}
              label="Единица измерения"
            />
          </div>

          <div className="Form__field">
            <Input type={'number'} reference={inputValue} label="Размер" />
          </div>

          {/*<div className="Form__field Form__field_last">*/}
          {/*  <Input type={'number'} reference={inputPrice} label="Цена" />*/}
          {/*</div>*/}

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Добавить
            </button>
          </div>

        </form>
      </Modal>
    )
  );
}

export default ModalSpecificationAdd;
