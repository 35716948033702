import React, { useState, useEffect } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../config/url";
import {useAction} from "@vokymlak/kabinet-ui";
import {useUnroll} from "@vokymlak/kabinet-ui";
import Specification from "./Specification";

function NomenclatureSpecifications(props) {
  const [nomenclature, setNomenclature] = useState(null);
  const [specification, setSpecification] = useState(null);

  const [isUpdate, setIsUpdate] = useState(false);

  useAction("isUpdate", () => setIsUpdate(!isUpdate));

  useEffect(() => {
    return () => {
      setNomenclature(null);
    };
  }, []);

  useEffect(() => {
    axios.get("/api/nomenclature/" + props.nomenclature.uuid,{
      withCredentials: false,
      baseURL: ROOT_URL,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("tokenSession"),
      },
    }).then(
      (response) => {
        setNomenclature(response.data);
      }
    );
  }, [isUpdate, props.nomenclatureId]);

  useEffect(() => {
    if (!!props.specification) {
      axios.get("/api/specification/" + props.specification.uuid, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      }).then(
        (response) => {
          setSpecification(response.data);
        }
      );
    } else {
      setSpecification(null);
    }
  }, [isUpdate, props.specification]);

  return (
    <>
      {!!nomenclature && !!specification && (
        <React.Fragment>
          <Specification
            specification={specification}
            quantity={props.quantity}
            count={props.count}
            isFirst={props.isFirst}
          />
        </React.Fragment>
      )}

      {!!nomenclature && !specification && !!nomenclature.specifications &&
    nomenclature.specifications.map((specification) => (
      <React.Fragment key={"specification_" + specification.uuid}>
        <Specification
          specification={specification}
          quantity={props.quantity}
          count={props.count}
          isFirst={props.isFirst}
          />
        {/*<table className="Table">*/}
        {/*  <thead>*/}
        {/*    <tr*/}
        {/*      className="Table__tr"*/}
        {/*      style={{*/}
        {/*        backgroundColor: "#c5c5c5",*/}
        {/*        border: "2px solid #202020",*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <th className="Table__td">*/}
        {/*        <div className="Td__title">{specification.name}</div>*/}
        {/*      </th>*/}
        {/*      <th*/}
        {/*        className="Table__td"*/}
        {/*        style={{*/}
        {/*          textAlign: "right",*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        x1*/}
        {/*      </th>*/}
        {/*      <th*/}
        {/*        className="Table__td"*/}
        {/*        style={{*/}
        {/*          textAlign: "right",*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        x{props.quantity}*/}
        {/*      </th>*/}
        {/*      {!!props.count && !props.isFirst && (*/}
        {/*        <th*/}
        {/*          className="Table__td"*/}
        {/*          style={{*/}
        {/*            textAlign: "right",*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          x{props.quantity}*{props.count}*/}
        {/*        </th>*/}
        {/*      )}*/}
        {/*      <th*/}
        {/*        className="Table__td"*/}
        {/*        style={{*/}
        {/*          textAlign: "right",*/}
        {/*        }}*/}
        {/*      ></th>*/}
        {/*    </tr>*/}
        {/*  </thead>*/}
        {/*  <tbody>*/}
        {/*    {specification.compositions &&*/}
        {/*      specification.compositions.map((composition, compositionIndex) => (*/}
        {/*        <React.Fragment key={"composition_" + composition.uuid}>*/}
        {/*          <tr*/}
        {/*            className={"Table__tr"}*/}
        {/*            style={{*/}
        {/*              backgroundColor: isUnroll(composition.uuid)*/}
        {/*                ? "#c8e6c9"*/}
        {/*                : "inherit",*/}
        {/*              borderStyle: "2px solid",*/}
        {/*              borderLeftColor: isUnroll(composition.uuid)*/}
        {/*                ? "#81c784"*/}
        {/*                : "#bdbdbd",*/}
        {/*              borderRightColor: isUnroll(composition.uuid)*/}
        {/*                ? "#81c784"*/}
        {/*                : "#bdbdbd",*/}
        {/*              borderBottomColor: isUnroll(composition.uuid)*/}
        {/*                ? "#81c784"*/}
        {/*                : composition.nomenclature.specification_count > 0*/}
        {/*                ? "#202020"*/}
        {/*                : "#bdbdbd",*/}
        {/*            }}*/}
        {/*            onClick={() => changeUnroll(composition.uuid)}*/}
        {/*          >*/}
        {/*            <td*/}
        {/*              className="Table__td"*/}
        {/*              style={{*/}
        {/*                width: "100%",*/}
        {/*              }}*/}
        {/*            >*/}
        {/*              <div className="Td__title">*/}
        {/*                {compositionIndex + 1} # {composition.nomenclature.name}*/}
        {/*              </div>*/}
        {/*            </td>*/}
        {/*            <td*/}
        {/*              className="Table__td "*/}
        {/*              style={{*/}
        {/*                textAlign: "right",*/}
        {/*                fontSize: 20,*/}
        {/*                fontWeight: 700,*/}
        {/*                opacity: 0.5,*/}
        {/*              }}*/}
        {/*            >*/}
        {/*              {composition.quantity.toFixed(3)}*/}
        {/*            </td>*/}
        {/*            <td*/}
        {/*              className="Table__td Table__td_all-border"*/}
        {/*              style={{*/}
        {/*                textAlign: "right",*/}
        {/*                fontSize: 20,*/}
        {/*                fontWeight: 700,*/}
        {/*              }}*/}
        {/*            >*/}
        {/*              {getQuantity(composition.quantity, props.quantity)}*/}
        {/*            </td>*/}

        {/*            {!!props.count && !props.isFirst && (*/}
        {/*              <td*/}
        {/*                className="Table__td Table__td_all-border"*/}
        {/*                style={{*/}
        {/*                  textAlign: "right",*/}
        {/*                  fontSize: 20,*/}
        {/*                  fontWeight: 700,*/}
        {/*                  opacity: 0.75,*/}
        {/*                }}*/}
        {/*              >*/}
        {/*                {getCount(*/}
        {/*                  (composition.quantity * (props.quantity / 1)),*/}
        {/*                  props.count*/}
        {/*                )}*/}
        {/*              </td>*/}
        {/*            )}*/}
        {/*            <td*/}
        {/*              className="Table__td Table__td_all-border"*/}
        {/*              style={{*/}
        {/*                textAlign: "center",*/}
        {/*                fontSize: 14,*/}
        {/*                fontWeight: 600,*/}
        {/*              }}*/}
        {/*            >*/}
        {/*              /!*{!!composition.measurement_type && composition.measurement_type.designation}*!/*/}
        {/*              {!!composition.measurement_unit_type ? composition.measurement_unit_type.designation :*/}
        {/*                !!composition.measurement_type*/}
        {/*                && composition.measurement_type*/}
        {/*                  .designation}*/}
        {/*            </td>*/}
        {/*          </tr>*/}
        {/*          {composition.nomenclature.specification_count > 0 && (*/}
        {/*            <tr*/}
        {/*              className={"Table__tr"}*/}
        {/*              style={{*/}
        {/*                border: "2px solid #202020",*/}
        {/*              }}*/}
        {/*            >*/}
        {/*              <td*/}
        {/*                className="Table__td"*/}
        {/*                colSpan={5}*/}
        {/*                style={{*/}
        {/*                  padding: "0 0 0 32px",*/}
        {/*                }}*/}
        {/*              >*/}
        {/*                <NomenclatureSpecifications*/}
        {/*                  nomenclature={composition.nomenclature}*/}
        {/*                  quantity={composition.quantity}*/}
        {/*                  count={props.count}*/}
        {/*                  isFirst={false}*/}
        {/*                />*/}
        {/*              </td>*/}
        {/*            </tr>*/}
        {/*          )}*/}
        {/*        </React.Fragment>*/}
        {/*      ))}*/}
        {/*  </tbody>*/}
        {/*</table>*/}
      </React.Fragment>
    ))}
      </>
  );
}

export default NomenclatureSpecifications;
