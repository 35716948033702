import React, {useEffect} from "react";
import {Route, Routes, Navigate, useParams, useSearchParams, useMatch} from "react-router-dom";
import "./Nomenclatures.scss";
import {NomenclaturesMenu} from "./NomenclaturesMenu/NomenclaturesMenu";
import {NomenclaturesCategories} from "./NomenclaturesCategories";
import {Nomenclatures} from "./Nomenclatures";
import {NomenclaturesCategory} from "./NomenclaturesCategory";
import {NomenclaturesNoCategory} from "./NomenclaturesNoCategory";
import {NomenclaturesIsDeleted} from "./NomenclaturesIsDeleted";

export const NomenclaturesRouting = () => {


  return (
    <div className="Nomenclatures">
      <div className="Block" >
        <div className="App App_vertical">
          <div className="Navigate" style={{
            width: 320,
          }}>
            <NomenclaturesMenu />
          </div>

          <div className="Content" style={{
            borderLeft: "2px solid #20202015",
          }}>
            <Routes>
              <Route
                path="/category/:nomenclatureCategoryUuid"
                element={<NomenclaturesCategory />}
              />
              <Route
                path="/all"
                element={<Nomenclatures isEdit={true} />}
              />
              <Route
                path="/no-category"
                element={<NomenclaturesNoCategory />}
              />
              <Route
                path="/is-deleted"
                element={<NomenclaturesIsDeleted />}
              />
              <Route path="/categories" element={<NomenclaturesCategories />} />
              <Route exact path="/" element={<Navigate to="./all" replace />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};
