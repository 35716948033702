import React, { Fragment, useState } from "react";
import {useList} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";
import styleContent from "../../../styles/modules/Block.module.scss";
import styleCard from "../../../styles/modules/Card.module.scss";
import ModalNomenclaturePhotoEdit from "../NomenclaturePage/ModalNomenclaturePhotoEdit";
import {useViewer} from "../../../components/Viewer";
import ModalNomenclaturePhotoAdd from "../NomenclaturePage/ModalNomenclaturePhotoAdd";
import {Nomenclature} from "./Nomenclature";

function NomenclatureProductList(props) {
  const { object, isEdit } = props;

  return (
    !!object.nomenclatures && (
      <Fragment>
          <div className={styleContent._block}>
            <div className={styleContent._block__title}>Комплект</div>
            <div className={styleContent._block__content}>
              <ul className="List">
                {object.nomenclatures.map((item) => (
                  <Fragment key={"item_" + item.uuid}>
                    <Nomenclature {...props} item={item} isEdit={isEdit}  />
                  </Fragment>
                ))}
              </ul>
            </div>
          </div>
      </Fragment>
    )
  );
}

export default NomenclatureProductList;
