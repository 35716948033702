import React, { useState, useEffect } from "react";
import "./Input.scss";
import { ReactComponent as IconEyeSolid } from "./eye-solid.svg";
import { ReactComponent as IconEyeSlashSolid } from "./eye-slash-solid.svg";

function Input(props) {
  const [value, setValue] = useState(props.value ? props.value : null);
  const [isFocus, setIsFocus] = useState(false);
  const [isFixed, setIsFixed] = useState(props.isFixed ? props.isFixed : false);
  const [eye, setEye] = useState(false);

  function onChange(event) {
    setValue(event.target.value);
  }

  function passwordEye() {
    setEye(!eye);
  }

  useEffect(() => {
    setIsFixed(props.isFixed);
    return () => {
      setIsFixed(false);
    };
  }, [props]);

  const classNameValueFocus = (value, isFocus, isFixed) => {
    if (isFocus) {
      return " active focus";
    }
    if (isFixed) {
      return " active";
    }
    if (!!value) {
      return " active";
    }
    return null;
  };

  return (
    <div className={"Field"}>
      {props.passwordEye ? (
        eye ? (
          <div className="Eye" onClick={passwordEye}>
            <IconEyeSolid />
          </div>
        ) : (
          <div className="Eye" onClick={passwordEye}>
            <IconEyeSlashSolid />
          </div>
        )
      ) : null}
      <input
        className={"Input"}
        type={
          props.type
            ? props.type === "password"
              ? eye
                ? "text"
                : "password"
              : props.type
            : "text"
        }
        onFocus={(e) => {
          setIsFocus(true);
        }}
        onBlur={(e) => {
          setIsFocus(false);
        }}
        autoComplete="off"
        defaultValue={value}
        ref={props.inputRef}
        onChange={(event) => onChange(event)}
        name={props.name}
        autoFocus={props.autoFocus}
      />
      <label
        className={"Label " + classNameValueFocus(value, isFocus, isFixed)}
      >
        {props.label}
      </label>
    </div>
  );
}

export default Input;
