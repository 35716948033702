import React, {useState} from "react";
import {ButtonIcon, Marker, useUnroll} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import styleContent from "../../../styles/modules/Block.module.scss";
import ModalPlanWarehouseUpload from "./ModalPlanWarehouseUpload";
import NomenclatureSpecifications
  from "../../ScreenNomenclatures/NomenclatureItem/NomenclatureSpecifications/NomenclatureSpecifications";

function PlanWarehouse() {
  const [selectMonthYear, setSelectMonthYear] = useState(null);
  const [isModalMonthYear, setIsModalSelectMonthYear] = useState(false);
  const [object, setObject] = useState(null);

  const monthText = (month) => {
    const months = [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ];

    return months[Number(month) - 1];
  };

  const {isUnroll, changeUnroll} = useUnroll();

  const [tab, setTab] = useState('orderItems');

  function getQuantity(nomenclature, countQuantity) {
    const nomenclatureValue = nomenclature.value / nomenclature.measurement_unit_type.ratio;
    const specificationValue = nomenclature.basic_specification.value / nomenclature.basic_specification.measurement_unit_type.ratio;
    const quantity = countQuantity / 1_000_000

    return Math.round(((nomenclatureValue / specificationValue) * quantity) * 1_000) / 1_000
  }

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            План на сырьё
          </div>

          <div className="Title__settings">
            <ButtonIcon
              tooltipText={"Фильтр&nbsp;поиска"}
              iconName={"custom-search-filter"}
              iconColor={"#202020"}
              size={24}
              style={{marginLeft: 12}}
              onClick={() => setIsModalSelectMonthYear(true)}
            />

            {!!selectMonthYear && (
              <div
                className="Title__text"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: 21,
                  fontWeight: 700,
                  marginLeft: 12,
                }}
              >
                {monthText(selectMonthYear.split("-")[1])}
                <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                  {selectMonthYear.split("-")[0]}
                </span>
              </div>
            )}
          </div>
        </div>


        {/*{!!object && (*/}
        {/*  <div className="Page__title" style={{height: "auto"}}>*/}
        {/*    <div*/}
        {/*      className="Title__text"*/}
        {/*      style={{*/}
        {/*        display: "flex",*/}
        {/*        alignItems: "center",*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <div className={styles._block}>*/}
        {/*        <div className={styles._block__content} style={{marginBottom: 4}}>*/}
        {/*          <div*/}
        {/*            className={styles._card + " " + styles._card_hover + " " + (tab === 'orderItems' ? styles._card_active : "")}*/}
        {/*            onClick={() => setTab('orderItems')}*/}
        {/*            style={{*/}
        {/*              padding: "12px",*/}
        {/*            }}>*/}
        {/*            <div*/}
        {/*              className={styles._card__number}*/}
        {/*              style={{*/}
        {/*                fontSize: 32,*/}
        {/*                marginBottom: 4*/}
        {/*              }}>{!!object.order_items ? object.order_items.length : 0}</div>*/}
        {/*            <div className={styles._card__title}>Позиции</div>*/}
        {/*          </div>*/}
        {/*          <div*/}
        {/*            className={styles._card + " " + styles._card_hover + " " + (tab === 'items' ? styles._card_active : "")}*/}
        {/*            onClick={() => setTab('items')}*/}
        {/*            style={{*/}
        {/*              padding: "12px",*/}
        {/*            }}>*/}
        {/*            <div*/}
        {/*              className={styles._card__number}*/}
        {/*              style={{*/}
        {/*                fontSize: 32,*/}
        {/*                marginBottom: 4*/}
        {/*              }}>{!!object.nomenclatures ? object.nomenclatures.length : 0}</div>*/}
        {/*            <div className={styles._card__title}>Номенклатура</div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}

        <hr className={"Page__hr"}/>

        {!object && (
          <div className={"Page__loader"}>
            {/*<Loading />*/}
            Нет данных для отображения
          </div>
        )}

        {!!object && tab === 'items' && (
          <div className="Page__title" style={{height: "auto"}}>
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: 19,
                fontWeight: 700,
              }}
            >
              Номенклатура
              {!!object.nomenclatures && (
                <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({object.nomenclatures.length})
              </span>
              )}
            </div>
          </div>
        )}

        {!!object && tab === 'orderItems' && (
          <div className="Page__title" style={{height: "auto"}}>
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: 19,
                fontWeight: 700,
              }}
            >
              Позиции
              {!!object.order_items && (
                <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({object.order_items.length})
              </span>
              )}
            </div>
          </div>
        )}

        {!!object && (
          <div className="Page__content">
            {tab === 'items' && !!object.nomenclatures && (
              <table className="Table" style={{marginBottom: 24}}>
                <thead className="Thead">
                <tr className="Table__tr Table__tr_padding Table__tr_border">
                  <th
                    className="Table__th Table__th_border"
                    style={{
                      backgroundColor: "#dfdfdf",
                    }}
                  >
                    <div className="Th__title">№</div>
                  </th>
                  <th
                    className="Table__th Table__th_border"
                    style={{
                      backgroundColor: "#dfdfdf",
                    }}
                  >
                    <div className="Th__title">Номенклатура</div>
                  </th>
                  <th
                    className="Table__th Table__th_border"
                    style={{
                      backgroundColor: "#dfdfdf",
                    }}
                  >
                    <div className="Th__title">Артикль</div>
                  </th>
                  <th
                    className="Table__th Table__th_border"
                    style={{
                      backgroundColor: "#dfdfdf",
                    }}
                  >
                    <div className="Th__title">Номер</div>
                  </th>
                  <th
                    className="Table__th Table__th_border"
                    style={{
                      backgroundColor: "#dfdfdf",
                    }}
                  >
                    <div className="Th__title">Количество</div>
                  </th>
                  <th
                    className="Table__th Table__th_border"
                    style={{
                      backgroundColor: "#dfdfdf",
                    }}
                  >
                    <div className="Th__title">Ед. измерения</div>
                  </th>
                </tr>
                </thead>
                <tbody>
                {object.nomenclatures.map((resource, index) => (
                  <tr
                    className="Table__tr Table__tr_hover Table__tr_padding Table__tr_border"
                    key={"item_" + index}
                  >
                    <td className="Table__td Table__td_border">
                      <div className="Td__text Td__text_center">
                        {resource.nomenclature.id}
                      </div>
                    </td>
                    <td className="Table__td Table__td_border">
                      <div className="Td__text">
                        <div style={{
                          fontSize: 17,
                          fontWeight: 400,
                        }}>
                          {resource.nomenclature.name}
                          {resource.nomenclature.specifications &&
                            resource.nomenclature.specifications.map((specification) => (
                              <div
                                key={"marker_" + specification.uuid}
                                className="Marker Marker_left Marker_inline Marker_small"
                              />
                            ))}
                        </div>
                      </div>
                    </td>
                    <td className="Table__td Table__td_border">
                      <div className="Td__text Td__text_center">
                        {!!resource.nomenclature.article && resource.nomenclature.article || "-"}
                      </div>
                    </td>
                    <td className="Table__td Table__td_border">
                      <div className="Td__text Td__text_center">
                        {resource.nomenclature.code}
                      </div>
                    </td>
                    <td className="Table__td Table__td_border">
                      <div className="Td__text Td__text_right">
                        <div style={{
                          fontSize: 17,
                          fontWeight: 700,
                        }}>
                          {(!!resource.nomenclature.warehouse_quantity && Number(resource.nomenclature.warehouse_quantity).toFixed(3)) || "-"}
                        </div>
                      </div>
                    </td>
                    <td className="Table__td Table__td_border">
                      <div className="Td__text ">
                        <div style={{
                          fontSize: 17,
                          fontWeight: 700,
                        }}>
                          {!!resource.nomenclature.measurement_type && (" " + resource.nomenclature.measurement_type.designation)}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            )}

            {tab === 'orderItems' && !!object.order_items && (
              <ul className="List">
                {object.order_items.sort((a, b) => b.count_quantity - a.count_quantity).map((item, index) => (
                  <React.Fragment key={"resource_" + item.uuid}>
                    <li
                      className={
                        isUnroll(item.uuid) ? "List__item active" : "List__item"
                      }
                      onClick={() => changeUnroll(item.uuid)}
                    >
                      <div className="Item__title">
                        <div className="Element__title">
                          {index + 1}.&nbsp;
                          {item.nomenclature.name}
                          {item.nomenclature.specification_count > 0 && (
                            <Marker color={"#9e9e9e"} size={8} style={{marginLeft: 12}}/>
                          )}

                          {!!item.nomenclature.is_pcs && item.nomenclature.is_pcs && (
                            <span
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#0061ff",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 0 0 8px",
                              }}
                            >
                              Штучно
                            </span>
                          )}

                          <span style={{
                            marginLeft: 16,
                            fontWeight: 700,
                            fontSize: 24
                          }}>
                                      {!!item.nomenclature && !!item.nomenclature.value && !!item.nomenclature.measurement_unit_type &&
                                        new Intl.NumberFormat("ru-RU").format(Number((!!item.nomenclature.value && !!item.nomenclature.measurement_unit_type ?
                                          (item.nomenclature.value / item.nomenclature.measurement_unit_type.ratio) : !!item.nomenclature.value && item.nomenclature.value).toFixed(3)))}
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>
                                      {!!item.nomenclature && !!item.nomenclature.measurement_unit_type && item.nomenclature.measurement_unit_type.designation + '.'}</span>
                                    </span>
                        </div>

                      </div>
                      <div className="Item__settings">
                        <div
                          style={{
                            textAlign: "right",
                            fontSize: 24,
                            fontWeight: 700,
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <div className={'Button__collected'}>
                            <div
                              className={'Collected__count Collected__count_blue'}>
                              {(!!item.count_quantity && item.count_quantity / 1000000) || "0"}
                            </div>
                          </div>

                          <span style={{
                            fontWeight: 700,
                            fontSize: 24,
                            marginLeft: 12
                          }}>
                                      {!!item.count_quantity && !!item.nomenclature && !!item.nomenclature.value &&
                                        new Intl.NumberFormat("ru-RU").format(Number((Number(item.count_quantity / 1000000).toFixed(6) * (!!item.nomenclature.value && !!item.nomenclature.measurement_unit_type ?
                                          (item.nomenclature.value / item.nomenclature.measurement_unit_type.ratio) : !!item.nomenclature.value && item.nomenclature.value)).toFixed(3))) || "0"}
                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>
                                      {!!item.nomenclature && !!item.nomenclature.measurement_unit_type && item.nomenclature.measurement_unit_type.designation + '.'}</span>
                                    </span>

                        </div>
                      </div>
                    </li>

                    {isUnroll(item.uuid) && (
                      <li className="List__content">
                        <div className={styleContent._block}>
                          <div className={styleContent._block__title}>Спецификация
                            <span style={{marginLeft: 12}}>
                  {!!item.nomenclature.basic_specification.value && !!item.nomenclature.basic_specification.measurement_unit_type
                    ? (item.nomenclature.basic_specification.value / item.nomenclature.basic_specification.measurement_unit_type.ratio)
                    : !!item.nomenclature.basic_specification.value && item.nomenclature.basic_specification.value}
                              {!!item.nomenclature.basic_specification.measurement_unit_type && <span style={{
                                marginLeft: 4,
                                fontWeight: 400,
                                fontSize: 13
                              }}>{item.nomenclature.basic_specification.measurement_unit_type.designation + '.'}</span>}
              </span>
                          </div>
                          <div className={styleContent._block__content}>


                            {item.nomenclature.specification_count > 0 && (
                              <NomenclatureSpecifications
                                nomenclature={item.nomenclature}
                                quantity={getQuantity(item.nomenclature, item.count_quantity)}
                                count={getQuantity(item.nomenclature, item.count_quantity)}
                                isFirst={true}
                              />
                            )}
                          </div>
                        </div>


                      </li>
                    )}
                  </React.Fragment>
                ))}
              </ul>
            )}
          </div>
        )}

        {isModalMonthYear && (
          <ModalPlanWarehouseUpload
            monthYear={selectMonthYear}
            setSelectMonthYear={setSelectMonthYear}
            successfulRequest={(o) => setObject(o)}
            handleClose={() => setIsModalSelectMonthYear(false)}
          />
        )}

      </div>
    </ScrollBlock>
  );
}

export default PlanWarehouse;
