import React, {useState} from "react";
import ModalSpecificationAdd from "./ModalSpecificationAdd";
import ModalSpecificationEdit from "./ModalSpecificationEdit";
import {ButtonIcon,} from "@vokymlak/kabinet-ui";
import ModalSpecificationItemAdd from "./ModalSpecificationItemAdd";
import ItemsList from "./ItemsList/ItemsList";
import ModalSpecificationsImport from "../../../NomenclatureItem/ModalSpecificationsImport";

function NomenclatureSpecificationsPage(props) {
  const {
    object,

    list,
    setList,
    count,

    setIsUpdateList,
    isUpdateList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    modalAdd,
    setModalAdd,

    modalEdit,
    setModalEdit,

    isBasicSpecification,
  } = props;

  const [modalSpecificationItemAdd, setModalSpecificationItemAdd] = useState(null)
  const [modalObjectImport, setModalObjectImport] = useState(null)

  function getAllAmountComposition(compositions) {
    let amount = 0
    if (!!compositions) {
      compositions.forEach(composition => {
        if (!!composition && !!composition.nomenclature && !!composition.nomenclature.price) {
          amount += composition.value / (composition.nomenclature.value / composition.nomenclature.price);
        }
      })
    }
    return amount;
  }

  function calculatePercentageDifference(a, b) {
    // Вычисляем разницу между числами
    let difference = a - b;
    // Вычисляем процент разницы от первого числа
    let percentage = (difference / a) * 100;
    // Округляем до двух знаков после запятой
    // Возвращаем результат
    return percentage.toFixed(2);
  }

  return (
    !!object && (
      <>
        <div
          className="Page__title"
          style={{
            marginTop: 0,
            marginBottom: 6,
          }}
        >
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
                marginRight: 6,
              }}
            >
              <div>
                Спецификации
                {!!list && (
                  <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                    ({count})
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{ marginLeft: 8 }}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 12 }}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!object.code && (
              <ButtonIcon
                tooltipText={"Импорт"}
                iconName={"custom-cloud-upload"}
                iconColor={"#202020"}
                size={26}
                style={{ marginLeft: 12 }}
                onClick={() => setModalObjectImport(object)}
              />
            )}

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{ marginLeft: 12 }}
              onClick={() => setModalAdd(true)}
            />
          </div>
        </div>

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {list.map((item) => (
                <React.Fragment key={"item_" + item.uuid}>
                  <li
                    className={
                      isUnroll(item.uuid) ? "List__item active" : "List__item"
                    }
                    onClick={() => changeUnroll(item.uuid)}
                  >
                    <div
                      className="Item__title"
                    >
                      <div
                        className="Element__title"
                        style={{
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 19,
                            fontWeight: 700,
                            width: "100%",
                          }}
                        >
                        {!!item.name && item.name}
                        </div>

                        {!!item.description && (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            marginTop: 2,
                          }}
                        >

                            <div
                              style={{
                                fontSize: 13,
                                fontWeight: 500,
                              }}
                            >
                              {item.description}
                            </div>
                        </div>
                        )}

                        <div
                          style={{
                            width: "100%",
                            opacity: 0.75,
                            marginTop: 4,
                            fontWeight: 700,
                            fontSize: 19,
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                        <span>
                  {!!item.value && !!item.measurement_unit_type ? (item.value / item.measurement_unit_type.ratio) : !!item.value && item.value}
                          {!!item.measurement_unit_type && <span style={{
                            marginLeft: 4,
                            fontWeight: 400,
                            fontSize: 13
                          }}>{item.measurement_unit_type.designation + '.'}</span>}
              </span>


                          {!!item.compositions && <span style={{marginLeft: 16}}>{new Intl.NumberFormat("ru-RU").format(
                            (getAllAmountComposition(item.compositions) / 100).toFixed(2))}<span
                          style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб. (себес)</span></span>}


                        </div>


                      </div>
                    </div>
                    <div className="Item__settings">
                      <div className="Element__datetime">
                        {item.is_deleted && (
                          <div
                            style={{
                              color: "white",
                              backgroundColor: "#f44336",
                              fontSize: 13,
                              fontWeight: 700,
                              padding: "5px 8px 4px",
                              borderRadius: 24,
                              display: "inline-block",
                              marginLeft: 8,
                            }}
                          >
                            Удалена
                          </div>
                        )}
                        {item.is_basic && (
                          <div
                            style={{
                              color: "white",
                              backgroundColor: "#202020",
                              fontSize: 13,
                              fontWeight: 700,
                              padding: "5px 8px 4px",
                              borderRadius: 24,
                              display: "inline-block",
                              marginLeft: 8,
                            }}
                          >
                            Основная
                          </div>
                        )}
                        {!!item.code && (
                        <span style={{
                          marginLeft: 8
                        }}>
                        {item.code}
                          </span>
                          )}
                      </div>
                    </div>
                  </li>

                  {isUnroll(item.uuid) && (
                    <li className="List__content">
                      {!(!!item.code || !!item.fs_code) && (
                      <div className="Content__header">
                        <div className="Header__title">
                          <div
                            className="Button__text"
                            onClick={() => setModalSpecificationItemAdd(item)}
                          >
                            Добавить позицию
                          </div>
                        </div>
                        <div className="Header__settings">
                          <div className="Button__swith">
                            <div className="Swith__text">Основная</div>
                            <div
                              className={
                                "Swith" +
                                ((!!item.is_basic && " Swith_active ") ||
                                  "")
                              }
                              onClick={() => isBasicSpecification(item)}
                            ></div>
                          </div>
                          <ButtonIcon
                            tooltipText={"Редактировать"}
                            iconName={"custom-edit"}
                            iconColor={"#202020"}
                            size={36}
                            style={{ marginLeft: 12 }}
                            onClick={() => setModalEdit(item)}
                          />
                        </div>
                      </div>
                      )}

                      {!!item.compositions && (
                        <ItemsList
                          specification={item}
                          {...props}
                        />
                      )}
                    </li>
                  )}
                </React.Fragment>
              ))}
            </ul>
          </div>
        )}

        {!!modalAdd && (
          <ModalSpecificationAdd
            nomenclature={object}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalAdd(null)}
          />
        )}
        {!!modalEdit && (
          <ModalSpecificationEdit
            specification={modalEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalEdit(null)}
          />
        )}

        {!!modalSpecificationItemAdd && (
          <ModalSpecificationItemAdd
            specification={modalSpecificationItemAdd}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalSpecificationItemAdd(null)}
          />
        )}

        {!!modalObjectImport && (
          <ModalSpecificationsImport
            object={modalObjectImport}
            successfulRequest={() =>
              setIsUpdateList(!isUpdateList)
            }
            handleClose={() => setModalObjectImport(null)}
          />
        )}
      </>
    )
  );
}

export default NomenclatureSpecificationsPage;
