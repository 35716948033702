import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../../config/url";
import {
  Modal,
  getErrorMessageByKey,
  useNotification,
  Input,
  Textarea,
  useObject,
  jsonFormatText, Select, useList, isValidateNullText,
} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";

function ModalNomenclatureTransactionEdit(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/nomenclature-transaction/" + props.transaction.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const [isDelete, setIsDelete] = useState(false);

  const inputName = useRef(null);
  const inputDescription = useRef(null);

  const selectMeasurementUnitType = useRef(null);
  const inputValue = useRef(null);
  const inputPrice = useRef(null);

  const { notification } = useNotification();

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0 && numberString !== "null"
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const numberConvertV = (numberString) => {
    return !!numberString && numberString.length > 0 && numberString !== "null"
      ? Number(String(Number(numberString).toFixed(3)))
      : null;
  };


  const changeEdit = (event) => {
    event.preventDefault();

    const name = jsonFormatText(inputName.current.value);
    const description = jsonFormatText(inputDescription.current.value);
    let measurementUnitType = selectMeasurementUnitType.current.value;
    measurementUnitType = measurementUnitType.length > 0
      ? measurementUnitType === "null"
        ? null
        : measurementUnitType
      : null
    const value = inputValue.current.value;
    const price = inputPrice.current.value;

    if (isValidateNullText(name)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      name: name,
      description: description,
      measurement_unit_type: !!measurementUnitType ? {
        tag: measurementUnitType,
      } : null,
      value: numberConvertV(value),
      price: numberConvert(price),
    });


    axios
      .put("/api/nomenclature-transaction/" + props.transaction.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("/api/nomenclature-transaction/" + props.specification.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Спецификация удалена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
        {!isDelete && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              {object.name}
              <div className="Text__signature">Спецификация</div>
            </div>

            <div className="Form__field">
              <Textarea reference={inputName} label="Название *"
                        value={!!object.name && object.name} />
            </div>

            <div className="Form__field ">
              <Textarea reference={inputDescription} label="Описание"
                        value={!!object.description && object.description}/>
            </div>

            <div className="Form__field">
              <Select
                isFixed={true}
                reference={selectMeasurementUnitType}
                options={[
                  {
                    value: "null",
                    title: "Не выбрано",
                  },
                  {
                    value: "MG",
                    title: "Миллиграммы",
                  },
                  {
                    value: "G",
                    title: "Граммы",
                  },
                  {
                    value: "KG",
                    title: "Килограммы",
                  },
                  {
                    value: "ML",
                    title: "Миллилитры",
                  },
                  {
                    value: "L",
                    title: "Литры",
                  },
                  {
                    value: "PCS",
                    title: "Штуки",
                  },
                  {
                    value: "MM",
                    title: "Миллиметры",
                  },
                  {
                    value: "M",
                    title: "Метры",
                  }]}
                label="Единица измерения"
                value={!!object.measurement_unit_type && object.measurement_unit_type.tag}
              />
            </div>

            <div className="Form__field">
              <Input type={'number'} reference={inputValue} label="Размер"
                     value={!!object.value && !!object.measurement_unit_type ? (object.value / object.measurement_unit_type.ratio) : !!object.value && object.value} />
            </div>

            <div className="Form__field Form__field_last">
              <Input type={'number'} reference={inputPrice} label="Цена"
                     value={!!object.price && (object.price / 100)} />
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            {!object.code && (
            <div className="Form__actions Form__actions_center">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>
            </div>
            )}
          </form>
        )}

        {isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div className="Form__title">
              {object.name}
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Удалить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsDelete(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalNomenclatureTransactionEdit;
