import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
  useObject,
  isValidateNullText,
  Input,
} from "@vokymlak/kabinet-ui";
import moment from "moment";


function ModalOrderItemTransactionRollback(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "api/production-order-item/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const { notification } = useNotification();


  const changeEdit = (event) => {
    event.preventDefault();

    axios
      .get("/api/production-order-item/" + props.object.uuid + "/transaction/rollback", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };


  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>

          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div
              className="Form__title"
              style={{ fontSize: 19, fontWeight: 700 }}
            >
              {object.nomenclature.name}
              <div className="Text__signature">№{object.production_order.id}{!!object.production_order.date && ' от ' + moment(object.production_order.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_gray">
                Отменить учёт
              </button>
            </div>
          </form>
      </Modal>
    )
  );
}

export default ModalOrderItemTransactionRollback;
