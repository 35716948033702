import React, {Fragment} from "react";
import {ButtonIcon, Loading, Pagination, Search, Select} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../../components/ScrollBlock/ScrollBlock";
import ModalProductionOrderAdd from "../ProductionOrderItem/ModalProductionOrderAdd";
import ModalProductionOrderEdit from "../ProductionOrderItem/ModalProductionOrderEdit";
import {ModalAddOrderItem2} from "../ProductionOrderItem/ModalAddOrderItem2";
import ModalOrderItemEdit3 from "../ProductionOrderItem/ModalOrderItemEdit3";
import ModalOrderItemTransaction from "../ProductionOrderItem/ModalOrderItemTransaction";
import {ModalAddEditOrderProduction} from "../ProductionOrderItem/ModalAddEditOrderProduction";
import ModalOrderItemTransactionRollback from "../ProductionOrderItem/ModalOrderItemTransactionRollback";
import ModalOrderEditAction from "../ProductionOrderItem/ModalOrderEditAction";
import {ProductionOrderItem} from "../ProductionOrderItem";

function ProductionOrdersPage(props) {
  const {
    list,
    count,
    setList,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,

    isModalObjectAdd, setIsModalObjectAdd,
    modalItemEdit, setModalItemEdit,
    modalOrderItem2Add, setModalOrderItem2Add,
    modalOrderItem3Edit, setModalOrderItem3Edit,

    modalAddBoning, setModalAddBoning,

    modalItemTransaction, setModalItemTransaction,
    modalItemTransactionRollback, setModalItemTransactionRollback,

    modalOrderEditAction, setModalOrderEditAction,
  } = props;

  function isBoningOrderTransaction(object) {
    let isTransaction = false

    if (!!object.items) {
      object.items.forEach(i => {
        if (i.is_transaction) {
          isTransaction = true
        }
      })
    }

    return isTransaction
  }

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Производство
            {!!list && (
              <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({count})
              </span>
            )}
            <span style={{width: 220, marginLeft: 16}}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{marginLeft: 8}}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 8}}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 10,
                      title: 10,
                    },
                    {
                      value: 100,
                      title: 100,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{marginLeft: 8}}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{marginLeft: 12}}
              onClick={() => setIsModalObjectAdd(true)}
            />
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {list.map((item) => (
                <Fragment key={"item_" + item.uuid}>
                  <ProductionOrderItem {...props} object={item}/>
                </Fragment>
              ))}
            </ul>
          </div>
        )}

        {!!modalItemEdit && (
          <ModalProductionOrderEdit
            object={modalItemEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemEdit(null)}
          />
        )}
        {!!isModalObjectAdd && (
          <ModalProductionOrderAdd
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalObjectAdd(false)}
          />
        )}

        {!!modalOrderItem2Add && (
          <ModalAddOrderItem2
            object={modalOrderItem2Add}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalOrderItem2Add(null)}
          />
        )}

        {!!modalOrderItem3Edit && (
          <ModalOrderItemEdit3
            object={modalOrderItem3Edit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalOrderItem3Edit(null)}
          />
        )}

        {!!modalItemTransaction && (
          <ModalOrderItemTransaction
            object={modalItemTransaction}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemTransaction(null)}
          />
        )}

        {!!modalAddBoning && (
          <ModalAddEditOrderProduction
            object={modalAddBoning}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalAddBoning(null)}
          />
        )}


        {!!modalItemTransactionRollback && (
          <ModalOrderItemTransactionRollback
            object={modalItemTransactionRollback}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemTransactionRollback(null)}
          />
        )}

        {!!modalOrderEditAction && (
          <ModalOrderEditAction
            object={modalOrderEditAction}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalOrderEditAction(null)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default ProductionOrdersPage;
