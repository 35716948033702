import axios from "axios";
import React, { useState, useEffect, useContext, useRef } from "react";
import { ROOT_URL } from "../../config/url";
import {
  getAccountFio,
  getErrorMessageByKey,
  isValidateNullText,
  jsonFormatText,
  ProfileContext,
  Modal,
  useNotification,
  Input,
  profileInit,
} from "@vokymlak/kabinet-ui";
import { Avatar } from "../../components/Avatar/Avatar";
import ModalProfileAvatarAdd from "./ModalProfileAvatarAdd";
import ModalProfileAvatarDelete from "./ModalProfileAvatarDelete";

function ModalProfile(props) {
  const { profileState, profileDispatch } = useContext(ProfileContext);
  const [isPassword, setIsPassword] = useState(false);
  const [profile, setProfile] = useState(null);

  const [modalProfileAvatarAdd, setModalProfileAvatarAdd] = useState(false);
  const [modalProfileAvatarDelete, setModalProfileAvatarDelete] = useState(false);

  const inputNewPassword = useRef(null);
  const inputOldPassword = useRef(null);

  const inputLastName = useRef(null);
  const inputFirstName = useRef(null);
  const inputPatronymic = useRef(null);
  const inputEmail = useRef(null);

  const { notification } = useNotification();

  useEffect(() => {
    setProfile(profileState.profileData);

    return () => {
      setProfile(null);
    };
  }, [profileState.profileData]);

  const changePassword = (event) => {
    event.preventDefault();
    const oldPassword = inputOldPassword.current.value;
    const newPassword = inputNewPassword.current.value;

    if (
      !oldPassword ||
      oldPassword.length === 0 ||
      !newPassword ||
      newPassword.length === 0
    ) {
      notification("Укажите действующий и новый пароль", "red");
      return;
    }

    const jsonData = JSON.stringify({
      old_password: oldPassword,
      new_password: newPassword,
    });

    axios
      .put("/api/profile/edit/password", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        notification("Пароль изменён", "green");
        setIsPassword(false);
        void profileInit(profileDispatch, ROOT_URL, null).then();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeEdit = (event) => {
    event.preventDefault();

    const lastName = jsonFormatText(inputLastName.current.value);
    const firstName = jsonFormatText(inputFirstName.current.value);
    const patronymic = jsonFormatText(inputPatronymic.current.value);
    const email = jsonFormatText(inputEmail.current.value);

    if (isValidateNullText(lastName) || isValidateNullText(firstName)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      last_name: lastName,
      first_name: firstName,
      patronymic: patronymic,
      email: email,
    });

    axios
      .put("/api/profile/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        notification("Данные сохранены", "green");
        void profileInit(profileDispatch, ROOT_URL, null).then();
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  function isMD5Hash(str) {
    // Проверяем, соответствует ли строка формату MD5 хэша.
    const md5Regex = /^[a-f0-9]{32}$/i;
    return md5Regex.test(str);
  }


  return (
    <>
      <Modal handleClose={() => props.handleClose()}>
        {!isPassword && profile && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div
              style={{
                marginBottom: 8,
              }}
            >
              <div style={{
                borderRadius: 7,
                backgroundColor: "#f3f3f3",
                height: 96,
                width: 96,
              }}>
                {!!profile.avatar && isMD5Hash(profile.avatar) && (
                  <img height={96} width={96} style={{
                    borderRadius: 7,
                  }}  src={ROOT_URL + "/api/account/avatar/" + profile.avatar}/>
                )}
                {!!profile.avatar && !isMD5Hash(profile.avatar) && (
                  <img height={96} width={96} style={{
                    borderRadius: 7,
                  }}  src={"data:image/jpeg;base64, "+ profile.avatar}/>
                )}
                {!profile.avatar && (
                  <Avatar size={96} value={getAccountFio(profile)} />
                )}
              </div>
              {!profile.avatar && (
                <span style={{
                  display: "inline-block"
                }}
                      className="Action__link Action__link_blue"
                      onClick={() => setModalProfileAvatarAdd(true)}
                >
              Загрузить фото
            </span>
              )}
              {!!profile.avatar && (
                <span style={{
                  display: "inline-block"
                }}
                      className="Action__link Action__link_red"
                      onClick={() => setModalProfileAvatarDelete(true)}
                >
              Удалить фото
            </span>
              )}
            </div>
            <div
              className="Form__title"
              style={{ fontSize: 19, fontWeight: 700 }}
            >
              {getAccountFio(profile)}
              <div
                style={{
                  fontSize: 12,
                  opacity: 0.5,
                }}
              >
                {"@" + profile.login}
              </div>
            </div>

            <div className="Form__field">
              <Input
                reference={inputLastName}
                label="Фамилия *"
                value={profile.last_name}
              />
            </div>

            <div className="Form__field">
              <Input
                reference={inputFirstName}
                label="Имя *"
                value={profile.first_name}
              />
            </div>

            <div className="Form__field">
              <Input
                reference={inputPatronymic}
                label="Отчество"
                value={profile.patronymic}
              />
            </div>

            <div className="Form__field Form__field_last">
              <Input
                reference={inputEmail}
                label="Электронная почта"
                value={profile.email}
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            <div className="Form__actions">
              <div
                className="Action__link Action__link_blue"
                onClick={() => setIsPassword(true)}
              >
                Смена пароля
              </div>
              <div className="Action__link" onClick={() => props.handleClose()}>
                Отмена
              </div>
            </div>
          </form>
        )}

        {!!isPassword && profile && (
          <form className="Form" onSubmit={(event) => changePassword(event)}>
            <div
              className="Form__title"
              style={{ fontSize: 19, fontWeight: 700 }}
            >
              {getAccountFio(profile)}
              <div className="Text__signature">Смена пароля</div>
            </div>

            <div className="Form__field">
              <Input reference={inputOldPassword} label="Действующий пароль" />
            </div>

            <div className="Form__field Form__field_last">
              <Input reference={inputNewPassword} label="Новый пароль" />
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Сменить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsPassword(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>

      {!!modalProfileAvatarAdd && (
        <ModalProfileAvatarAdd
          successfulRequest={() => profileInit(profileDispatch, ROOT_URL, null).then()}
          handleClose={() => setModalProfileAvatarAdd(null)}
        />
      )}

      {!!modalProfileAvatarDelete && (
        <ModalProfileAvatarDelete
          successfulRequest={() => profileInit(profileDispatch, ROOT_URL, null).then()}
          handleClose={() => setModalProfileAvatarDelete(null)}
        />
      )}
    </>
  );
}

export default ModalProfile;
