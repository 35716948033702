import React, { useState, useEffect, useRef } from "react";
import { ROOT_URL } from "../../../config/url";
import {
  useAction,
  useList,
  useProfile,
  useUnroll,
} from "@vokymlak/kabinet-ui";
import {useCtrlKey} from "../../../hooks/useCtrlKey";

export const useNomenclatures = (props) => {
  const { profile } = useProfile()
  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();
  const [isEdit, setIsEdit] = useState(props.isEdit || false);


  const [isSelect, setIsSelect] = useState(false);
  useCtrlKey(() => {
    setIsSelect(true)
  }, () => {
    setIsSelect(false)
  });
  const [selectNomenclatures, setSelectNomenclatures] = useState([]);
  const isSelectNomenclature = (selectList, uuid) => {
    let isTrue = false
    selectList.forEach(u => {
      if (u === uuid) {
        isTrue = true;
      }
    });
    return isTrue
  }
  const changeSelectNomenclature = (uuid) => {
    let isNew = true;
    let index = 0;

    selectNomenclatures.forEach((u, i) => {
      if (u === uuid) {
        isNew = false;
        index = i;
      }
    });

    if (isNew) {
      setSelectNomenclatures([...selectNomenclatures, uuid]);
    } else {
      selectNomenclatures.splice(index, 1);
      setSelectNomenclatures([...selectNomenclatures]);
    }
  };

  const [isModalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [isModalObjectImport, setIsModalObjectImport] = useState(false);
  const [modalObjectAdd, setModalObjectAdd] = useState(null);
  const [modalNomenclatureInCategory, setModalNomenclatureInCategory] = useState(null);
  const [isModalNomenclaturesInCategory, setIsModalNomenclaturesInCategory] = useState(false);
  const [isModalNomenclaturesIsPublish, setIsModalNomenclaturesIsPublish] = useState(false);
  const [modalEdit, setModalEdit] = useState(null)

  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, changeUpdate],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
  } = useList({
    apiUrl: "/api/nomenclatures",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 100,
    isSearch: true,
    urlParams: {
      "is-deleted": false
    }
  });

  const inputSearchText = useRef(null);
  function onChangeSearchText() {
    setSearchQuery(inputSearchText.current.value);
  }

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  const { setAction } = useAction("nomenclatures", () => changeUpdate());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "nomenclatures"]);
    } else {
      setAction("nomenclatures");
    }
  }, [list]);

  const [selectComposition, setSelectComposition] = useState(null);

  const isSelectComposition = (composition) => {
    return !!selectComposition && selectComposition.uuid === composition.uuid;
  };

  const changeSelectComposition = (composition) => {
    if (!!selectComposition && selectComposition.uuid === composition.uuid) {
      setSelectComposition(null);
    } else {
      setSelectComposition(composition);
    }
  };

  return {
    isEdit,

    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,

    isSelectComposition,
    changeSelectComposition,
    selectComposition,

    profile,

    isModalObjectAdd,
    setIsModalObjectAdd,
    isModalObjectImport,
    setIsModalObjectImport,
    modalObjectAdd,
    setModalObjectAdd,

    modalNomenclatureInCategory,
    setModalNomenclatureInCategory,
    modalEdit, setModalEdit,

    isSelect,
    selectNomenclatures, setSelectNomenclatures,
    isSelectNomenclature, changeSelectNomenclature,

    isModalNomenclaturesInCategory,
    setIsModalNomenclaturesInCategory,
    isModalNomenclaturesIsPublish,
    setIsModalNomenclaturesIsPublish,
  };
}
