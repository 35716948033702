import React, {useState} from "react";
import {Modal} from "@vokymlak/kabinet-ui";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {getErrorMessageByKey} from "@vokymlak/kabinet-ui";
import {useNotification} from "@vokymlak/kabinet-ui";
import {Loading} from "@vokymlak/kabinet-ui";
import moment from "moment";

function ModalNomenclaturesCategorySeo(props) {

  const [isRequest, setIsRequest] = useState(false);
  const { notification } = useNotification();

  const changeSeoUrl = () => {
    setIsRequest(true)
    axios
      .get("/api/nomenclature-category/service/seo-url", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsRequest(false)
        props.successfulRequest();
        props.handleClose();
      })
      .catch((error) => {
        setIsRequest(false)
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <div className="Form">
        <div className="Form__title">Генерация SEO адресов</div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue"
          onClick={!isRequest ? () => changeSeoUrl() : () => null}>

            {!isRequest
              ? "Генерация адресов"
              : <Loading size="medium" color="blue" />}
          </button>
        </div>

        <div className="Form__actions Form__actions_center">
          <div className="Action__link" onClick={() => props.handleClose()}>
            Отмена
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalNomenclaturesCategorySeo;
