import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../config/url";
import {
  getErrorMessageByKey,
  Textarea,
  useNotification,
  Modal,
  useObject,
  jsonFormatText,
  isValidateNullText, Input, Select,
} from "@vokymlak/kabinet-ui";
import moment from "moment";

function ModalOrderEditAction(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "api/production-order/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const selectOrderStatusType = useRef(null);

  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    let orderStatusType = selectOrderStatusType.current.value;
    orderStatusType = orderStatusType.length > 0
      ? orderStatusType === "null"
        ? null
        : orderStatusType
      : null

    const jsonData = JSON.stringify({
      production_order_status_type: !!orderStatusType ? {
        tag: orderStatusType,
      } : null,
    });

    axios
      .put("/api/production-order/" + props.object.uuid + "/edit/action", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeEdit(event)}>
          <div
            className="Form__title"
            style={{ fontSize: 19, fontWeight: 700 }}
          >
            №{object.id}{!!object.date && ' от ' + moment(object.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}
            <div className="Text__signature">Статус наряда</div>
          </div>

          <div className="Form__field Form__field_last">
            <Select
              isFixed={true}
              reference={selectOrderStatusType}
              options={[
                {
                  value: "NEW",
                  title: "Новый",
                },
                {
                  value: "AT_WORK",
                  title: "В работе",
                },
                {
                  value: "COMPLETED",
                  title: "Завершён",
                }
                ]}
              label="Статус"
              value={!!object.production_order_status_type && object.production_order_status_type.tag}
            />
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Применить
            </button>
          </div>

        </form>
      </Modal>
    )
  );
}

export default ModalOrderEditAction;
