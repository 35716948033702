import React, {useRef, useState} from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
  useObject,
  Input,
  useList, Select, Textarea, jsonFormatText,
} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";
import ModalSpecificationItemSelect from "./ModalSpecificationItemSelect";

function ModalSpecificationItemAdd(props) {
  // const {
  //   list: [list],
  // } = useList({ apiUrl: "/api/nomenclatures/list", rootUrl: ROOT_URL });

  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/specification/" + props.specification.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const [selectItem, setSelectItem] = useState(null)
  const [isModalSelectItem, setIsModalSelectItem] = useState(false)


  // const selectNomenclatureUuid = useRef(null);

  const inputDescription = useRef(null);

  const selectMeasurementUnitType = useRef(null);
  const inputValue = useRef(null);

  const { notification } = useNotification();

  const numberConvertV = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(3)))
      : null;
  };

  const changeAdd = (event) => {
    event.preventDefault();

    // const nomenclatureUuid = selectNomenclatureUuid.current.value;

    if (!selectItem) {
      notification("Укажите номенклатуру", "red");
      return;
    }
    const description = jsonFormatText(inputDescription.current.value);
    let measurementUnitType = selectMeasurementUnitType.current.value;
    measurementUnitType = measurementUnitType.length > 0
      ? measurementUnitType === "null"
        ? null
        : measurementUnitType
      : null
    const value = inputValue.current.value;

    if ((!value || (!!value && value.length === 0)) ||
      !measurementUnitType) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      description: description,
      measurement_unit_type: !!measurementUnitType ? {
        tag: measurementUnitType,
      } : null,
      value: numberConvertV(value),
    });

    axios
      .post("/api/specification/" + props.specification.uuid + "/composition/"+selectItem.uuid+"/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Позиция добавлена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getNomenclatureSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.uuid,
        title: item.id + ". " + item.name   + (!!item.article ? " - " + item.article : ""),
      };

      options.push(option);
    });

    return options;
  };

  return (!!object &&
    // !!list &&
    (
      <>
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div className="Form__title">
            {object.name}
            <div className="Text__signature">Спецификация</div>
          </div>

          {!selectItem && (
            <div className="Form__field"
                 style={{
                   cursor: "pointer"
                 }}
                 onClick={() => setIsModalSelectItem(true)}>
              <div className="Field__label">
                Номенклатура
              </div>
              <div className="Field__text">
                -
              </div>
            </div>
          )}

          {!!selectItem && (
            <div className="Form__field"
                 style={{
                   cursor: "pointer"
                 }}
                 onClick={() => setIsModalSelectItem(true)}>
              <div className="Field__label">
                Номенклатура
              </div>
              <div className="Field__text">
                {selectItem.id + ". " + selectItem.name   + (!!selectItem.article ? " - " + selectItem.article : "")}
              </div>
            </div>
          )}

          {/*<div className="Form__field">*/}
          {/*  <Select*/}
          {/*    isFixed={true}*/}
          {/*    reference={selectNomenclatureUuid}*/}
          {/*    options={!!list ? getNomenclatureSelectOptions(list) : getNomenclatureSelectOptions([])}*/}
          {/*    label="Номенклатура"*/}
          {/*  />*/}
          {/*</div>*/}

          <div className="Form__field ">
            <Textarea reference={inputDescription} label="Описание" />
          </div>

          <div className="Form__field">
            <Select
              isFixed={true}
              reference={selectMeasurementUnitType}
              options={[
                {
                  value: "null",
                  title: "Не выбрано",
                },
                {
                  value: "MG",
                  title: "Миллиграммы",
                },
                {
                  value: "G",
                  title: "Граммы",
                },
                {
                  value: "KG",
                  title: "Килограммы",
                },
                {
                  value: "ML",
                  title: "Миллилитры",
                },
                {
                  value: "L",
                  title: "Литры",
                },
                {
                  value: "PCS",
                  title: "Штуки",
                },
                {
                  value: "MM",
                  title: "Миллиметры",
                },
                {
                  value: "M",
                  title: "Метры",
                }]}
              label="Единица измерения *"
            />
          </div>

          <div className="Form__field Form__field_last">
            <Input type={'number'} reference={inputValue} label="Размер *" />
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Добавить
            </button>
          </div>
        </form>


      </Modal>
        {isModalSelectItem && (
          <ModalSpecificationItemSelect
            item={selectItem}
            successfulRequest={item =>
              setSelectItem(item)
            }
            handleClose={() => setIsModalSelectItem(false)}
          />
        )}
        </>
    )
  );
}

export default ModalSpecificationItemAdd;
